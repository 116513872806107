import React from "react";
import TagList from "../../components/tags/TagList";
import { useParams } from "react-router-dom";
import { STORE_CONST } from "../../utils/constants/store";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { useSelector } from "react-redux";
import "../dashboard/Dashboard.css";
import "../../components/dashboard/Topbar.css";
import "../../components/dashboard/ReleaseTable.css";
import "./Tag.css";

function Tag() {
  const { productId } = useParams();
  return (
    <>
      <TagList productId={productId}></TagList>
    </>
  );
}

export default Tag;
