import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../utils/constants/apiUrl";
import { STORE_CONST } from "../../utils/constants/store";
import customAxios from "../../utils/lib/customAxios";

const getPreferenceData = createAsyncThunk(
  `${STORE_CONST.PREFERENCE_NAME_SPACE}/getPreferenceData`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.PREFERENCEAPI, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const updatePreferenceData = createAsyncThunk(
  `${STORE_CONST.PREFERENCE_NAME_SPACE}/updatePreferenceData`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.patch(
        API_URL.UPDATE_PREFERENCE_API, arg);
      return {
        ...data,
        response: {
          ...data.response,
          fromReleaseDetails: arg?.fromReleaseDetails,
          type: arg?.type,
        },
      };
      // return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const publishData = createAsyncThunk(
  `${STORE_CONST.PREFERENCE_NAME_SPACE}/publishData`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.PUBLISH_DATA, arg);
      return {
        ...data,
        response: {
          ...data.response,
          fromReleaseDetails: arg?.fromReleaseDetails,
        },
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const singleStepCancel = createAsyncThunk(
  `${STORE_CONST.PREFERENCE_NAME_SPACE}/singleStepCancel`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.SINGLE_STEP_CANCEL, arg);

      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const multiStepCancel = createAsyncThunk(
  `${STORE_CONST.PREFERENCE_NAME_SPACE}/multiStepCancel`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.MULTI_STEP_CANCEL, arg);

      return {
        ...data,
        choice: arg?.choice
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export {
  multiStepCancel,
  singleStepCancel,
  getPreferenceData,
  updatePreferenceData,
  publishData
};
