import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";
import { checkReleaseDeleted, getAppDetails, getReasonListForSpam, reportAsSpam, sendOtp } from "./api/CommonApi";
import { ERROR_CODE_CONST } from "../utils/constants/errorCode";
import { toasterError, toasterSuccess } from "../utils/helper/commonHelper";

const CommonSlice = createSlice({
  name: STORE_CONST.COMMON_NAMESPACE,
  initialState: {
    isLoad: false,
    setActiveMenu: "",
    appDetails: {
      version: "",
    },
    widthDetails: {
      totalWidth: 0,
      sidebarWidth: 0,
      rightWidth: 0,
    },
    heightDetails: {
      releaseTopbarHeight: 0,
      releaseActionHeight: 0,
      releaseContentHeight: 0,
      sidebarHeight: 0,
    },
    goToPreviewSection: false,
    reportSpamList:[],
    showSpamMsg: ''
  },
  reducers: {
    setActiveMenuClass(state, action) {
      state.setActiveMenu = action.payload.setActiveMenu;
    },
    setTotalWidth(state, action) {
      state.widthDetails = {
        ...state.widthDetails,
        totalWidth: action.payload.totalWidth,
      };
    },
    setSidebarWidth(state, action) {
      state.widthDetails = {
        ...state.widthDetails,
        sidebarWidth: action.payload.sidebarWidth,
      };
    },
    setRightsideWidth(state, action) {
      state.widthDetails = {
        ...state.widthDetails,
        rightWidth: action.payload.rightWidth,
      };
    },
    setElementHeight(state, action) {
      state.heightDetails = {
        ...state.heightDetails,
        [action.payload.key]: action.payload.value,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAppDetails.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getAppDetails.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.appDetails.version = payload?.response?.version;
      }
    });
    builder.addCase(getAppDetails.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(checkReleaseDeleted.pending, (state, { payload }) => {
      state.isLoad = true;
      state.goToPreviewSection = false;
    });
    builder.addCase(checkReleaseDeleted.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.goToPreviewSection = true;
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.goToPreviewSection = false;
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.goToPreviewSection = false;
        toasterError(payload?.message);
      }
    });
    builder.addCase(checkReleaseDeleted.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.goToPreviewSection = false;
    });
    builder.addCase(reportAsSpam.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(reportAsSpam.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.showSpamMsg = payload?.message;
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(reportAsSpam.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(getReasonListForSpam.pending, (state, { payload }) => {
      state.isLoad = true;
      state.reportSpamList = [];
    });
    builder.addCase(getReasonListForSpam.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.reportSpamList = payload?.response?.reasons;
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(getReasonListForSpam.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.reportSpamList = [];
    });
  },
});

export const commonActions = CommonSlice.actions;

export default CommonSlice;
