import React from "react";

function Copyright({ paddingBottom }) {
  const d = new Date();
  let year = d.getFullYear();
  return (
    <div style={{ paddingBottom: paddingBottom }} className="copyright">
      (c) {year} Simple Release Notes
    </div>
  );
}

export default Copyright;
