import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STORE_CONST } from "../../utils/constants/store";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import Copyright from "../../layouts/Copyright";
import "./ReportAsSpam.css";
import {
  releaseNoteLogo
} from "../../utils/imports/images";
import { useForm } from "react-hook-form";
import { getReasonListForSpam, reportAsSpam } from "../../store/api/CommonApi";
import { useNavigate } from "react-router-dom";

function ReportAsSpam() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [descriptionLength, setDescriptionLength] = useState(0);
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  const unsubscribe = queryParameters.get("unsubscribe");
  const [reasonId, setReason] = useState(0);

  const reportSpamList = useSelector(
    (state) => state[STORE_CONST.COMMON_NAMESPACE]?.reportSpamList
  );
  const isLoad = useSelector(
    (state) => state[STORE_CONST.COMMON_NAMESPACE]?.isLoad
  );
  const showSpamMsg = useSelector(
    (state) => state[STORE_CONST.COMMON_NAMESPACE]?.showSpamMsg
  );

  /**
   * function to get spam reason list
   */
  useEffect(() => {
    dispatch(getReasonListForSpam());
  }, [token]);

  const { control, register, getValues, setValue } = useForm({
    mode: "all",
    defaultValues: {},
  });

  const submit = () => {
    dispatch(
      reportAsSpam({
        token: token,
        reasonId: Number(reasonId),
        comments: getValues("reasonTextAreaData"),
        isUnsubscribe: unsubscribe === 'true' ? true: false,
      })
    );
  };

  useEffect(() => {
    if (showSpamMsg !== "") {
      navigate(
        "/report-spam/success"
      );
    }
  }, [showSpamMsg]);

  /**
   * function to render UI based on IsDisplayed key
   * @param {*} reason 
   * @param {*} i 
   * @returns 
   */
  const renderUi = (reason, i) => {
    if(reason.IsDisplayed) {
      return (
        <div className="unsubscribe-option flex" key={i}>
        <label className="unsubscribe-radio-container">
          <input
            type="radio"
            id={i}
            name="reasonRadio"
            value="File Import"
            onChange={() => {
              setReason(reason?.SpamReportReasonID);
            }}
          />
          <span className="checkmark"></span>
        </label>
        <span className="option-label">
          {reason.SpamReportReasonDescription}
        </span>
      </div>
      )
    }
  }

  return (
    <>
      <div className="message-page-main-wrapper">
        <header>
          <div className="message-page-center">
            <div className="logo-wrapper logo-icon flex">
              <img src={releaseNoteLogo} />
              <div className="logo-icon-text">
                <h1>Simple Release Notes</h1>
                <small>Show off your work</small>
              </div>
            </div>
          </div>
        </header>
        <section className="reportspam-main-wrapper">
          <div className="reportspam-main flex">
            <div className="reportspam-form-wrapper">
              <h1>
              We are sorry to hear you are receiving unsolicited emails. We have a strong commitment to protect your privacy and your feedback below will help us improve our processes.
              </h1>
              <div className="reportspam-form">
                <div className="form-group">
                  <label>Please help us with the reason for this report:</label>
                  <div className="unsubscribe-option-wrapper">
                    {reportSpamList &&
                      reportSpamList.map((reason, i) => (
                        renderUi(reason, i)
                      ))}
                  </div>
                </div>

                <div className="form-group reason-textarea-wrapper">
                  <textarea
                    id="reason"
                    {...register(`reasonTextAreaData`, {})}
                    placeholder="Please enter your comments here"
                    maxLength={1000}
                    onInput={(e) => {
                      if (e.target.value.length >= 1001) {
                        return;
                      }
                      setDescriptionLength(e.target.value.length);
                    }}
                  ></textarea>
                  <div className="character-count">
                    <span>{descriptionLength}/1000</span>
                  </div>
                </div>
                <div className="form-group report-btn-wrapper">
                  <button
                    className="blue-button flex"
                    onClick={() => {
                      submit();
                    }}
                  >
                    <span>Report</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div className="message-page-center">
            <Copyright />
          </div>
        </footer>
      </div>
      {isLoad && <LoadingSpinner />}
    </>
  );
}

export default ReportAsSpam;
