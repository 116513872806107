import React, { useEffect, useState } from "react";

function Pagination({ paginationArr, responseData }) {
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    let totalPage = 0;
    setTotalPages(0);
    if (paginationArr && paginationArr?.count > paginationArr["limit"]) {
      totalPage = Math.ceil(paginationArr["count"] / paginationArr["limit"]);
      setTotalPages(totalPage);
    }
    let currentPage = paginationArr?.page ? paginationArr?.page : 1;
    let limit = paginationArr?.limit ? paginationArr?.limit : 0;
    let totalCount = paginationArr?.count ? paginationArr?.count : 0;
    let firstEntry = limit * (currentPage - 1) + 1;
    let lastEntry = limit * currentPage;
    setCurrentPage(currentPage);
    if (lastEntry > totalCount) {
      lastEntry = totalCount;
    }
    if (!totalCount) {
      firstEntry = 0;
    }
    getNumbers(currentPage, totalPage);
  }, [paginationArr]);

  const getNumbers = (cPage, totalPage) => {
    const numbers = [];
    const startPaging = Math.max(1, cPage - 7);
    const endPaging = Math.min(Math.max(7, cPage + 2), totalPage);
    for (var i = startPaging; i <= endPaging; i++) {
      numbers.push(i);
    }
    setNumbers(numbers);
  };

  const nextPage = (page) => {
    setCurrentPage(page);
    getNumbers(page, totalPages);
    responseData({ currentPage: page });
  };

  return (
    <>
      {totalPages !== 0 && (
        <div className="table-data-pagination flex">
          <span
            onClick={() => nextPage(1)}
            className={"first " + (currentPage === 1 ? "disabled-cls" : "")}
          >
            First
          </span>
          <ul className="flex page-nums">
            <li
              onClick={() => nextPage(currentPage - 1)}
              className={currentPage === 1 ? "disabled-cls" : ""}
            >
              {"<"}
            </li>
            {numbers.map((number) => (
              <li
                key={number}
                onClick={() => nextPage(number)}
                className={currentPage === number ? "active" : ""}
              >
                {number}
              </li>
            ))}
            {totalPages > 7 && currentPage <= totalPages - 4 && <li>...</li>}
            {totalPages > 8 && currentPage <= totalPages - 3 && <li onClick={() => nextPage(totalPages)}>{totalPages}</li>}
          </ul>
          <span
            onClick={() => nextPage(currentPage === totalPages ? totalPages : currentPage + 1)}
            className={"next " + (currentPage === totalPages ? "disabled-cls" : "")}
          >
            {">"}
          </span>
          <span
            onClick={() => nextPage(totalPages)}
            className={"last " + (currentPage === totalPages ? "disabled-cls" : "")}
          >
            Last
          </span>
        </div>
      )}
    </>
  );
}

export default Pagination;
