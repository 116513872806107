import React from "react";
import "./Confirmation.css";

function UnsubscribeConfirmation({ responseAction }) {
  return (
    <React.Fragment>
      <div className="popup-overlay"></div>
      <div className="popup-min confirmation-popup">
        <div className="popup-min-top">
          <h2>Are you sure you want to Unsubscribe ?</h2>
        </div>
        <div className="popup-min-bottom flex">
          <div className="popup-min-bottom-left">
            <button
              onClick={(event) => responseAction({ isCancel: true })}
              className="blue-button close"
            >
              No
            </button>
          </div>
          <div className="popup-min-bottom-right">
            <button
              onClick={(event) => responseAction({ isSubmit: true })}
              className="blue-button save"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UnsubscribeConfirmation;
