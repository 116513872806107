import axios from "axios";
import { toast } from "react-toastify";
import {
  decryptAES,
  encryptAES,
  getLocalStorage,
  getloginInfo,
  toasterError,
  toasterWarning,
  removeLocalStorage,
  setLocalStorage
} from "../helper/commonHelper";
import { PROJECT_CONST } from "../constants/projectConst";
import { ERROR_CODE_CONST } from "../constants/errorCode";
import customHistory from "../lib/history";

export const customAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {},
});

customAxios.interceptors.request.use((request) => {
  let accessToken =
    getloginInfo() && getloginInfo()?.token?.access_token
      ? getloginInfo()?.token?.access_token
      : "";
  if (accessToken && !PROJECT_CONST.EXCLUDE_URL_ARR.includes(request?.url)) {
    request.headers.Authorization = "Bearer " + accessToken;
  }

  if (!PROJECT_CONST.EXCLUDE_URL_ARR.includes(request?.url)) {
    request.headers["Productid"] = getLocalStorage("activeLevelTwoId");
  }
  return request;
});

customAxios.interceptors.response.use(
  (response) => {
    if (response?.data?.hasError) {
      if(response?.data?.errorCode === ERROR_CODE_CONST.UUID_DECODE_ERROR ) {
        customHistory.replace("/*");

        return;
      }
      if (response?.data?.errorCode === ERROR_CODE_CONST.COMMON_ERROR_CODE) {
        toasterError(response?.data?.message);
      }else {
        if (response?.data?.errorCode == ERROR_CODE_CONST.ARCHIVE_ERROR) {
          toasterWarning(response?.data?.message);
        }
        if (
          response?.data?.errorCode == ERROR_CODE_CONST.LEVEL2_DELETION &&
          !PROJECT_CONST.EXCLUDE_DELETE_ARCHIVE_ARR.includes(
            response?.config?.url
          )
        ) {
          if(response?.data?.response.hasOwnProperty("fromRoute") && 
          response?.config?.url === 'user/send-application-feedback') {
            if(response?.data?.errorCode ===  ERROR_CODE_CONST.LEVEL2_DELETION) {
              setLocalStorage("errorMsg", response?.data?.response?.level2LabelName)
            }
            customHistory.replace("/global/deleted-level");
          }
          if (!response?.data?.response.hasOwnProperty("fromRoute")) {
            customHistory.replace("/release-note/no-level-exist/Level2");
          } else if (response?.data?.response.hasOwnProperty("fromRoute") &&
            (response?.config?.url === 'jira/getjiracredentials' ||
              response?.config?.url === 'jira/getjiracredential' ||
              response?.config?.url === 'jira/updatecredentials' ||
              response?.config?.url === 'jira/savecredentials')
          ) {
            toasterError(response?.data?.message);
          }
        } else if (response?.data?.errorCode == ERROR_CODE_CONST.LEVEL1_DELETION &&
          !PROJECT_CONST.EXCLUDE_DELETE_ARCHIVE_ARR.includes(
            response?.config?.url) && (response?.config?.url === 'customer/level2/add' ||
              response?.config?.url === 'customer/level1/delete' ||
              response?.config?.url === 'customer/level1/edit' ||
              response?.config?.url === 'customer/level1/archive' ||
              response?.config?.url === 'customer/level2/archive')) {
          customHistory.replace("/release-note/no-level-exist/Level1");
        } else {
          if (
            !PROJECT_CONST.EXCLUDE_DELETE_ARCHIVE_ARR.includes(
              response?.config?.url
            ) && response?.data?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
            response?.config?.url != 'send-application-feedback'
          ) {
           
            toasterError(response?.data?.message);
          } else if (PROJECT_CONST.INCLUDE_DELETE.includes(response?.config?.url)
            && response?.data?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR) {
            toasterError(response?.data?.message);
          } else if ((response?.config?.url === PROJECT_CONST.UNAUTHORIZED_FEEDBACK_URL
            || response?.config?.url === PROJECT_CONST.UNAUTHORIZED_FEEDBACK_URL_WITH_RELEASE_ID
            || response?.config?.url === PROJECT_CONST.UNAUTHORIZED_FEEDBACK_SEND_OTP
            || response?.config?.url === PROJECT_CONST.UNAUTHORIZED_FEEDBACK_RESEND_OTP
            || response?.config?.url === PROJECT_CONST.UNAUTHORIZED_FEED_BACK_EDIT
            || response?.config?.url === PROJECT_CONST.UNAUTHORIZED_FEED_BACK_ADD
            || response?.config?.url === PROJECT_CONST.UNAUTHORIZED_UNSUBSCRIBE
            || response?.config?.url === PROJECT_CONST.UNAUTHORIZED_SUBSCRIBE
            || response?.config?.url === PROJECT_CONST.UNAUTHORIZED_FEEDBACK_ADD_VERIFICATION ||
            response?.config?.url == PROJECT_CONST.UNAUTHORIZED_FEEDBACK_DELETE ||
            response?.config?.url == PROJECT_CONST.UNAUTHORIZED_SUBSCRIBER_DETAILS ||
            response?.config?.url == PROJECT_CONST.UNAUTHORIZED_FEEDBACK_TAG_LIST ||
            response?.config?.url === PROJECT_CONST.UNAUTHORIZED_SECURITY_CHECK ||
            response?.config?.url === PROJECT_CONST.UNAUTHORIZED_FEEDBACK_RELEASE_LIST ||
            response?.config?.url ===  PROJECT_CONST.UNAUTHORIZED_SUBSCRIBER_SEND_OTP ||
            response?.config?.url == PROJECT_CONST.UNAUTHORIZED_FEEDBACK_SUBSCRIBER_VERIFICATION ||
            response?.config?.url == PROJECT_CONST.EMAIL_VERIFIED) &&
            response?.data?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR && 
            response?.data?.errorCode != ERROR_CODE_CONST.COMMON_ERROR_CODE) {
              if(response?.data?.errorCode ===  ERROR_CODE_CONST.LEVEL2_DELETION) {
                setLocalStorage("errorMsg", response?.data?.response?.level2LabelName)
              }else{
                setLocalStorage("errorMsg", '')
              }
            customHistory.replace("/global/deleted-level");
          }else if(response?.config?.url == PROJECT_CONST.PREVIEW && 
            response?.data?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR) {
              if(response?.data?.errorCode ===  ERROR_CODE_CONST.LEVEL2_DELETION) {
                setLocalStorage("errorMsgForPreview", response?.data?.response?.level2LabelName)
              }else{
                setLocalStorage("errorMsgForPreview", '')
              }
            customHistory.replace("/preview/deleted-level");
          }
        }
      }
     
    }
    return response;
  },
  (error) => {
    let msg = error?.message;
    if (error && error?.response?.status === 500) {
      msg =
        error?.response?.data && error?.response?.data?.message
          ? error?.response?.data?.message
          : "";
    } else if (error?.response?.status === 401) {
      // Reload page if release list unauthorised
      if (
        error?.response?.config?.url == PROJECT_CONST.UNAUTHORIZED_RELEASE_LIST_URL ||
        error?.response?.config?.url == PROJECT_CONST.UNAUTHORIZED_FEEDBACK_URL ||
        error?.response?.config?.url == PROJECT_CONST.UNAUTHORIZED_FEEDBACK_URL_WITH_RELEASE_ID ||
        error?.response?.config?.url == PROJECT_CONST.UNAUTHORIZED_FEEDBACK_SEND_OTP ||
        error?.response?.config?.url == PROJECT_CONST.UNAUTHORIZED_FEEDBACK_RESEND_OTP ||
        error?.response?.config?.url == PROJECT_CONST.UNAUTHORIZED_FEED_BACK_EDIT ||
        error?.response?.config?.url == PROJECT_CONST.UNAUTHORIZED_FEED_BACK_ADD ||
        error?.response?.config?.url == PROJECT_CONST.UNAUTHORIZED_UNSUBSCRIBE ||
        error?.response?.config?.url == PROJECT_CONST.UNAUTHORIZED_SUBSCRIBE ||
        error?.response?.config?.url == PROJECT_CONST.UNAUTHORIZED_FEEDBACK_ADD_VERIFICATION ||
        error?.response?.config?.url == PROJECT_CONST.UNAUTHORIZED_FEEDBACK_DELETE ||
        error?.response?.config?.url == PROJECT_CONST.UNAUTHORIZED_SUBSCRIBER_DETAILS ||
        error?.response?.config?.url == PROJECT_CONST.UNAUTHORIZED_FEEDBACK_TAG_LIST || 
        error?.response?.config?.url == PROJECT_CONST.UNAUTHORIZED_SUBSCRIBER_SEND_OTP ||
        error?.response?.config?.url == PROJECT_CONST.PAGE_AUTHORISATION || 
        error?.response?.config?.url == PROJECT_CONST.UNAUTHORIZED_FEEDBACK_SUBSCRIBER_VERIFICATION
      ) {
        removeLocalStorage("publicAuthorization");
        removeLocalStorage("securityType");
        window.location.reload();
      } else {
        toasterError('Unauthorized Access');
        localStorage.removeItem("loginInfo");
        removeLocalStorage("filterArrSet");
        window.location.href = "/login";
      }
    }
    if (error?.response?.status === 401) {
      return;
    } 
    else {
      toasterError(msg);
    }
  }
);

export default customAxios;
