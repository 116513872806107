import { closeDarkGreyImg, publish, sad, nutrel, happy } from "../../utils/imports/images"
import { useForm } from "react-hook-form";
import { Debounce } from "../../utils/helper/commonHelper";
import { getLocalStorage } from "../../utils/helper/commonHelper";
import { useSelector, useDispatch } from "react-redux";
import { STORE_CONST } from "../../utils/constants/store";
import { toasterWarning } from "../../utils/helper/commonHelper";
import { useState } from "react";
import { feedbackActions } from "../../store/FeedbackSlice";
import { getFeedBackDetails, sendOtp, submitOtp, reSendOtp } from "../../store/api/FeedbackApi";
import { PROJECT_CONST } from "../../utils/constants/projectConst";
import FormErrorMsg from "../common/FormErrorMsg";
import TwoFactorAuthenticationPopup from "../common/OtpVerificationPopup";

function ProvideFeedBack () {

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        reset,
        setValue,
      } = useForm({
        mode: "all",
        defaultValues: {},
      });
      const dispatch = useDispatch()
      const [emailError, setEmailError] = useState(false);
      const [emailSytaxError, setEmailSytaxError] = useState(false);
      const [oldEmail, setOldEmail] = useState();
      const [email, setEmail] = useState();
      const queryParameters = new URLSearchParams(window.location.search);
      const [publicAuthorization, setPublicAuthorization] = useState(false);
      const [submitData, setSubmitData] = useState(false);
      const dnsName = queryParameters.get("dns-name")
        ? decodeURIComponent(queryParameters.get("dns-name"))
        : '';
        const securityType = getLocalStorage("securityType")
        ? getLocalStorage("securityType")
        : "";

      const feedBackDetails = useSelector(
        (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedBackDetails
      );

      const authorisationDetails = useSelector(
        (state) => state[STORE_CONST.DNS_SETTINGS_NAME_SPACE]?.authorisationDetails
      );

      const classActive = useSelector(
        (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.classActive
      );
      const openOtpScreen = useSelector(
        (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.openOtpScreen
      );
      const sessionToken = useSelector(
        (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.sessionToken
      );
      const otpType = useSelector(
        (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.otpType
      );

        /**
   * function to check subscriber with mail ID
   * @param {*} event
   */
  const checkSubScriber = (event) => {
    const publicAuthorization = getLocalStorage("publicAuthorization")
      ? getLocalStorage("publicAuthorization")
      : "";
    if (feedBackDetails?.isArchiveLevel2) {
      toasterWarning('This ' + feedBackDetails?.customerAttributes?.caLevel2Name + ' is Read-Only');
      setValue("email", feedBackDetails?.email);
      return;
    }
    if (event.target.value.length > 0) {
      setEmailError(false);
    } else {
      setEmailError(true);
      setEmailSytaxError(false)

      return;
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (emailRegex.test(event.target.value)) {
      setEmailSytaxError(false)
    } else {
      setEmailSytaxError(true)

      return;
    }
    if (emailRegex.test(event.target.value)) {
      setOldEmail(email);
      if (email != event.target.value) {
        setEmail(event.target.value);
        dispatch(
          feedbackActions.setIsLoad({
            isLoad: true,
          })
        );
        dispatch(
          getFeedBackDetails({
            header: {
              token: publicAuthorization
                ? publicAuthorization
                : '',
              dnsName: dnsName ? dnsName : ''
            },
            param: {
              email: event.target.value,
              releaseParentId: feedBackDetails.release.releaseParentId,
            }
          })
        );
      }
    }
  };

    const debouncedOnChange = Debounce(checkSubScriber, 500);


    const showOtpScreen = (type = 0) => {
    dispatch(
        feedbackActions.setOtpType({
        otpType: type,
        })
    );
    dispatch(
        sendOtp(
        {
            header: {
            token: publicAuthorization
                ? publicAuthorization
                : authorisationDetails?.details?.token,
            dnsName: feedBackDetails?.release?.level2Data?.level2DNSRegistrationName,
            securityType: securityType ? securityType : 0
            },
            param: {
            email: email,
            releaseParentId: feedBackDetails?.release?.releaseParentId,
            otpType: type
            }
        }
        )
    );
    };

      /**
   * function to submit OTP
   * @param {*} data
   */
  const submitOtpData = (data) => {
    let extraParam = {};
    setSubmitData(true);
    dispatch(
      feedbackActions.setIsLoad({
        isLoad: true,
      })
    );
    extraParam = {
      feedbackNote: getValues("feedBackData"),
      scoreId: classActive,
    }
    dispatch(
      submitOtp({
        header: {
          token: publicAuthorization
            ? publicAuthorization
            : authorisationDetails?.details?.token,
          dnsName: feedBackDetails?.release?.level2Data?.level2DNSRegistrationName,
          securityType: securityType ? securityType : 0
        },
        param: {
          email: email,
          sessionToken: sessionToken,
          otp: Number(data),
          level2Id: feedBackDetails?.level2Id,
          releaseParentId: feedBackDetails.release.releaseParentId,
          otpType: otpType,
        },
        extraParam: extraParam
      })
    );
  };

    /**
   * function to cancel OTP screen
   */
    const cancelPopUp = () => {
        if (!submitData) {
          setEmail(oldEmail ? oldEmail : email);
          setValue("email", oldEmail ? oldEmail : email);
        }
        dispatch(
          feedbackActions.setOpenOtpScreen({
            setopenOtpScreen: false,
          })
        );
      };
    
        /**
   * function to re-send OTP
   */
  const resendOtp = () => {
    dispatch(
      feedbackActions.setIsLoad({
        isLoad: true,
      })
    );
    dispatch(
      reSendOtp({
        header: {
          token: publicAuthorization
            ? publicAuthorization
            : authorisationDetails?.details?.token,
          dnsName: feedBackDetails?.release?.level2Data?.level2DNSRegistrationName,
          securityType: securityType ? securityType : 0
        },
        param: {
          sessionToken: sessionToken,
          releaseParentId: feedBackDetails.release.releaseParentId,
          otpType: otpType,
        }
      })
    );
  };

    return (
        <>

        {openOtpScreen && (
            <TwoFactorAuthenticationPopup
            onClick={submitOtpData}
            cancelPopUp={cancelPopUp}
            resendOtp={resendOtp}
            ></TwoFactorAuthenticationPopup>
        )}
        <div className="release-libraray-provide-feedback-wrapper">
                <h3>Provide Feedback</h3>
                <div className="release-libraray-provide-feedback-form-wrapper">
                    <div className="release-libraray-provide-feedback-form-group">
                        <label>How do you feel about this release?</label>
                        <div className="release-libraray-feedback-reactions">
                            {/* <div className="release-libraray-reaction-item">
                                <img src={sad}/>
                                <p>Unhappy</p>
                            </div>
                            <div className="release-libraray-reaction-item">
                                <img src={nutrel}/>
                                <p>Average</p>
                            </div>
                            <div className="release-libraray-reaction-item">
                                <img src={happy}/>
                                <p>Excellent</p>
                            </div> */}
                             {feedBackDetails.emojis &&
                feedBackDetails.emojis
                  .filter((emojis) => emojis.score !== 1)
                  .map((emoji, i) => (
                    <div
                      key={i}
                      className={`release-libraray-reaction-item ${classActive?.classActive == emoji.score ? "active" : ""
                        }`}
                      onClick={() => {
                        // if (feedBackDetails?.isArchiveLevel2) {
                        //   toasterWarning(PROJECT_CONST.LEVEL_2READ_ONLY_MSG);
                        //   return;
                        // }
                        dispatch(feedbackActions.setClassActiveInFeedBack({
                          classActive: emoji.score
                        }))
                      }}
                    >
                      <img src={emoji.emoji} alt={emoji?.emojiLabel} title={emoji?.emojiLabel} />
                      <p>{emoji.score === 2 ? "Unhappy" : emoji.score === 3 ? "Average" : emoji.score === 4 ? "Excellent" :""}</p>
                    </div>
                  ))}
                        </div>
                    </div>
                    <div className="release-libraray-provide-feedback-form-group summary">
                        <label>Provide Summary of Feedback:</label>
                        <textarea {...register("feedBackData")}></textarea>
                    </div>
                    <div className="release-libraray-provide-feedback-form-group">
                        <label>Enter Email:</label>
                        <input
                        type="email"
                        {...register("email")}
                        placeholder="Please enter your email to leave feedback"
                        onChange={debouncedOnChange}
                        />
                    </div>
                      {emailError && (<FormErrorMsg message={'Please Enter Email'} />)}
                    {emailSytaxError && (<FormErrorMsg message={'Please Enter a Valid Email'} />)}
                    <div className="release-libraray-provide-feedback-form-btn-wrapper">
                        <button className="btn-lib-common btn-light"><img src={closeDarkGreyImg} width="12"/> Close</button>
                    <button className="btn-lib-common btn-dark"   onClick={handleSubmit((data) => {
                    // if (feedBackDetails?.isArchiveLevel2) {
                    //   toasterWarning(PROJECT_CONST.LEVEL_2READ_ONLY_MSG);
                    //   return;
                    // }
                    if (getValues("email") === '') {
                      setEmailError(true);

                      return;
                    } else {
                      setEmailError(false);
                    }
                    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                    if (emailRegex.test(getValues("email"))) {
                      setEmailSytaxError(false);
                    } else {
                      setEmailSytaxError(true);

                      return;
                    }

                    showOtpScreen(PROJECT_CONST.OTP_TYPE_SUBMIT_FEEDBACK);
                  })}><img src={publish} width="14"/> Submit</button>
                    </div>
                </div>
            </div>
        </>
    )

}

export default ProvideFeedBack