import { closeDarkGreyImg, publish, resendImg } from "../../utils/imports/images";
import { useState, useRef } from "react";

function TwoFactorAuthenticationPopup ({submitOtpData, cancelPopUp, resendOtp}) {

    const [otp, setOtp] = useState({
        value: "",
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
        disable: true,
      });
      const Ref = useRef(null);

      const inputfocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
          const next = elmnt.target.tabIndex - 2;
          if (next > -1) {
            elmnt.target.form.elements[next].focus();
          }
        } else {
          const next = elmnt.target.tabIndex;
          if (next < 4) {
            elmnt.target.form.elements[next].focus();
          }
        }
      };
    
      const handleChange = (value1, event) => {
        setOtp((prevOtp) => ({
          ...prevOtp,
          [value1]: event.target.value,
        }));
      };
    
      const onSubmit = () => {
        let otpValue = otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4;
        submitOtpData(otpValue);
      };

 return (
    <>
     <div className="release-lib-popup-overlay"></div>
        <div className="release-lib-popup authenticate-popup">
            <div className="release-lib-popup-header">
                <h3>Two-Factor Authentication</h3>
                <div className="close-pop" onClick={cancelPopUp}></div>
            </div>
            <form>
            <div className="release-lib-popup-body">
               <div className="authenticate-otp-form-wrapper">
                    <label>Enter the four digit code from your email</label>
                    <div className="authenticate-otp-form-flex">
                    <input
                    type="text"
                    maxLength={1}
                    onChange={(e) => handleChange("otp1", e)}
                    tabIndex="1"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                  <input
                    type="text"
                    maxLength={1}
                    onChange={(e) => handleChange("otp2", e)}
                    tabIndex="2"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                  <input
                    type="text"
                    maxLength={1}
                    onChange={(e) => handleChange("otp3", e)}
                    tabIndex="3"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                  <input
                    type="text"
                    maxLength={1}
                    onChange={(e) => handleChange("otp4", e)}
                    tabIndex="4"
                    onKeyUp={(e) => inputfocus(e)}
                  />
                    </div>
               </div>
            </div>
            </form>
            <div className="release-lib-popup-footer">
                <button className="btn-lib-common btn-light" onClick={cancelPopUp}><img src={closeDarkGreyImg} width="12"/>Close</button>
                <button className="btn-lib-common btn-dark" onClick={resendOtp}><img src={resendImg} width="20"/>Resend Code</button>
                <button className="btn-lib-common btn-dark" onClick={onSubmit}><img src={publish} width="15"/>Submit</button>
                
            </div>
        </div>
    </>
 )
}
export default TwoFactorAuthenticationPopup;