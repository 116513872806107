import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";
import {
  createLevelOne,
  createLevelTwo,
  getArchiveDetails,
  deleteLevel1,
  deleteLevel2,
  getLevelOneList,
  getLevelTwoList,
  setLevel1Archive,
  setLevel2Archive,
  updateLevelOne,
  updateLevelTwo,
  sendApplicationFeedback,
} from "./api/SidebarApi";
import { ERROR_CODE_CONST } from "../utils/constants/errorCode";
import {
  getLevel2Index,
  toasterError,
  toasterSuccess,
} from "../utils/helper/commonHelper";
import { PROJECT_CONST } from "../utils/constants/projectConst";
import {
  setLocalStorage, getLocalStorage
} from "../utils/helper/commonHelper";

const SidebarSlice = createSlice({
  name: STORE_CONST.SIDEBAR_NAMESPACE,
  initialState: {
    isLoad: false,
    levelOneDetails: {
      list: [],
      formattedLevel1Details: [],
      customerAttribute:{},
    },
    levelTwoDetails: {
      list: [],
      formattedLevel2Details: [],
    },
    activeLevelOneId: "",
    activeLevelTwoId: "",
    level1and2Loaded: false,
    levelMenuDetails: {},
    archiveStatus: {
      level1Status: false,
      level2Status: false,
    },
    levelDeleted: false,
    callReleaseListApi: false,
    triggerFilter: false,
    captureWindow: false,
    triggerCapturePopUp: '',
    closeDataCapturePopUp: false,
    applicationFeedbackMsg: '',
    closeApplicationFeedbackSuccessMsg: false
  },
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload.isLoad;
    },
    setProductId(state, action) {
      state.activeLevelTwoId = action.payload.activeLevelTwoId;
    },
    setCustomerId(state, action) {
      state.activeLevelOneId = action.payload.activeLevelOneId;
    },
    emptyProductList(state, action) {
      state.levelTwoDetails.list = [];
    },
    setLevel1and2Loaded(state, action) {
      state.level1and2Loaded = action.payload.level1and2Loaded;
    },
    setLevelMenuDetails(state, action) {
      state.levelMenuDetails = action.payload.levelMenuDetails;
    },
    setLevelDeletedStatus(state, action) {
      state.levelDeleted = action.payload.levelDeleted;
    },
    setCallReleaseListAfterArchive(state, action) {
      state.callReleaseListApi = action.payload.stateValue;
    },
    setClearFilterTrigger(state, action) {
      state.triggerFilter = action.payload.triggerFilter
    },
    setCaptureWindow(state, action) {
      state.captureWindow = action.payload.captureWindow
    },
    setWindowCapturePopUp(state, action) {
      state.triggerCapturePopUp = action.payload.triggerCapturePopUp
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLevelOneList.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getLevelOneList.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.levelOneDetails.list = payload?.response?.level1List;
        let level1Format = [];
        payload?.response?.level1List.map((row) => {
          level1Format.push({
            label: row?.level1Name,
            value: row?.level1Id,
          });
        });
        state.levelOneDetails.formattedLevel1Details = level1Format;
        var foundIndex = state.levelOneDetails.list.findIndex(
          (x) => x.level1Id == Number(getLocalStorage("activeLevelOneId"))
        );
        if (foundIndex === -1) {
          setLocalStorage("activeLevelOneId", 0);
          setLocalStorage("activeLevelTwoId", 0);
        } else {
          let level2Index = state.levelOneDetails.list[foundIndex].level2data.findIndex(
            (x) => x.level2Id == Number(getLocalStorage("activeLevelTwoId"))
          );
          if (level2Index === -1) {
            setLocalStorage("activeLevelTwoId", 0);
          }
        }
        // update delete level1 level2 message with label name using this customer Attribute
        state.levelOneDetails.customerAttribute = payload?.response?.customerAttribute 
      }
    });
    builder.addCase(getLevelOneList.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(getLevelTwoList.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getLevelTwoList.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.triggerFilter = false;
        let level2Format = [];
        payload?.response?.level2List.map((row) => {
          level2Format.push({
            level1Id: row?.level1Id,
            label: row?.level2Name,
            value: row?.level2Id,
          });
        });
        state.levelTwoDetails.formattedLevel2Details = level2Format;
        state.levelTwoDetails.list = payload?.response?.level2List;
        // if (
        //   payload?.response?.initialType === PROJECT_CONST.INITIAL_LOAD &&
        //   activeLevelOneId
        // ) {
        //   state.activeLevelOneId = activeLevelOneId;
        // }
        // if (
        //   payload?.response?.initialType === PROJECT_CONST.INITIAL_LOAD &&
        //   activeLevelTwoId
        // ) {
        //   state.activeLevelTwoId = activeLevelTwoId;
        // }
      }
    });
    builder.addCase(getLevelTwoList.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(createLevelOne.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(createLevelOne.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.levelOneDetails.list = [
          ...state.levelOneDetails.list,
          payload?.response?.level1,
        ];
        toasterSuccess(payload?.message);
      } else if (
        !payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.VALIDATION_ERROR
      ) {
        payload?.response?.validation.map((data) => {
          toasterError(data["message"]);
        });
      }
    });
    builder.addCase(createLevelOne.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(createLevelTwo.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(createLevelTwo.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        var newArr = [...state.levelOneDetails.list];
        var foundIndex = state.levelOneDetails.list.findIndex(
          (x) => x.level1Id == payload?.response?.level2?.level1Id
        );
        if (foundIndex != -1) {
          newArr[foundIndex]["level2data"] = [
            ...state.levelOneDetails.list[foundIndex]["level2data"],
            payload?.response?.level2,
          ];
          state.levelOneDetails.list = newArr;
        }
        toasterSuccess(payload?.message)
      } else if (
        !payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.VALIDATION_ERROR
      ) {
        payload?.response?.validation.map((data) => {
          toasterError(data["message"]);
        });
      }
    });
    builder.addCase(createLevelTwo.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(updateLevelOne.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(updateLevelOne.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        var foundIndex = state.levelOneDetails.list.findIndex(
          (x) => x.level1Id == payload?.response?.level1?.level1Id
        );
        if (foundIndex != -1) {
          state.levelOneDetails.list[foundIndex]["level1Name"] =
            payload?.response?.level1?.level1Name;
            toasterSuccess(payload?.message);
        }
      } else if (
        !payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.VALIDATION_ERROR
      ) {
        payload?.response?.validation.map((data) => {
          toasterError(data["message"]);
        });
      }
    });
    builder.addCase(updateLevelOne.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(updateLevelTwo.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(updateLevelTwo.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        const index = getLevel2Index(
          state.levelOneDetails.list,
          payload?.response?.level2?.level1Id,
          payload?.response?.level2?.level2Id
        );
        if (index?.level2Index != -1) {
          state.levelOneDetails.list[index?.level1Index]["level2data"][
            index?.level2Index
          ]["level2Name"] = payload?.response?.level2?.level2Name;
          toasterSuccess(payload?.message);
        }
      } else if (
        !payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.VALIDATION_ERROR
      ) {
        payload?.response?.validation.map((data) => {
          toasterError(data["message"]);
        });
      }
    });
    builder.addCase(updateLevelTwo.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(setLevel2Archive.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callReleaseListApi = false;
    });
    builder.addCase(setLevel2Archive.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        const activeLevelTwoId = getLocalStorage("activeLevelTwoId")
          ? getLocalStorage("activeLevelTwoId")
          : 0;
        if (Number(payload?.response?.level2Id) == Number(activeLevelTwoId)) {
          state.archiveStatus = {
            ...state.archiveStatus,
            level2Status: true,
          };
        }

        const index = getLevel2Index(
          state.levelOneDetails.list,
          payload?.response?.level1Id,
          payload?.response?.level2Id
        );
        if (index?.level2Index != -1) {
          state.levelOneDetails.list[index?.level1Index]["level2data"][
            index?.level2Index
          ]["isArchived"] = payload?.response?.isArchived;
          // toasterSuccess(PROJECT_CONST.LEVEL2_UPDATED_MSG);
        }
        if (window.location.href.includes('/dashboard')) {
          state.callReleaseListApi = true;
        }
        toasterSuccess(payload?.message);
      }
    });
    builder.addCase(setLevel2Archive.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callReleaseListApi = false;
    });
    builder.addCase(setLevel1Archive.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callReleaseListApi = false;
    });
    builder.addCase(setLevel1Archive.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        const activeLevelOneId = getLocalStorage("activeLevelOneId")
          ? getLocalStorage("activeLevelOneId")
          : 0;
        if (Number(payload?.response?.level1Id) == Number(activeLevelOneId)) {
          state.archiveStatus = {
            ...state.archiveStatus,
            level1Status: true,
          };
        }
        var foundIndex = state.levelOneDetails.list.findIndex(
          (x) => x.level1Id == payload?.response?.level1Id
        );
        if (foundIndex != -1) {
          state.levelOneDetails.list[foundIndex]["isArchived"] =
            payload?.response?.isArchived;
          const level2Data = state.levelOneDetails.list[foundIndex][
            "level2data"
          ]
            ? state.levelOneDetails.list[foundIndex]["level2data"]
            : [];
          if (level2Data.length) {
            const l2Data = level2Data.map((level2data) => {
              return {
                ...level2data,
                isArchived: payload?.response?.isArchived,
              };
            });
            state.levelOneDetails.list[foundIndex]["level2data"] = l2Data;
          }
        }
        if (window.location.href.includes('/dashboard')) {
          state.callReleaseListApi = true;
        }
        toasterSuccess(payload?.message);
      }
    });
    builder.addCase(setLevel1Archive.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callReleaseListApi = false;
    });
    builder.addCase(getArchiveDetails.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getArchiveDetails.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.archiveStatus = {
          level1Status: payload?.response?.level1ArchiveStatus,
          level2Status: payload?.response?.level2ArchiveStatus,
        };
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.archiveStatus = {};
        toasterError(payload?.message);
      }
    });
    builder.addCase(getArchiveDetails.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(deleteLevel2.pending, (state, { payload }) => {
      state.isLoad = true;
      state.levelDeleted = false;
    });
    builder.addCase(deleteLevel2.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        let foundIndex = state.levelOneDetails.list.findIndex(
          (obj) =>
            obj.level2data &&
            obj.level2data.find(
              (innerObj) =>
                innerObj.level2Id === payload?.response?.level2?.level2Id
            )
        );
        let newActiveLevelTwoId = 0;
        let newActiveLevelOneId = 0;
        if (foundIndex != -1) {
          if (
            state.levelOneDetails.list[foundIndex] &&
            state.levelOneDetails.list[foundIndex]["level2data"]
          ) {
            const level2Index = state.levelOneDetails.list[foundIndex][
              "level2data"
            ].findIndex((x) => x.level2Id == payload?.response?.level2?.level2Id);

            if (level2Index != -1) {
              state.levelOneDetails.list[foundIndex].level2data.splice(
                level2Index,
                1
              );
              if (
                Number(getLocalStorage("activeLevelTwoId")) ===
                Number(payload?.response?.level2?.level2Id)
              ) {
                if (state.levelOneDetails.list[foundIndex].level2data.length > 0) {
                  newActiveLevelTwoId =
                    state.levelOneDetails.list[foundIndex].level2data[0].level2Id;
                } else {
                  let objectsWithLevel2Data = state.levelOneDetails?.list?.filter(
                    (obj) => obj.level2data && obj.level2data.length > 0
                  );
                  if (objectsWithLevel2Data.length) {
                    let firstObjectWithLevel2Data = objectsWithLevel2Data[0];
                    newActiveLevelOneId = firstObjectWithLevel2Data.level1Id;
                    newActiveLevelTwoId =
                      firstObjectWithLevel2Data.level2data[0].level2Id;
                    setLocalStorage("activeLevelOneId", newActiveLevelOneId);
                  }
                }
                setLocalStorage("activeLevelTwoId", newActiveLevelTwoId);
                state.levelDeleted = true;
                state.triggerFilter = true;
              }
            }
          }
        }
        toasterSuccess(payload?.message);
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(deleteLevel2.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.levelDeleted = false;
    });

    builder.addCase(deleteLevel1.pending, (state, { payload }) => {
      state.isLoad = true;
      state.levelDeleted = false;
    });
    builder.addCase(deleteLevel1.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        let foundIndex = state.levelOneDetails.list.findIndex(
          (obj) => obj.level1Id === payload?.response?.level1?.level1Id
        );
        let newActiveLevelOneId = 0;
        let newActiveLevelTwoId = 0;
        if (foundIndex != -1) {
          if (state.levelOneDetails.list[foundIndex]) {
            state.levelOneDetails?.list?.splice(foundIndex, 1);
            if (
              Number(getLocalStorage("activeLevelOneId")) ===
              Number(payload?.response?.level1?.level1Id)
            ) {
              let objectsWithLevel2Data = state.levelOneDetails?.list?.filter(
                (obj) => obj.level2data && obj.level2data.length > 0
              );
              if (objectsWithLevel2Data.length) {
                let firstObjectWithLevel2Data = objectsWithLevel2Data[0];
                newActiveLevelOneId = firstObjectWithLevel2Data.level1Id;
                newActiveLevelTwoId =
                  firstObjectWithLevel2Data.level2data[0].level2Id;
              } else if (state.levelOneDetails.list.length) {
                newActiveLevelOneId = state.levelOneDetails.list[0].level1Id;
              }
              setLocalStorage("activeLevelOneId", newActiveLevelOneId);
              setLocalStorage("activeLevelTwoId", newActiveLevelTwoId);
              state.levelDeleted = true;
              state.triggerFilter = true;
              if (state.levelOneDetails.list.length === 0) {
                state.levelMenuDetails = {
                  level1Name: '',
                  level2Name: ''
                }
              }
            }
          }
        }
        toasterSuccess(payload?.message);
      }
    });
    builder.addCase(deleteLevel1.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.levelDeleted = false;
    });
    builder.addCase(sendApplicationFeedback.pending, (state, { payload }) => {
      state.isLoad = true;
      state.closeDataCapturePopUp = false;
      state.applicationFeedbackMsg = '';
      state.closeApplicationFeedbackSuccessMsg = false;
    });
    builder.addCase(sendApplicationFeedback.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.applicationFeedbackMsg = payload?.message;
        state.closeDataCapturePopUp = true;
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }else{
        state.closeDataCapturePopUp = true;
        state.closeApplicationFeedbackSuccessMsg = true;
      }
    });
    builder.addCase(sendApplicationFeedback.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.closeDataCapturePopUp = false;
      state.applicationFeedbackMsg = '';
    });
  },
});

export const SidebarActions = SidebarSlice.actions;

export default SidebarSlice;
