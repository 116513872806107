import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../utils/constants/apiUrl";
import { STORE_CONST } from "../../utils/constants/store";
import customAxios from "../../utils/lib/customAxios";
import { setLocalStorage } from "../../utils/helper/commonHelper";

const releaseNoteDetails = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/releaseNoteDetails`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.RELEASE_NOTE_DETAILS,
        arg
      );
      // if (data.response.releaseList.releaseTags.length) {
      //   dispatch(
      //     tagActions.setTagsApplied({
      //       applyTags: true,
      //     })
      //   );
      // }
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const releaseNoteCreate = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/releaseNoteCreate`,
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.RELEASE_NOTE_CREATE,
        formData
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const updateReleaseNote = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/updateReleaseNote`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      console.log(arg, 'argggg')
      const { data } = await customAxios.post(
        API_URL.RELEASE_NOTE_UPDATE,
        arg?.releasePreferenceData
      );
      return {
        ...data,
        response: {
          ...data.response,
          type: arg?.releasePreferenceData?.type
        },
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const publishNote = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/publishNote`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.PUBLISH_RELEASE_NOTE,
        arg
      );
      return {
        ...data,
        response: {
          ...data.response,
          oldReleaseId: arg?.releaseId,
        },
      };
      // return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const releaseNotePreview = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/releaseNotePreview`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.RELEASE_NOTE_PREVIEW, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);
const releaseNoteList = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/releaseNoteList`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.RELEASE_NOTE_LIST, arg);
      // return data;
      return {
        ...data,
        response: {
          ...data.response,
          filterType: arg?.filterType,
        },
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const deleteReleaseNote = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/deleteReleaseNote`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.RELEASE_NOTE_DELETE,
        arg
      );
      return {
        ...data,
        response: {
          ...data.response,
          releaseId: arg?.id,
          type: arg?.type,
        },
      };
      // return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getReleaseNumber = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/getReleaseNumber`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.get(
        API_URL.GET_RELEASE_NUMBER + "/" + arg?.releaseFeedbackKey
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const releaseNotePreviewByReleaseIDKey = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/releaseNotePreviewByReleaseIDKey`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.get(
        API_URL.RELEASE_NOTE_PREVIEW_BY_KEY + "/" + arg?.releaseIDKey
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getStatusList = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/getStatusList`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.get(API_URL.STATUS_LIST);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

// const getBarChartDetails = createAsyncThunk(
//   `${STORE_CONST.RELEASENOTE_NAMESPACE}/getBarChartDetails`,
//   async (arg, { rejectWithValue, dispatch }) => {
//     try {
//       const { data } = await customAxios.post(API_URL.BAR_CHART_DETAILS);
//       return data;
//     } catch (error) {
//       rejectWithValue(error.response.data);
//     }
//   }
// );

// const getSubscribers = createAsyncThunk(
//   `${STORE_CONST.RELEASENOTE_NAMESPACE}/getSubscribers`,
//   async (arg, { rejectWithValue, dispatch }) => {
//     try {
//       const { data } = await customAxios.post(API_URL.SUBSCRIBERS);
//       return data;
//     } catch (error) {
//       rejectWithValue(error.response.data);
//     }
//   }
// );

const getReleaseHistory = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/getReleaseHistory`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.RELEASE_NOTE_HISTORY,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const uploadFile = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/uploadFile`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.RELEASE_NOTE_FILE_UPLOAD,
        arg?.formData
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const deleteUploadedFile = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/deleteUploadedFile`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.RELEASE_NOTE_DELETE_FILE,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getScheduleList = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/getScheduleList`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.get(
        API_URL.SCHEDULE_LIST + "/" + arg?.level2Id
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getJiraCredentials = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/getJiraCredentials`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.JIRA_CREDENTIALS, arg?.data);
      if(data && arg?.level2Id) {
        setLocalStorage("activeLevelTwoId", arg?.level2Id);
      }
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getJiraKeys = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/getJiraKeys`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.JIRA_KEYS, arg?.data);
      if(data && arg?.level2Id) {
        setLocalStorage("activeLevelTwoId", arg?.level2Id);
      }
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getProjects = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/getProjects`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.JIRA_PROJECTS, arg?.data);
      if(data && arg?.level2Id) {
        setLocalStorage("activeLevelTwoId", arg?.level2Id);
      }
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getTicketData = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/getTicketData`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.JIRA_TICKET, arg);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const saveJiraCred = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/saveJiraCred`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.SAVE_JIRA_CRED, arg?.data);
      if(arg?.level2Id != 0) {
        setLocalStorage("activeLevelTwoId", arg?.level2Id);
      }
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const updateJiraCred = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/updateJiraCred`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.patch(API_URL.UPDATE_JIRA_CRED, arg?.data);
      if(arg?.level2Id != 0) {
        setLocalStorage("activeLevelTwoId", arg?.level2Id);
      }
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getJiraLoadedDetails = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/getJiraLoadedDetails`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.JIRA_LOADED_DETAILS, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getJiraDetails = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/getJiraDetails`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.get(
        API_URL.JIRA_EDIT_CREDENTIALS + "/" + arg?.credentialId
      );
      if(arg?.level2Id != 0) {
        setLocalStorage("activeLevelTwoId", arg?.level2Id);
      }
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const releaseNoteFeedbackGridDetails = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/releaseNoteFeedbackGridDetails`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.RELEASE_NOTE_FEEDBACK_GRID_DETAILS,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const releaseNoteFeedbackList = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/releaseNoteFeedbackList`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.RELEASE_NOTE_FEEDBACK_LIST,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const createJiraTicketForAnRelease = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/createJiraTicketForAnRelease`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.CREATE_JIRA_TICKET_FOR_AN_RELEASE, arg?.inputData
      );
      if (data) {
        setLocalStorage("activeLevelTwoId", arg?.level2Id);
      }
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getCollaborators = createAsyncThunk(
  `${STORE_CONST.RELEASENOTE_NAMESPACE}/getCollaborators`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.RELEASE_NOTE_COLLABORATOR_LIST,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export {
  releaseNoteDetails,
  releaseNoteCreate,
  publishNote,
  updateReleaseNote,
  releaseNotePreview,
  releaseNoteList,
  deleteReleaseNote,
  getReleaseNumber,
  releaseNotePreviewByReleaseIDKey,
  getStatusList,
  // getBarChartDetails,
  // getSubscribers,
  getReleaseHistory,
  uploadFile,
  getScheduleList,
  getJiraCredentials,
  getJiraKeys,
  getProjects,
  getTicketData,
  saveJiraCred,
  updateJiraCred,
  getJiraLoadedDetails,
  getJiraDetails,
  releaseNoteFeedbackGridDetails,
  releaseNoteFeedbackList,
  createJiraTicketForAnRelease,
  getCollaborators,
  deleteUploadedFile
};
