import React, { useEffect, useState } from "react";
import { STORE_CONST } from "../../utils/constants/store";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../common/pagination/Pagination";
import { PROJECT_CONST } from "../../utils/constants/projectConst";
import "./ReleaseList.css";
import Scrollbars from "react-custom-scrollbars-2";
import { feedbackActions } from "../../store/FeedbackSlice";
import { Debounce, toasterWarning } from "../../utils/helper/commonHelper";
import ReleaseTags from "../dashboard/ReleaseTags";
import { useNavigate, useParams } from "react-router-dom";

function ReleaseList() {
  const navigate = useNavigate();
  const { key, dnsName } = useParams();
  const dispatch = useDispatch();
  const [popUpShow, setTagPopUpShow] = useState(false);
  const [tags, setTags] = useState([]);
  const releaseList = useSelector(
    (state) =>
      state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedbackLibraryDetails?.releaseList
  );
  const paginationArr = useSelector(
    (state) =>
      state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedbackLibraryDetails
        ?.paginationArr
  );
  const isArchiveLevel2 = useSelector(
    (state) =>
      state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedbackLibraryDetails
        ?.isArchiveLevel2
  );

  const responseData = (page) => {
    dispatch(feedbackActions.setFilter({ page: page?.currentPage }));
  };

  const onChange = (e) => {
    dispatch(
      feedbackActions.setFilter({
        searchKey: e.target.value,
        page: PROJECT_CONST.PAGINATION_DEFAULT_PAGE,
      })
    );
  };
  const debouncedOnChange = Debounce(onChange, 800);
  const closeTagpopup = () => {
    setTagPopUpShow(false);
  };

  const formatTags = (tags) => {
    let array = [];
    tags.map((data, i) => {
      array.push({
        label: data?.tag?.name,
        color: data?.tag?.tagColour
      })
    })
    setTags(array);
  }

  const removeTags = () => { };
  
  return (
    <>
      <div className="middle-container release-library-middle-container">
        <div className="middle-container-bottom flex">
          <div className="dashboard-main">
            <div className="search-wrapper">
              <input
                onChange={debouncedOnChange}
                id="input"
                type="search"
                placeholder="Search"
              />
            </div>
            <Scrollbars
              autoHeight={true}
              autoHeightMax={"70vh"}
              autoHide={true}
            >
              <div className="dashboard-tables-wrapper pagination-table">
                <table className="bg-white">
                  <thead>
                    <tr>
                      <th className="text-left">Name</th>
                      <th>Release Date</th>
                      <th>Author</th>
                      <th>Tags</th>
                    </tr>
                  </thead>
                  <tbody>
                    {releaseList.length !== 0 ? (
                      releaseList.map((release, index) => (
                        <tr
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            // if (isArchiveLevel2) {
                            //   toasterWarning(
                            //     PROJECT_CONST.LEVEL_2READ_ONLY_MSG
                            //   );
                            //   return;
                            // }
                            if(key) {
                              window.location.replace(
                                process.env.REACT_APP_API_URL.split("api")[0] +
                                  "feedback/customer-feedback?encryptedId=" +
                                  release?.releaseId + '&jwt-token='+ key + '&dns-name='+dnsName
                              );
                            }else{
                              window.location.replace(
                                process.env.REACT_APP_API_URL.split("api")[0] +
                                  "feedback/customer-feedback?encryptedId=" +
                                  release?.releaseId + '&dns-name='+dnsName
                              );
                            }
                          }}
                        >
                          <td className="text-left">
                            {release?.releaseShortName}
                          </td>
                          <td>
                            {moment(release?.releasePublishedDate).format(
                              "MM/DD/YYYY"
                            )}
                          </td>
                          <td>
                            {release?.createdByUserData?.userFullName
                              ? release?.createdByUserData?.userFullName
                              : ""}
                          </td>
                          <td>
                            <div className="flex tags-flex">
                              <div className="tags-col flex">
                                {release?.releaseTags &&
                                  release?.releaseTags
                                    .slice(0, 3)
                                    .map((releaseTags) => (
                                      <span
                                        key={"tag_" + releaseTags?.tag?.tagId}
                                        style={{
                                          backgroundColor:
                                            releaseTags.tag.tagColour,
                                        }}
                                      >
                                         <span className="tag-name-tooltip">{releaseTags.tag.name}</span>
                                      </span>
                                    ))}
                              </div>
                              {release.releaseTags.length > 3 ? (
                                <button
                                  className="add-new"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    formatTags(release.releaseTags);
                                    setTagPopUpShow(true);
                                  }}
                                ></button>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4}>No Data Found</td>
                      </tr>
                    )}
                    <tr className="pagination-row">
                      <td colSpan={4}>
                        {Object.keys(paginationArr).length !== 0 &&
                  releaseList.length !== 0 && (
                    <Pagination
                      paginationArr={paginationArr}
                      responseData={responseData}
                    />
                  )}
                  </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Scrollbars>
           
          </div>
        </div>
      </div>
      {popUpShow && <ReleaseTags tags={tags} removeTags={removeTags} closeTagpopup={closeTagpopup} />}
    </>
  );
}

export default ReleaseList;
