import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";
import {
  deleteDlist,
  getDMemberList,
  getDlist,
  saveDMember,
  saveDlist,
  updateDMember,
  updateDlist,
  deleteDMember,
  verifyTarget,
  resendVerifyTarget,
  getDistributionListName,
  bulkEmailImport,
} from "./api/DlApi";
import {
  toasterError,
  toasterSuccess,
  toasterWarning,
} from "../utils/helper/commonHelper";
import { PROJECT_CONST } from "../utils/constants/projectConst";
import { ERROR_CODE_CONST } from "../utils/constants/errorCode";

const DlSlice = createSlice({
  name: STORE_CONST.DL_NAMESPACE,
  initialState: {
    isLoad: false,
    isShowAdd: false,
    isShowModal: false,
    dlDetails: {
      dlList: [],
    },
    dlMemberDetails: {
      dlMemberList: [],
      pdlName: [],
      page: 0,
      paginationArr: []
    },
    verifyDetails: {
      verifySuccess: false,
      verifyMessage: false,
    },
    clickedDlListId: 0,
    callDlListApi: false,
    callDlTargetListApi: false,
    clickedDlMemberId: 0,
    distributionListName: "",
    errorInEmails: [],
    closeMappingPopUp: false,
    closeBulkImportPopUp: false,
    showBulkPopUpWhenErrors: false,
    paginationDetails:{
      page: 0,
      paginationArr: []
    },
    noData: false,
    noDataForDlMember: false
  },
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload.isLoad;
    },
    showHideModal(state, action) {
      state.isShowModal = action.payload.isShowModal;
    },
    setIsShowAdd(state, action) {
      state.isShowAdd = action.payload.isShowAdd;
    },
    setList(state, action) {
      const index = state.dlDetails.dlList.findIndex(
        (x) => x.pdlId === action.payload.id
      );
      if (index > -1) {
        state.dlDetails.dlList[index]["hasEdit"] = true;
      }
    },
    updateList(state, action) {
      const index = state.dlDetails.dlList.findIndex(
        (x) => x.pdlId === action.payload.id
      );
      if (index > -1) {
        state.dlDetails.dlList[index]["hasEdit"] = false;
      }
    },
    setClickedDlListId(state, action) {
      state.clickedDlListId = action.payload.clickedDlListId;
    },
    setCallDlListApi(state, action) {
      state.callDlListApi = action.payload.callDlListApi;
    },
    setDlTargetListApi(state, action) {
      state.callDlTargetListApi = action.payload.callDlTargetListApi;
    },
    setClickedDlMemberList(state, action) {
      state.clickedDlMemberId = action.payload.clickedDlMemberId;
    },
    setErrorInEmails(state, action) {
      state.errorInEmails = action.payload.errorInEmails;
    },
    setPaginationDetails(state, action) {
      state.paginationDetails = {
        ...state.paginationDetails,
        ...action?.payload,
      }
    },
    setPaginationDetailsForDlMember(state, action) {
      state.dlMemberDetails = {
        ...state.dlMemberDetails,
        ...action?.payload,
      }
    },
    setShowBulkPopUpWhenErrors(state, action) {
      state.showBulkPopUpWhenErrors = action.payload.showBulkPopUpWhenErrors
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDlist.pending, (state, { payload }) => {
      state.isLoad = true;
      state.noData = false
    });
    builder.addCase(getDlist.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.dlDetails.dlList = payload?.response?.pdl
          ? payload?.response?.pdl
          : [];
        state.noData = payload?.response?.NoData;
        state.paginationDetails.paginationArr = payload?.response?.paginationArr
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.dlDetails.dlList = [];
        state.noData = false;
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.noData = false;
        state.dlDetails.dlList = [];
        toasterError(payload?.message);
      }
    });
    builder.addCase(getDlist.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.noData = false;
    });
    builder.addCase(saveDlist.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callDlListApi = false;
    });
    builder.addCase(saveDlist.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        //show success toaster
        toasterSuccess(PROJECT_CONST.DISTRIBUTION_CREATED_MSG);
        //remove add
        state.isShowAdd = false;
        state.callDlListApi = true;
      } else if (payload?.errorCode === 1008 && !payload?.hasError) {
        payload?.response?.validation.map((data) => {
          toasterError(data["message"]);
        });
        state.callDlListApi = true;
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(saveDlist.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callDlListApi = false;
    });
    builder.addCase(updateDlist.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callDlListApi = false;
    });
    builder.addCase(updateDlist.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.callDlListApi = true;
        //show success toaster
        toasterSuccess(PROJECT_CONST.DISTRIBUTION_UPDATED_MSG);
        dlActions.updateList({ id: payload?.response?.pdl?.pdlId });
      } else if (payload?.errorCode === 1008 && !payload?.hasError) {
        payload?.response?.validation.map((data) => {
          toasterError(data["message"]);
        });
        state.callDlListApi = true;
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(updateDlist.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callDlListApi = false;
    });
    builder.addCase(deleteDlist.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callDlListApi = false;
    });
    builder.addCase(deleteDlist.fulfilled, (state, { payload }) => {
      state.isLoad = false;
    console.log(payload, 'haii')
      if (!payload?.hasError && payload?.errorCode === -1) {
        const index = state.dlDetails.dlList.findIndex(
          (x) => x.pdlId === payload?.response?.pdlId
        );
        if (index > -1) {
          state.dlDetails.dlList.splice(index, 1);
        }
        state.callDlListApi = true;
        //show success toaster
        if(payload?.length > 1) {
          toasterSuccess(PROJECT_CONST.DISTRIBUTIONS_DELETED_MSG);
        }else{
          toasterSuccess(PROJECT_CONST.DISTRIBUTION_DELETED_MSG);
        }
        
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.dlDetails.dlList = []
        toasterError(payload?.message);
      }
    });
    builder.addCase(deleteDlist.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callDlListApi = false;
    });

    builder.addCase(getDMemberList.pending, (state, { payload }) => { 
      state.isLoad = true;
      state.noDataForDlMember = false;
    });
    builder.addCase(getDMemberList.fulfilled, (state, { payload }) => {
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.isLoad = false;
        state.dlMemberDetails.dlMemberList = payload?.response?.targetList;
        state.dlMemberDetails.pdlName = payload?.response?.pdlName;
        state.dlMemberDetails.paginationArr = payload?.response?.paginationArr;
        state.noDataForDlMember = payload?.response?.NoData
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.dlMemberDetails.dlMemberList = [];
        state.dlMemberDetails.pdlName = "";
        state.noDataForDlMember = false
        toasterError(payload?.message);
      }
    });
    builder.addCase(getDMemberList.rejected, (state, { payload }) => { 
      state.isLoad = false;
      state.noDataForDlMember = false
     });
    builder.addCase(saveDMember.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callDlTargetListApi = false;
    });
    builder.addCase(saveDMember.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.callDlTargetListApi = true;
        state.dlMemberDetails.dlMemberList = [
          payload?.response?.dt,
          ...state.dlMemberDetails.dlMemberList,
        ];
        //show success toaster
        toasterSuccess(PROJECT_CONST.DISTRIBUTION_MEMBER_SINGLE_CREATED_MSG);
        //remove modal
        state.isShowModal = false;
      } else if (
        payload?.errorCode === ERROR_CODE_CONST.VALIDATION_ERROR &&
        payload?.response &&
        payload?.response?.validation
      ) {
        payload?.response?.validation.map((data) => {
          toasterError(data["message"]);
        });
      } else if (payload?.hasError && payload?.errorCode === 1027) {
        state.dlMemberDetails.dlMemberList = [];
        state.dlMemberDetails.pdlName = '';
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.dlMemberDetails.dlMemberList = [];
        toasterError(payload?.message);
      }
    });
    builder.addCase(saveDMember.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callDlTargetListApi = false;
    });
    builder.addCase(updateDMember.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callDlTargetListApi = false;
    });
    builder.addCase(updateDMember.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.callDlTargetListApi = true;

        //show success toaster
        toasterSuccess(PROJECT_CONST.DISTRIBUTION_MEMBER_UPDATED_MSG);
        //remove modal
        state.isShowModal = false;
      } else if (
        payload?.errorCode === ERROR_CODE_CONST.VALIDATION_ERROR &&
        payload?.response &&
        payload?.response?.validation
      ) {
        payload?.response?.validation.map((data) => {
          toasterError(data["message"]);
        });
      } else if (payload?.hasError && payload?.errorCode === 1027) {
        state.dlMemberDetails.dlMemberList = [];
        state.dlMemberDetails.pdlName = '';
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(updateDMember.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callDlTargetListApi = false;
    });

    builder.addCase(deleteDMember.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callDlTargetListApi = false;
    });
    builder.addCase(deleteDMember.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.callDlTargetListApi = true;
        const index = state.dlMemberDetails.dlMemberList.findIndex(
          (x) =>
            x.distributionTargetId ===
            payload?.response?.target?.distributionTargetId
        );
        if (index > -1) {
          state.dlMemberDetails.dlMemberList.splice(index, 1);
        }
        //show success toaster
        if (payload?.listLength > 1) {
          toasterSuccess(
            PROJECT_CONST.DISTRIBUTION_MEMBER_DELETED_MSG_MULTIPLE
          );
        } else {
          toasterSuccess(PROJECT_CONST.DISTRIBUTION_MEMBER_DELETED_MSG);
        }
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.dlMemberDetails.dlMemberList = [];
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.dlMemberDetails.dlMemberList = [];
        toasterError(payload?.message);
      }
    });
    builder.addCase(deleteDMember.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callDlTargetListApi = false;
    });

    builder.addCase(verifyTarget.pending, (state, { payload }) => {
      state.isLoad = true;
      state.verifyDetails.verifySuccess = false;
    });
    builder.addCase(verifyTarget.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.verifyDetails.verifySuccess = true;
        state.verifyDetails.verifyMessage = payload?.message;
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }else if (!payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && (payload?.errorCode ===
        ERROR_CODE_CONST.INVITATION_EMAIL_LINK_EXPIRE || payload?.errorCode ===
        ERROR_CODE_CONST.INVITATION_EMAIL_SUCCESS || payload?.errorCode ===
        ERROR_CODE_CONST.INVITATION_EMAIL_USER_DELETED)
      ) {
        state.verifyDetails.verifySuccess = true;
        state.verifyDetails.verifyMessage = payload?.message;
      }
    });
    builder.addCase(verifyTarget.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(resendVerifyTarget.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callDlTargetListApi = false;
    });
    builder.addCase(resendVerifyTarget.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.callDlTargetListApi = true;
        toasterSuccess(PROJECT_CONST.DISTRIBUTION_EMAIL_SEND_MSG);
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(resendVerifyTarget.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callDlTargetListApi = false;
    });

    builder.addCase(getDistributionListName.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getDistributionListName.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.distributionListName =
          payload?.response?.ProductDistributionList.pdlName;
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.distributionListName = "";
        toasterError(payload?.message);
      }
    });
    builder.addCase(getDistributionListName.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(bulkEmailImport.pending, (state, { payload }) => {
      state.isLoad = true;
      state.closeMappingPopUp = false;
      state.closeBulkImportPopUp = false;
      state.showBulkPopUpWhenErrors = false;
    });
    builder.addCase(bulkEmailImport.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      state.closeMappingPopUp = true;
      state.callDlTargetListApi = true;
      if (payload?.response?.emails != null && payload?.errorCode === -1) {
        let emails = [];
        payload?.response?.emails.map((email) => {
          emails.push(email);
        });
        state.errorInEmails = emails;
        if (
          (PROJECT_CONST.CSV_EXTENSION.includes(payload?.fileFormat) ||
            PROJECT_CONST.XLSX_EXTENSION.includes(payload?.fileFormat)) &&
          emails.length > 0
        ) {
          state.showBulkPopUpWhenErrors = true;
        }
        toasterWarning(payload?.response?.processedEmailMsg);
        return;
      } else {
        state.closeBulkImportPopUp = true;
      }

      if (!payload?.hasError && payload?.errorCode === -1) {
        if (payload?.response?.targets.length > 1) {
          toasterSuccess(PROJECT_CONST.DISTRIBUTION_MEMBER_MULTI_CREATED_MSG);
        } else {
          toasterSuccess(PROJECT_CONST.DISTRIBUTION_MEMBER_SINGLE_CREATED_MSG);
        }
      }
    });
    builder.addCase(bulkEmailImport.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.closeMappingPopUp = false;
      state.closeBulkImportPopUp = true;
      state.showBulkPopUpWhenErrors = false;
    });
  },
});

export const dlActions = DlSlice.actions;

export default DlSlice;
