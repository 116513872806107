import React from "react";
import { lockIcon } from "../../utils/imports/images";
function Unauthorization({ message, errorCode }) {
  return (
    <div className="outer-wrapper unauthorised-page-wrapper">
      <img src={lockIcon} />
      <h1>{errorCode ? errorCode : "403"}</h1>
      <h2>{message ? message : "Access not granted!"}</h2>
    </div>
  );
}

export default Unauthorization;
