import { toast } from "react-toastify";
import moment from 'moment-timezone';
import { PROJECT_CONST } from "../constants/projectConst";
import CustomToast from "../../components/common/CustomToast";
import { DateTime } from 'luxon';
import { timeZoneAbbreviationsMap } from "../constants/abbrevations";


export const getArchiveStatus = (archiveStatus) => {
  if (archiveStatus?.level1Status || archiveStatus?.level2Status) {
    toasterWarning(PROJECT_CONST.LEVEL_2READ_ONLY_MSG);
    return true;
  }
  return false;
};

export const toasterSuccess = (msg) => {
  toast.success(msg, {
    position: toast.POSITION.TOP_RIGHT,
    theme: "colored",
    draggable: true,
    // autoClose: 50000000,
  });
};

export const toasterError = (msg) => {
  toast.error(<CustomToast message={msg} />, {
    position: toast.POSITION.TOP_RIGHT,
    theme: "colored",
    draggable: true,
  });
};

export const toasterWarning = (msg) => {
  toast.warning(<CustomToast message={msg} />, {
    position: toast.POSITION.TOP_RIGHT,
    theme: "colored",
    draggable: true,
    // autoClose: 50000000,
  });
};

export const openNewTab = (ui) => {
  let a = document.createElement("a");
  a.target = "_blank";
  a.href = ui;
  a.click();
};
export const getParam = (key) => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  return params.get(key);
};

export const getSmiley = (score) => {
  switch (score) {
    case 1:
      return "";
    // break;
    case 2:
      return "";
    // break;
    case 3:
      return "";
    // break;
    default:
      break;
  }
};

export const getSmileyText = (releaseFeedbackScore) => {
  switch (releaseFeedbackScore) {
    case 1:
      return "Sad";
    // break;
    case 2:
      return "Neutral";
    // break;
    case 3:
      return "Happy";
    // break;
    default:
      break;
  }
};

/* Fetch date and time */
export const getFormattedDate = (date, type = "") => {
  var cDate = new Date(date);
  var year = cDate.getFullYear();
  var day = (cDate.getDate() < 10 ? "0" : "") + cDate.getDate();
  var month = (cDate.getMonth() + 1 < 10 ? "0" : "") + (cDate.getMonth() + 1);
  // var time =
  //   cDate.getHours() + ":" + cDate.getMinutes() + ":" + cDate.getSeconds();
  if (type == "forDatePicker") {
    return month + "/" + day + "/" + year;
  }
  return year + "-" + month + "-" + day;
};

export const releaseFilterCount = (dataArr) => {
  var filterCount = dataArr.reduce((initialValue, v) => {
    return (initialValue += 1);
    // return (initialValue += v.filterOptions ? 1 : 0);
  }, 0);
  return filterCount;
};

export const getloginInfo = () => {
  return localStorage.getItem("loginInfo")
    ? JSON.parse(localStorage.getItem("loginInfo"))
    : {};
};

export const getDefaultLevelData = (level1List) => {
  return level1List.find((data) => {
    if (data?.level2data?.length) {
      return data;
    }
  });
};

export const getExtension = (filename) => {
  return filename.split(".").pop();
};

export const pad = (num) => {
  return num < 10 ? '0' + num : num;
};

/**
 * function to get time difference based on time zone
 * @returns 
 */
export const getTimeDifferenceBasedOnTimeZoneWithUtc = (time, timeZone) => {
  const utcMoment = moment.utc(time, 'YYYY-MM-DD HH:mm:ss');
  const zoneMoment = moment.tz(timeZone);
  const differenceMinutes = zoneMoment.utcOffset();
  const hours = Math.abs(Math.floor(differenceMinutes / 60));
  const minutes = Math.abs(differenceMinutes % 60);
  const formattedDifference = (differenceMinutes < 0 ? '-' : '+') + pad(hours) + ':' + pad(minutes);

  return formattedDifference;
}

/**
 * function to set scheduled date based on various conditions
 * @param {*} release
 * @returns
 */
export const getScheduledDateBasedOnCondition = (release, type = "") => {
  switch (release.choice) {
    case 1:
      return "";
    case 2:
      if (type == "schedule") {
        return (
          "<div><div>" +
          moment(
            convertTZ(
              new Date(JSON.parse(JSON.stringify(release?.scheduleTime1))),
              release?.schedule1TimeZone
            )
          ).format("MM/DD/YY") +
          "</div></div>"
        );
      } else {
        return (
          "<span class='time-span'>" +
          moment(
            convertTZ(
              new Date(JSON.parse(JSON.stringify(release?.scheduleTime1))),
              release?.schedule1TimeZone
            )
          ).format("MM/DD/YY | h:mm A") +
          " UTC " + getTimeDifferenceBasedOnTimeZoneWithUtc(release?.scheduleTime1, release?.schedule1TimeZone) + "</span>"
        );
      }
    case 3:
      if (type == "schedule") {
        return (
          "<div><div>" +
          moment(
            convertTZ(
              new Date(JSON.parse(JSON.stringify(release?.scheduleTime1))),
              release?.schedule1TimeZone
            )
          ).format("MM/DD/YY") +
          "</div></div>"
        );
      } else {
        return (
          "<span class='time-span'>" +
          moment(
            convertTZ(
              new Date(JSON.parse(JSON.stringify(release?.scheduleTime1))),
              release?.schedule1TimeZone
            )
          ).format("MM/DD/YY  |  h:mm A") +
          " UTC" + getTimeDifferenceBasedOnTimeZoneWithUtc(release?.scheduleTime1, release?.schedule1TimeZone) + "</span>" +
          "<span class='time-span'>" + moment(
            convertTZ(
              new Date(JSON.parse(JSON.stringify(release?.scheduleTime2))),
              release?.schedule2TimeZone
            )
          ).format("MM/DD/YY  |  h:mm A") +
          " UTC " + getTimeDifferenceBasedOnTimeZoneWithUtc(release?.scheduleTime2, release?.schedule2TimeZone) + "</span>"
        );
      }
  }
};

/**
 * function to check date given is less than todays date
 * @param {*} date
 * @returns
 */
const isDateBeforeToday = (date) => {
  return new Date(date.toDateString()) < new Date(new Date().toDateString());
};

/**
 * function to check date given is equal to todays date
 * @param {*} date
 * @returns
 */
const isDateEqualToToday = (date) => {
  return new Date(date.toDateString()) == new Date(new Date().toDateString());
};
/**
 *
 * @param {*} date
 * @returns
 */
export const copyToClipboardData = (string) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText)
    return navigator.clipboard.writeText(string);
  return Promise.reject("The Clipboard API is not available.");
};
/**
 *
 * @param {*} date
 * @returns
 */
export const regxValidation = (regex, input) => {
  return input.match(regex)?.length ? true : false;
};

export const Debounce = (fn, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => fn.apply(this, args), delay);
  };
};
export const unsecuredCopyToClipboard = (text) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Unable to copy to clipboard", err);
  }
  document.body.removeChild(textArea);
};

export const convertToPlain = (html) => {
  html = convertedInnerHtml(html).replace(/<br \/>/g, "\r\n");
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

const getTimezoneOffset = (timeZone) => {
  const now = new Date();
  const tzString = now.toLocaleString("en-US", { timeZone });
  const localString = now.toLocaleString("en-US");
  const diff = (Date.parse(localString) - Date.parse(tzString)) / 3600000;
  const offset = diff + now.getTimezoneOffset() / 60;
  const finalOffset = -offset;
  const leftSide =
    finalOffset < 0 ? Math.floor(finalOffset) * -1 : Math.floor(finalOffset);
  const rightSide = (finalOffset - Math.floor(finalOffset)) * 60;
  return (
    (-offset < 0 ? "-" : "+") +
    (leftSide < 10 ? "0" : "") +
    leftSide +
    ":" +
    (rightSide < 10 ? "0" : "") +
    rightSide
  );
};

export const convertDateWithTimezone = (date, timezone) => {
  if (date) {
    return (
      moment(date).format("YYYY-MM-DDTH:mm:ss") + getTimezoneOffset(timezone)
    );
  }
  return null;
};

export const convertTZ = (date, tzString) => {
  try {
    if (tzString) {
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString,
          }
        )
      );
    } else {
      return date;
    }
  } catch (error) {
    console.log(error, "error");
  }
};

export const generateRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

/**
 * function to check empty or not
 * @param {*} value
 * @returns
 */
export const isNotEmpty = (value) => {
  return value.trim() !== "";
};
/**
 * function to get the level 2 index
 * @param arr
 * @returns
 */
export const getLevel2Index = (arr, level1Id, level2Id) => {
  const foundIndex = arr.findIndex((x) => x.level1Id == level1Id);
  if (foundIndex != -1) {
    if (arr[foundIndex] && arr[foundIndex]["level2data"]) {
      const level2Index = arr[foundIndex]["level2data"].findIndex(
        (x) => x.level2Id == level2Id
      );
      return { level1Index: foundIndex, level2Index: level2Index };
      // if (level2Index != -1) {
      //   arr[foundIndex]["level2data"][level2Index]["level2Name"] =
      //     payload?.response?.level2?.level2Name;
      //   toasterSuccess(PROJECT_CONST.LEVEL2_UPDATED_MSG);
      // }
    }
  }
  return { level1Index: -1, level2Index: -1 };
};

/**
 * function to return converted inner html
 * @param {*} string
 * @returns
 */
export const convertedInnerHtml = (string = "") => {
  // const convertedStr = string
  //   .replace(/<img.*?\/>/g, "")
  //   .replace(
  //     /\n\n<p><span\s+class="image-wrap"[^>]*>[\s\S]*?<\/span><\/p>\n\n/gi,
  //     ""
  //   )
  //   .replace(/\n\n/g, "<br />");
  const convertedStr = string
    .replace(/<img.*?\/>/g, "")
    .replace(
      /\n\n<p><span\s+class="image-wrap"[^>]*>[\s\S]*?<\/span><\/p>\n\n/gi,
      ""
    )
    .replace(/<p><span\s+class="image-wrap"[^>]*>[\s\S]*?<\/span><\/p>/gi, "")
    .replace(/\n\n/g, "<br />");
  return convertedStr && convertedStr != "<p></p>" ? convertedStr : "N/A";
};

/**
 * function to return converted inner html
 * @param {*} value
 * @returns
 */
export const convertedDescriptionForCopytoReleaseNote = (string = "") => {
  const convertedStr = string
    .replace(/<img.*?\/>/g, "")
    .replace(/<p><span\s+class="image-wrap"[^>]*>[\s\S]*?<\/span><\/p>/gi, "");
  return convertedStr && convertedStr != "<p></p>" ? convertedStr : "N/A";
};

/**
 * function to set local storage value
 * @param {*} key 
 * @param {*} data 
 */
export const setLocalStorage = (key, data) => {
  var tabID = sessionStorage['tabID'] ? sessionStorage['tabID'] : sessionStorage['tabID'] = Math.random();
  var tabIDArr = (localStorage.getItem(tabID)) ? JSON.parse(localStorage.getItem(tabID) || '{}') : {};
  tabIDArr[key] = JSON.stringify(data);
  localStorage.setItem(tabID, JSON.stringify(tabIDArr));
}

/**
 * function to get local storage value
 * @param {*} key 
 * @returns 
 */
export const getLocalStorage = (key) => {
  var data = '';
  var tabID = sessionStorage['tabID'] ? sessionStorage['tabID'] : sessionStorage['tabID'] = Math.random();
  var dataArr = (localStorage.getItem(tabID)) ? JSON.parse(localStorage.getItem(tabID) || '{}') : {};
  if (Object.keys(dataArr).length) {
    return (dataArr[key]) ? (JSON.parse((dataArr[key]))) : '';

  }
  return data;
}

/**
 * function to remove local storage
 */
export const removeLocalStorage = (key) => {
  var tabID = sessionStorage['tabID'] ? sessionStorage['tabID'] : sessionStorage['tabID'] = Math.random();
  var dataArr = (localStorage.getItem(tabID)) ? JSON.parse(localStorage.getItem(tabID) || '{}') : {};
  if (Object.keys(dataArr).length) {
    if (dataArr.hasOwnProperty(key)) {
      delete dataArr[key];
      localStorage.setItem(tabID, JSON.stringify(dataArr));
    }
  }
}

export const getTimeBasedOnCondition = (release) => {
  switch (release.choice) {
    case 1:
      return "";
    case 2:
      return (
        moment(
          convertTZ(
            new Date(JSON.parse(JSON.stringify(release?.scheduleTime1))),
            release?.schedule1TimeZone
          )
        ).format("h:mm A") + ' UTC ' + getTimeDifferenceBasedOnTimeZoneWithUtc(release?.scheduleTime1, release?.schedule1TimeZone)

      );
    case 3:
      return (
        "<div><div>" +
        moment(
          convertTZ(
            new Date(JSON.parse(JSON.stringify(release?.scheduleTime1))),
            release?.schedule1TimeZone
          )
        ).format("h:mm A") +
        " UTC " + getTimeDifferenceBasedOnTimeZoneWithUtc(release?.scheduleTime1, release?.schedule1TimeZone) + "</div></div>"
      );
  }
}

/**
 * function to compare values for sorting
 * @param {*} key 
 * @param {*} order 
 * @returns 
 */
export const compareValues = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }
    const varA = (typeof a[key] === 'string')
      ? (/^\d+$/.test(a[key].trim())) ? parseInt(a[key]) : a[key].trim().toUpperCase() : (a[key] == null) ? "" : a[key];
    const varB = (typeof b[key] === 'string')
      ? (/^\d+$/.test(b[key].trim())) ? parseInt(b[key]) : b[key].trim().toUpperCase() : (b[key] == null) ? "" : b[key];
    let comparison = 0;

    if (typeof varA === typeof varB) {
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
    }
    else {
      if (typeof varA === "number" && varB != "") {
        comparison = -1;
      }
      else if (typeof varB === "number" && varA != "") {
        comparison = 1;
      }
      else if (typeof varA === "number" && varB === "") {
        comparison = 1;
      }
      else if (typeof varB === "number" && varA === "") {
        comparison = -1;
      }
    }

    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
}

export const getValueFromKey = (map, value) => {
  for (const key in map) {
      if (map.hasOwnProperty(key) && map[key].includes(value)) {
          return key;
      }
  }
  return '';
};

/**
 * function to convert the date and time to user  time zone
 * @param {*} inputDate
 * @returns 
 */
export const convertDateToLocal = (inputDate) => {
  try {
    let newDate = new Date(inputDate);
    let localDateString;
    let timeZoneAbbreviation;
    let options = {
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    const timeZone = DateTime.now().setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const timeZoneAbbreviations = timeZone.offsetNameLong;
    timeZoneAbbreviation = getValueFromKey(timeZoneAbbreviationsMap, timeZoneAbbreviations);
    if(timeZoneAbbreviation === null) {
      if (timeZoneAbbreviations.includes('GMT')) {
          timeZoneAbbreviation = timeZoneAbbreviations;
      }else if(timeZoneAbbreviations.includes('UTC')) {
        timeZoneAbbreviation = timeZoneAbbreviations;
      }
    }
    localDateString = new Intl.DateTimeFormat("en-US", options).format(newDate);
    var date = localDateString.split(',')[0];
    var time = localDateString.split(',')[1];
    var date1 = date.split('/')[0];
    var date2 = date.split('/')[1];
    var year = date.split('/')[2];
    localDateString = date1 + '/' + date2 + '/' + year;

    return {
      date: localDateString,
      time: time,
      zone: timeZoneAbbreviation
    }

  } catch (error) {
    console.log(error)
  }
};

export const convertDateTime = (date, time, meridiem, timeZone1) => {
  const timeZone = "Asia/Kolkata";
  const dateTimeString = `${date} ${time} ${meridiem}`;
  const utcDateTime = moment.utc(dateTimeString, "YYYY-MM-DD hh:mm A");
  const convertedDateTime = utcDateTime.tz(timeZone);
  const date1 = convertedDateTime.format("YYYY-MM-DD hh:mm A z");
  const parts = date1.split(" ");
  const newDate = parts[0];
  const newTime = parts[1];
  const newMeridiem = parts[2];

  return {
    date: newDate,
    time: newTime,
    meridiem: newMeridiem
  }
}

export const getFilterData = () => {
  return getLocalStorage("filterArrSet")
    ? JSON.parse(getLocalStorage("filterArrSet"))
    : [];
};

export const refreshEvent = (e) => {
  removeLocalStorage("filterArrSet");
};

export const allStorageData = (page = '') => {
  let arrayData = [];
  const tabID = sessionStorage['tabID'];
  arrayData.push(JSON.parse(localStorage.getItem(tabID)));
  if(page === '') {
    arrayData.push(JSON.parse(localStorage.getItem("loginInfo")));
    if(localStorage.getItem("releaseParentId")) {
      arrayData.push({
        releaseParentId: localStorage.getItem("releaseParentId")
      });
    }
    if(localStorage.getItem("releaseStatusId")) {
      arrayData.push({
        releaseStatusId: localStorage.getItem("releaseStatusId")
      });
    }
  }
  
  return arrayData;
};

export const handleCapture = async (currentReference, useNidi = false) => {
    const images = currentReference.getElementsByTagName("img");
    const promises = Array.from(images).map((img) => {
      return new Promise((resolve, reject) => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = resolve;
          img.onerror = reject;
        }
      });
    });

    try {
      await Promise.all(promises);
      if(useNidi) {
        let html2canvas = (await import('@nidi/html2canvas')).default;

       return createCanvasImage(html2canvas, currentReference)
      }else{
        const html2canvas = (await import('html2canvas')).default;

        return createCanvasImage(html2canvas, currentReference)
      }
     
    } catch (error) {
      console.error("Error capturing the image:", error);
    }
};


export const createCanvasImage = async (canvasElement, currentReference) =>{
  const canvas = await canvasElement(currentReference, {
    logging: false,
    useCORS: true,
  });
  const imgData = canvas.toDataURL("image/png");
  return imgData;
}