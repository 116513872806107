import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getloginInfo } from "../helper/commonHelper";

function AuthGuard() {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    let accessToken =
      getloginInfo() && getloginInfo()?.token?.access_token
        ? getloginInfo()?.token?.access_token
        : "";
    setAccessToken(accessToken);
    if (!accessToken) {
      navigate(`/login`);
    }
  }, []);

  return accessToken && <Outlet />;
}

export default AuthGuard;