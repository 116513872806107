import "./SubscribePopup.css";
import {
  notificationWhiteImg,
  closeDarkGreyImg,
} from "../../utils/imports/images";
import { useSelector } from "react-redux";
import { STORE_CONST } from "../../utils/constants/store";
import { useState, useEffect, useRef } from "react";
import { getTagListForFeedBack } from "../../store/api/TagApi";
import {
  getLocalStorage,
  setLocalStorage,
  toasterWarning,
  Debounce,
  toasterError,
} from "../../utils/helper/commonHelper";
import { useDispatch } from "react-redux";
import { feedbackActions } from "../../store/FeedbackSlice";
import { sendOtp, getFeedBackDetails } from "../../store/api/FeedbackApi";
import { useForm } from "react-hook-form";
import FormErrorMsg from "./FormErrorMsg";
import { useLocation } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import LoadingSpinner from "./LoadingSpinner";
import { PROJECT_CONST } from "../../utils/constants/projectConst";

function SubscribeToUpdatePopup({ level2Id, closeSubscribePopup }) {
  const dispatch = useDispatch();
  const [tagList, setSelectedTags] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isSubmit, setIsSubmit] = useState(true);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [emailSytaxError, setEmailSytaxError] = useState(false);
  const [oldEmail, setOldEmail] = useState();
  const location = useLocation();
  const currentDNSName = location.pathname.split("/")[2];
  const warningShownRef = useRef(false);
  const findLocationCustomerFeedback = location.pathname.includes(
    "feedback/customer-feedback"
  );
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm({
    mode: "all",
    defaultValues: {},
  });
  const tagListWithOutAuthorization = useSelector(
    (state) => state[STORE_CONST.TAG_NAMESPACE]?.tagListWithOutAuthorization
  );
  const feedBackDetails = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedBackDetails
  );

  const subscribeToAllFlag = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.SubscriberSendAll
  );
  const subscriberTagDetails = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.subscriberTagDetails
  );
  const level2Data = useSelector(
    (state) =>
      state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedbackLibraryDetails?.level2Data
  );

  const isLoad = useSelector(
    (state) => state[STORE_CONST.TAG_NAMESPACE]?.isLoad
  );

  const subscriberEmail = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.subscriberEmail
  );

  const level1Data = useSelector(
    (state) =>
      state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedbackLibraryDetails?.level1Data
  );
  const securityTypeDetails = useSelector(
    (state) => state[STORE_CONST.DNS_SETTINGS_NAME_SPACE]?.securityTypeDetails
  );

  const filter = (data) => {
    setFilteredData(
      tagListWithOutAuthorization.filter((tags) =>
        data ? tags.name.toLowerCase().includes(data.toLowerCase()) : tags
      )
    );
  };

  const securityType = getLocalStorage("securityType")
    ? getLocalStorage("securityType")
    : "";

  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token")
    ? decodeURIComponent(queryParameters.get("token"))
    : null;

  const dnsName = queryParameters.get("dns-name")
    ? decodeURIComponent(queryParameters.get("dns-name"))
    : "";

  const authorisationDetails = useSelector(
    (state) => state[STORE_CONST.DNS_SETTINGS_NAME_SPACE]?.authorisationDetails
  );

  const publicAuthorization = getLocalStorage("publicAuthorization");

  useEffect(() => {
    let isLibraryPage = "false";
    let dns = "";
    let level2 = 0;
    if (window.location.href.indexOf("lib") > -1) {
      isLibraryPage = "true";
      dns = currentDNSName;
      level2 = 0;
    } else {
      dns = dnsName;
      level2 = securityTypeDetails?.details?.level2Id 
    }
    dispatch(
      getTagListForFeedBack({
        header: {
          token: publicAuthorization
            ? publicAuthorization
            : authorisationDetails?.details?.token,
          dnsName: dns ? dns : "",
          securityType: securityType ? securityType : 0,
          isLibraryPage: isLibraryPage,
          level2Id: level2
        },
        param: {
          id: level2Id,
          isShowAll: true,
        },
      })
    );
  }, [publicAuthorization]);

  useEffect(() => {
    setFilteredData(tagListWithOutAuthorization);
    if (getLocalStorage("searchDataForSubscriptionTagsList") != null) {
      // filter(getLocalStorage("searchDataForSubscriptionTagsList"))
      //   setValue("search", getLocalStorage("searchDataForSubscriptionTagsList"))
    }
  }, [tagListWithOutAuthorization]);

  const requestOtp = () => {
    let isLibraryPage = "false";
    let dns = "";
    let level2 = 0;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (getValues("email").length === 0) {
      setEmailError("Please Enter Email");
      setIsSubmit(false);
    } else if (!regex.test(getValues("email"))) {
      setEmailError("Please Enter a Valid Email");
      setIsSubmit(false);
      return;
    } else {
      setLocalStorage("email", getValues("email"));
      setLocalStorage("selectedTags", tagList);
      if (window.location.href.indexOf("lib") > -1) {
        isLibraryPage = "true";
        dns = currentDNSName;
        level2 = 0;
      } else {
        dns = dnsName;
        level2 = securityTypeDetails?.details?.level2Id 
      }
      dispatch(
        sendOtp({
          header: {
            token: publicAuthorization
              ? publicAuthorization
              : authorisationDetails?.details?.token,
            dnsName: dns ? dns : "",
            securityType: securityType ? securityType : 0,
            Productid: level2Data?.level2Id ? level2Data?.level2Id : 0,
            isLibraryPage: isLibraryPage,
            level2Id: level2
          },
          param: {
            email: getValues("email"),
            customerId: level1Data?.customer?.customerId,
          },
        })
      );
    }
  };

  /**
   * function to check subscriber with mail ID
   * @param {*} event
   */
  const checkSubScriber = (event) => {
    let isLibraryPage = "false";
    let dns = "";
    let level2 = 0;
    warningShownRef.current = false;
    const publicAuthorization = getLocalStorage("publicAuthorization")
      ? getLocalStorage("publicAuthorization")
      : "";
    if (feedBackDetails?.isArchiveLevel2) {
      toasterWarning(
        "This " +
          feedBackDetails?.customerAttributes?.caLevel2Name +
          " is Read-Only"
      );
      setValue("email", feedBackDetails?.email);
      return;
    }
    if (event.target.value.length > 0) {
      setEmailError(false);
    } else {
      setEmailError(true);
      setEmailSytaxError(false);

      return;
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (emailRegex.test(event.target.value)) {
      setEmailSytaxError(false);
    } else {
      setEmailSytaxError(true);

      return;
    }
    if (emailRegex.test(event.target.value)) {
      setOldEmail(email);
      if (email != event.target.value) {
        setEmail(event.target.value);
        dispatch(
          feedbackActions.setIsLoad({
            isLoad: true,
          })
        );
        if (window.location.href.indexOf("lib") > -1) {
          isLibraryPage = "true";
          dns = currentDNSName;
          level2 = 0;
        } else {
          dns = dnsName;
          level2 = securityTypeDetails?.details?.level2Id 
        }
        dispatch(
          getFeedBackDetails({
            header: {
              token: publicAuthorization
                ? publicAuthorization
                : authorisationDetails?.details?.token,
              dnsName: dns ? dns : "",
              isLibraryPage: isLibraryPage,
              level2Id: level2
            },
            param: {
              email: event.target.value,
              releaseParentId: feedBackDetails.release?.releaseParentId
                ? feedBackDetails.release.releaseParentId
                : 0,
              level2Id: level2Id,
            },
          })
        );
      }
    }
  };

  const debouncedOnChange = Debounce(checkSubScriber, 500);

  useEffect(() => {
    if (subscriberTagDetails) {
      setSelectedTags(subscriberTagDetails);
    }
  }, [subscriberTagDetails]);

  const checkEmail = (email) => {
    if (email.length === 0) {
      setEmailError(true);
      setEmailSytaxError(false);
      return false;
    } else {
      setEmailError(false);
    }

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (emailRegex.test(email)) {
      setEmailSytaxError(false);
      return true;
    } else {
      setEmailSytaxError(true);
      return false;
    }
  };
  useEffect(() => {
    if (findLocationCustomerFeedback && subscriberEmail) {
      setValue("email", subscriberEmail);
    }
  }, [subscriberEmail]);

  useEffect(() => {
    if (
      !feedBackDetails?.subscribeEnable &&
      subscriberEmail &&
      !warningShownRef.current
    ) {
      toasterWarning(
        PROJECT_CONST.WARNING_MSG_FOR_SUBSCRIBE_TO_UPDATE +
          feedBackDetails.customerAttributes.caLevel2Name
      );
      warningShownRef.current = true;
    }
  }, [feedBackDetails, subscriberEmail]);

  return (
    <>
      {isLoad && <LoadingSpinner />}
      <div className="release-lib-popup-overlay"></div>
      <div className="release-lib-popup">
        <div className="release-lib-popup-header">
          <h3>Subscribe to Updates</h3>
          <div className="close-pop" onClick={closeSubscribePopup}></div>
        </div>
        <div className="release-lib-popup-body">
          <div className="input-search">
            <input
              type="search"
              name="search-sub-tag"
              placeholder="Search"
              onChange={(e) => {
                filter(e.target.value);
                setLocalStorage(
                  "searchDataForSubscriptionTagsList",
                  e.target.value
                );
              }}
            />
          </div>

          <div className="subscription-tag-wrapper-main">
            <div className="subscription-tag-wrapper">
              <div className="subscribe-to-all">
                Subscribe to All Releases
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={subscribeToAllFlag}
                    onChange={(e) => {
                      if (checkEmail(getValues("email"))) {
                        dispatch(
                          feedbackActions.setSubscriberSendAll({
                            subscriberSendAll: e.target.checked,
                          })
                        );
                        setIsSelectAll(!isSelectAll);
                      }
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="relese-lib-taglist">
                <Scrollbars
                  autoHeight={true}
                  autoHeightMax={`210px`}
                  autoHide={true}
                >
                  {filteredData?.length > 0 ? (
                    filteredData.map((tag, key) => (
                      <div className="relese-lib-tag-item" key={key}>
                        <label className="container-checkbox">
                          <input
                            type="checkbox"
                            onChange={() => {
                              if (checkEmail(getValues("email"))) {
                                let tagId = tag.tagId;
                                if (tagList.includes(tagId)) {
                                  setSelectedTags((current) =>
                                    current.filter((tag) => {
                                      return tag !== tagId;
                                    })
                                  );
                                } else {
                                  setSelectedTags((prev) => [
                                    ...prev,
                                    tag.tagId,
                                  ]);
                                }
                              }
                            }}
                            checked={tagList.includes(tag.tagId)}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <span className="check-text">{tag.name}</span>
                      </div>
                    ))
                  ) : (
                    <p className="no-data">No tags found!</p>
                  )}
                </Scrollbars>
              </div>
            </div>
            {/* <span class="release-lib-error">Please select a tag to subscription</span> */}
          </div>

          <div className="release-lib-subscribe-email">
            {/* <input type="text" name="email"  placeholder="Enter Email" onChange={(e) =>setEmail(e.target.value)}/> */}
            <input
              type="email"
              {...register("email")}
              placeholder="Enter Email"
              onChange={debouncedOnChange}
            />
            {/* <span class="release-lib-error">Please enter email</span> */}
            {emailError && <FormErrorMsg message={"Please Enter Email"} />}
            {emailSytaxError && (
              <FormErrorMsg message={"Please Enter a Valid Email"} />
            )}
          </div>
        </div>
        <div className="release-lib-popup-footer">
          <button
            className="btn-lib-common btn-light"
            onClick={closeSubscribePopup}
          >
            <img src={closeDarkGreyImg} width="12" />
            Close
          </button>
          <button
            className={`btn-lib-common btn-dark ${
              !feedBackDetails?.subscribeEnable && subscriberEmail.length !== 0
                ? "disabled"
                : ""
            }`}
            onClick={requestOtp}
            disabled={
              !feedBackDetails?.subscribeEnable && subscriberEmail.length !== 0
            }
          >
            <img src={notificationWhiteImg} width="18" />
            Subscribe to Updates
          </button>
        </div>
      </div>
    </>
  );
}

export default SubscribeToUpdatePopup;
