import React from "react";
import "./Confirmation.css";

function Confirmation({ customMsg='', responseAction, warning, heading }) {
  return (
    <React.Fragment>
      <div className="popup-overlay"></div>
      <div className="popup-min confirmation-popup">
        <div className="popup-min-top">
          <h2>{heading ? heading : (customMsg != '')? customMsg: "Are you sure you want to delete ?"}</h2>
          <span style={{color: 'red', fontSize: '13px'}}>{warning ? warning : ""}</span>
        </div>
        <div className="popup-min-bottom flex">
          <div className="popup-min-bottom-right flex">
          <button
              onClick={(event) => responseAction({ isCancel: true })}
              className="blue-button confirmation-icon-padding close"
            >
              No
            </button>
            <button
              onClick={(event) => responseAction({ isSubmit: true })}
              className="blue-button confirmation-icon-padding save"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Confirmation;
