import ProvideFeedBack from "./ProvideFeedBack"
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useLayoutEffect } from "react";
import { getLocalStorage, setLocalStorage } from "../../utils/helper/commonHelper";
import { STORE_CONST } from "../../utils/constants/store";
import { toasterWarning } from "../../utils/helper/commonHelper";
import { feedbackActions } from "../../store/FeedbackSlice";
import { getFeedBackDetails, sendOtp, submitOtp, reSendOtp, updateFeedBack, feedBackSubmit, deleteFeedback, unsubscribeUser, saveSubscriberPreferenceData } from "../../store/api/FeedbackApi";
import { Debounce } from "../../utils/helper/commonHelper";
import TwoFactorAuthenticationPopup from "../common/OtpVerificationPopup";
import FormErrorMsg from "../common/FormErrorMsg";
import { closeDarkGreyImg, publish } from "../../utils/imports/images";
import { PROJECT_CONST } from "../../utils/constants/projectConst";
import { tagActions } from "../../store/TagSlice";
import PublicReleaseTags from "../library/ReleaseTags";
import moment from "moment";
import Scrollbars from "react-custom-scrollbars-2";

function LibraryFeedBackSubmit ({showSubscribePopup}) {

    
    const dispatch = useDispatch();
    const [releaseNote, setReleaseNote] = useState("");
    const [popUpShow, setTagPopUpShow] = useState(false);
    const [tags, setTags] = useState([]);
    const [email, setEmail] = useState();
    const [oldEmail, setOldEmail] = useState();
    const [submitData, setSubmitData] = useState(false);
    const [isShowConfirmation, showDeleteConfirmation] = useState(false);
    const [filterHeight, setFilterHeight] = useState(300);
    const [unSubscribeShow, setUnSubscribeShow] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [subscriptionDetails, setSubscriptionDetails] = useState({});
    const [setSubscriptionPopUp, setSubscriptionPreferencePopUp] =
      useState(false);
    const [pageNotFound, setPageNotFound] = useState(false);
    const [emailSytaxError, setEmailSytaxError] = useState(false);
  
    const securityType = getLocalStorage("securityType")
      ? getLocalStorage("securityType")
      : "";
    const feedBackDetails = useSelector(
      (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedBackDetails
    );
    const sessionToken = useSelector(
      (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.sessionToken
    );
    const isLoad = useSelector(
      (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.isLoad
    );
    const hideOtpScreen = useSelector(
      (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.hideOtpScreen
    );
    const queryParameters = new URLSearchParams(window.location.search);
    const token = queryParameters.get("token")
      ? decodeURIComponent(queryParameters.get("token"))
      : null;
    const dnsName = queryParameters.get("dns-name")
      ? decodeURIComponent(queryParameters.get("dns-name"))
      : '';
    const closeFeedBackDeletePopUp = useSelector(
      (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.closeFeedBackDeletePopUp
    );
    const closeUnsubscribePopUp = useSelector(
      (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.closeUnsubscribePopUp
    );
    const closeSubscriptionPreferencePopUp = useSelector(
      (state) =>
        state[STORE_CONST.FEEDBACK_NAMESPACE]?.closeSubscriptionPreferencePopUp
    );
    const otpType = useSelector(
      (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.otpType
    );
    const showSubscriptionPopUpFromSubscription = useSelector(
      (state) => state[STORE_CONST.TAG_NAMESPACE]?.showSubscriptionPreference
    );
    const openOtpScreen = useSelector(
      (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.openOtpScreen
    );
    const authorisationDetails = useSelector(
      (state) => state[STORE_CONST.DNS_SETTINGS_NAME_SPACE]?.authorisationDetails
    );
    const newToken = useSelector(
      (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.newToken
    );
    const classActive = useSelector(
      (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.classActive
    );
    const redirect = useSelector(
      (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.redirect
    );

    const feedbackLibraryDetails = useSelector(
      (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedbackLibraryDetails
    );

    const subscriberId = useSelector(
      (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.subscriberId
    );
    const level2Data = useSelector(
      (state) =>
        state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedbackLibraryDetails?.level2Data
    );

    const subscribeToAll = useSelector(
      (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.SubscriberSendAll
    );

    const feedbackSuccess = useSelector(
      (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedbackSuccess
    );

    const level1Data = useSelector(
      (state) =>
        state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedbackLibraryDetails?.level1Data
    );
    const securityTypeDetails = useSelector(
      (state) => state[STORE_CONST.DNS_SETTINGS_NAME_SPACE]?.securityTypeDetails
    );
    const {
      control,
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      reset,
      setValue,
    } = useForm({
      mode: "all",
      defaultValues: {},
    });
  
    useEffect(() => {
      if (closeSubscriptionPreferencePopUp) {
        setSubscriptionPreferencePopUp(false);
        dispatch(
          feedbackActions.setCloseSubscriptionPreferencePopUp({
            closeSubscriptionPreferencePopUp: false,
          })
        );
      }
    }, [closeSubscriptionPreferencePopUp]);
  
    useEffect(() => {
      window.addEventListener("resize", handleWindowResize);
      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    }, []);
  
    useEffect(() => {
      window.addEventListener("resize", handleWindowResize);
      return () => {
        window.removeEventListener("resize", handleWindowResize);
      };
    }, []);
  
    const handleWindowResize = () => {
      if (window.innerHeight < 700) {
        setFilterHeight(180);
      } else {
        setFilterHeight(300);
      }
    };
  
    useLayoutEffect(() => {
      handleWindowResize();
    }, []);
  
    const showOtpScreen = (type = 0) => {
      dispatch(
        feedbackActions.setOtpType({
          otpType: type,
        })
      );
      dispatch(
        sendOtp(
          {
            header: {
              token: authorisationDetails?.details?.token
                ? authorisationDetails?.details?.token
                : "",
              dnsName: feedBackDetails?.release?.level2Data?.level2DNSRegistrationName,
              securityType: securityType ? securityType : 0,
              isLibraryPage: 'false',
              level2Id: securityTypeDetails?.details?.level2Id 
            },
            param: {
              email: email,
              releaseParentId: feedBackDetails?.release?.releaseParentId,
              otpType: type,
              customerId: level1Data?.customer?.customerId,
            }
          }
        )
      );
    };
  
    /**
     * function to submit feedback data
     */
    const submitFeedBackData = () => {
      if (feedBackDetails.feedback.releaseFeedbackId != 0) {
        dispatch(
          updateFeedBack(
            {
              header: {
                token: authorisationDetails?.details?.token
                  ? authorisationDetails?.details?.token
                  : "",
                dnsName: feedBackDetails?.release?.level2Data?.level2DNSRegistrationName,
                securityType: securityType ? securityType : 0,
                level2Id: securityTypeDetails?.details?.level2Id 
              },
              param: {
                feedbackId: feedBackDetails.feedback.releaseFeedbackId,
                scoreId: classActive?.classActive,
                feedbackNote: getValues("feedBackData"),
                releaseParentId: feedBackDetails.release.releaseParentId,
                email: getValues("email"),
                token: newToken ? newToken : token,
                level2Id: feedBackDetails?.level2Id,
              }
            })
        );
        dispatch(
          feedbackActions.setIsLoad({
            isLoad: true,
          })
        );
        setValue("feedBackData", "");
      } else {
        if (getValues("email")) {
          setEmailError(false);
          dispatch(
            feedBackSubmit(
              {
                header: {
                  token: authorisationDetails?.details?.token
                    ? authorisationDetails?.details?.token
                    : "",
                  dnsName: feedBackDetails?.release?.level2Data?.level2DNSRegistrationName,
                  securityType: securityType ? securityType : 0,
                  level2Id: securityTypeDetails?.details?.level2Id  
                },
                param: {
                  feedbackNote: getValues("feedBackData"),
                  email: getValues("email"),
                  scoreId: classActive?.classActive,
                  recipientId: feedBackDetails.recipientId,
                  token: newToken ? newToken : token,
                  releaseParentId: feedBackDetails.release.releaseParentId,
                  level2Id: feedBackDetails?.level2Id,
                }
              })
          );
          dispatch(
            feedbackActions.setIsLoad({
              isLoad: true,
            })
          );
          setValue("feedBackData", "");
        } else {
          setEmailError(true);
        }
      }
    };
  
    /**
     * function to set values
     */
    useEffect(() => {
      if (Object.keys(feedBackDetails).length > 0) {
        setLocalStorage("activeLevelTwoId", feedBackDetails?.level2Id);
        setValue("email", feedBackDetails?.email);
        if (!feedBackDetails?.recipientId) {
          setValue("feedBackData", "");
          dispatch(feedbackActions.setClassActiveInFeedBack({
            classActive: 0
          }))
        } else {
          setValue(
            "feedBackData",
            feedBackDetails?.feedback?.releaseFeedbackNote?feedBackDetails?.feedback?.releaseFeedbackNote: ""
          );
          dispatch(feedbackActions.setClassActiveInFeedBack({
            classActive: feedBackDetails?.feedback?.scoreId == 0
              ? 1
              : feedBackDetails?.feedback?.scoreId
          }))
        }
        setEmail(feedBackDetails?.email);
        setReleaseNote(feedBackDetails?.release?.releaseNote);
      }
    }, [feedBackDetails]);
  
    /**
     * for delete action (closing pop up)
     */
    useEffect(() => {
      if (closeFeedBackDeletePopUp) {
        showDeleteConfirmation(false);
      }
    }, [closeFeedBackDeletePopUp]);
  
    /**
     * for unsubscribe action (closing pop up)
     */
    useEffect(() => {
      if (closeUnsubscribePopUp) {
        setUnSubscribeShow(false);
      }
    }, [closeUnsubscribePopUp]);
  
    /**
     * function to close tag pop up
     */
    const closeTagpopup = () => {
      setTagPopUpShow(false);
    };
  
    /**
     * function to check subscriber with mail ID
     * @param {*} event
     */
    const checkSubScriber = (event) => {
      if (feedBackDetails?.isArchiveLevel2) {
        toasterWarning('This ' + feedBackDetails?.customerAttributes?.caLevel2Name + ' is Read-Only');
        setValue("email", feedBackDetails?.email);
        return;
      }
      if (event.target.value.length > 0) {
        setEmailError(false);
      } else {
        setEmailError(true);
        setEmailSytaxError(false)
  
        return;
      }
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (emailRegex.test(event.target.value)) {
        setEmailSytaxError(false)
      } else {
        setEmailSytaxError(true)
  
        return;
      }
      if (emailRegex.test(event.target.value)) {
        setOldEmail(email);
        if (email != event.target.value) {
          setEmail(event.target.value);
          dispatch(
            feedbackActions.setIsLoad({
              isLoad: true,
            })
          );
          dispatch(
            getFeedBackDetails({
              header: {
                token: authorisationDetails?.details?.token
                  ? authorisationDetails?.details?.token
                  : "",
                dnsName: dnsName ? dnsName : '',
                isLibraryPage: 'false',
                level2Id: securityTypeDetails?.details?.level2Id 
              },
              param: {
                email: event.target.value,
                releaseParentId: feedBackDetails.release.releaseParentId,
              }
            })
          );
        }
      }
    };
  
    /**
     * function to submit OTP
     * @param {*} data
     */
    const submitOtpData = (data) => {
      let extraParam = {};
      setSubmitData(true);
      dispatch(
        feedbackActions.setIsLoad({
          isLoad: true,
        })
      );
      extraParam = {
        feedbackNote: getValues("feedBackData"),
        scoreId: classActive,
      }
      dispatch(
        submitOtp({
          header: {
            token: authorisationDetails?.details?.token
              ? authorisationDetails?.details?.token
              : "",
            dnsName: dnsName? dnsName: feedBackDetails?.release?.level2Data?.level2DNSRegistrationName,
            securityType: securityType ? securityType : 0,
            isLibraryPage: 'false',
            level2Id: securityTypeDetails?.details?.level2Id 
          },
          param: {
            email: email,
            sessionToken: sessionToken,
            otp: Number(data),
            level2Id: feedBackDetails?.level2Id,
            releaseParentId: feedBackDetails.release.releaseParentId,
            otpType: otpType,
          },
          extraParam: extraParam
        })
      );
      dispatch(feedbackActions.setClassActiveInFeedBack({
        classActive: classActive?.classActive
      }))
    };
  
    /**
     * function to cancel OTP screen
     */
    const cancelPopUp = () => {
      if (!submitData) {
        setEmail(oldEmail ? oldEmail : email);
        setValue("email", oldEmail ? oldEmail : email);
      }
      dispatch(
        feedbackActions.setOpenOtpScreen({
          setopenOtpScreen: false,
        })
      );
    };
  
    /**
     * function to re-send OTP
     */
    const resendOtp = () => {
      dispatch(
        feedbackActions.setIsLoad({
          isLoad: true,
        })
      );
      dispatch(
        reSendOtp({
          header: {
            token: authorisationDetails?.details?.token
              ? authorisationDetails?.details?.token
              : "",
            dnsName: feedBackDetails?.release?.level2Data?.level2DNSRegistrationName,
            securityType: securityType ? securityType : 0,
            isLibraryPage: 'false',
            level2Id: securityTypeDetails?.details?.level2Id 
          },
          param: {
            sessionToken: sessionToken,
            releaseParentId: feedBackDetails.release.releaseParentId,
            otpType: otpType,
            customerId: level1Data?.customer?.customerId,
          }
        })
      );
    };
  
    /**
     * function to delete feedback
     * @param {*} feedBackId
     */
    const deleteFeedBack = (feedBackId) => {
      dispatch(
        feedbackActions.setIsLoad({
          isLoad: true,
        })
      );
      dispatch(
        deleteFeedback(
          {
            header: {
              token: authorisationDetails?.details?.token
                ? authorisationDetails?.details?.token
                : "",
              dnsName: feedBackDetails?.release?.level2Data?.level2DNSRegistrationName,
              securityType: securityType ? securityType : 0,
              level2Id: securityTypeDetails?.details?.level2Id 
            },
            param: {
              token: newToken ? newToken : token,
              feedbackId: feedBackId,
              level2Id: feedBackDetails?.level2Id,
              releaseParentId: feedBackDetails.release.releaseParentId
            }
          })
      );
      setValue("feedBackData", "");
    };
  
    useEffect(() => {
      if (showSubscriptionPopUpFromSubscription?.isShow && !emailError) {
        setSubscriptionPreferencePopUp(true);
      }
    }, [showSubscriptionPopUpFromSubscription]);
  
    const subscriptionPreferences = () => {
      setSubscriptionPreferencePopUp(true);
    };
    const cancelPopUpForSubscriptionPreference = () => {
      setSubscriptionPreferencePopUp(false);
      dispatch(
        tagActions.setSubscriptionPreferencePopUpShowFromSubscription({
          isShow: false,
        })
      );
    };
    const submitSubscriptionPreferences = () => { };
  
    const debouncedOnChange = Debounce(checkSubScriber, 500);
  
    const formatTags = (tags) => {
      let array = [];
      tags.map((data, i) => {
        array.push({
          label: data?.tag?.name,
          color: data?.tag?.tagColour
        })
      })
      setTags(array);
    }
  
    const removeTags = () => { };
  
    useEffect(() => {
      if (hideOtpScreen) {
        dispatch(
          feedbackActions.setHideOtpScreen({
            hideOtpScreen: false,
          })
        );
        if (otpType == PROJECT_CONST.OTP_TYPE_SUBMIT_FEEDBACK) {
          dispatch(
            feedbackActions.setIsLoad({
              isLoad: true,
            })
          );
          submitFeedBackData();
        } else if (otpType == PROJECT_CONST.OTP_TYPE_UNSUBSCRIBE) {
          dispatch(
            feedbackActions.setIsLoad({
              isLoad: true,
            })
          );
          dispatch(
            unsubscribeUser(
              {
                header: {
                  token: authorisationDetails?.details?.token
                    ? authorisationDetails?.details?.token
                    : "",
                  dnsName: feedBackDetails?.release?.level2Data?.level2DNSRegistrationName,
                  securityType: securityType ? securityType : 0,
                  level2Id: securityTypeDetails?.details?.level2Id 
                },
                param: {
                  token: newToken ? newToken : token,
                  level2Id: feedBackDetails?.level2Id,
                  subscriberId: feedBackDetails?.subscriber?.SubscriberID,
                }
              })
          );
        } else if (otpType == PROJECT_CONST.OTP_TYPE_DELETE_FEEDBACK) {
          deleteFeedBack(feedBackDetails?.feedback?.releaseFeedbackId);
        } else if (otpType == PROJECT_CONST.OTP_TYPE_SUBSCRIBE) {
          dispatch(
            feedbackActions.setIsLoad({
              isLoad: true,
            })
          );
          dispatch(saveSubscriberPreferenceData({
            header: {
              token: authorisationDetails?.details?.token
                ? authorisationDetails?.details?.token
                : "",
              dnsName: feedBackDetails?.release?.level2Data?.level2DNSRegistrationName,
              securityType: securityType ? securityType : 0,
              level2Id: securityTypeDetails?.details?.level2Id 
            },
            // subscriptionDetails
            subscriptionDetails: {subscriberid: subscriberId, level2id:level2Data.level2Id, sendall:subscribeToAll, email: getLocalStorage('email'), tagids: getLocalStorage('selectedTags'), token:token ? token : null}
          })
          );
          dispatch(
            tagActions.setSubscriptionPreferencePopUpShowFromSubscription({
              isShow: false,
            })
          );
          dispatch(
            feedbackActions.setIsLoad({
              isLoad: true,
            })
          );
        }
      }
    }, [classActive])

    const contributors = feedbackLibraryDetails?.contributors?.map(contributor => 
      `${contributor.userFirstName} ${contributor.userLastName}`
    ) || [];

    useEffect( () => {
      if(!feedBackDetails?.feedbackEnable && !feedbackSuccess && getValues("email") && !showSubscribePopup){
        toasterWarning(PROJECT_CONST.WARNING_MSG_FOR_ALLOW_TO_LEAVE_FEEDBACK+feedBackDetails.customerAttributes.caLevel2Name)
      }
  
    },[feedBackDetails])

    return(
        <>
         {popUpShow && <PublicReleaseTags tags={tags} removeTags={removeTags} closeTagpopup={closeTagpopup} />}
         {openOtpScreen && (
            <TwoFactorAuthenticationPopup
            submitOtpData={submitOtpData}
            cancelPopUp={cancelPopUp}
            resendOtp={resendOtp}
            ></TwoFactorAuthenticationPopup>
        )}
         <div className={`release-libraray-detail-page-main-wrapper ${ feedbackSuccess ? "feedback-success" :""}    release-page-center`}>
            <div className="release-libraray-detail-description-wrapper">
                <h3>{ feedBackDetails?.release?.releaseShortName }</h3>
                <div className="release-libraray-detail-description">
                    <div className="release-library-tag-list-wrapper">

                    { feedBackDetails?.release?.releaseTags !==  null && feedBackDetails?.release?.releaseTags
                    .slice(0, 6)
                    .map((tag, i) => (
                      <div className="release-library-tag-item" style={{ backgroundColor:  tag.tag.tagColour+"32", color: tag.tag.tagColour }} key={i}>
                      <span
                        key={tag?.tag?.tagId}
                        style={{ backgroundColor: tag.tag.tagColour }}
                        className="tag-color"
                      >  </span>
                        <span className="tag-text">{tag.tag.name}</span>
                    
                      </div>
                    ))}
                    { feedBackDetails?.release?.releaseTags !==  null && feedBackDetails?.release?.releaseTags.length > 5 ?
                    <div className="multiselect-more-items" onClick={(e) => {
                        formatTags(feedBackDetails?.release.releaseTags);
                        setTagPopUpShow(true);
                      }}>{ feedBackDetails?.release?.releaseTags !==  null && feedBackDetails?.release?.releaseTags.length - 5} more</div>:""
                    }


                    </div>
                    <div className="release-libraray-detail-top-details">
                        <p><span>Release Date:</span>
                        {" "}
                          {moment(feedBackDetails?.release?.releaseCreateDate).format(
                    "MMMM DD, YYYY"
                  )}</p>
                        <p><span>Author:</span> {feedBackDetails?.release?.createdByUserData?.userFullName}</p>
                        <p><span>Contributors: <span> {contributors.join(', ')} </span></span>
                       
                        </p>
                    </div>
                    <div className="release-libraray-detail-bottom-details-main">
                    <Scrollbars
                      autoHeight={true}
                      autoHeightMax={`calc(100vh - 395px)`}
                      autoHide={true}
                    >
                    <div className="release-libraray-detail-bottom-details">
                    <div dangerouslySetInnerHTML={{ __html: releaseNote }}></div>
                    </div>
                    </Scrollbars>
                    </div>
                </div>
            </div>
            { feedbackSuccess ?
            <div className="release-libraray-submitted-feedback">
                Thank you! Your feedback has been successfully submitted. We truly appreciate you taking the time to share your thoughts with us.
            </div> 
            :
            <div className="release-libraray-provide-feedback-wrapper">
                <h3>Provide Feedback</h3>
                <div className="release-libraray-provide-feedback-form-wrapper">
                    <div className="release-libraray-provide-feedback-form-group">
                        <label>How do you feel about this release?</label>
                        <div className="release-libraray-feedback-reactions">
                          
                             {feedBackDetails.emojis &&
                feedBackDetails.emojis
                  .filter((emojis) => emojis.score !== 1)
                  .map((emoji, i) => (
                    <div
                      key={i}
                      className={`release-libraray-reaction-item ${classActive?.classActive == emoji.score ? "active" : ""
                        }`}
                      onClick={() => {
                        // if (feedBackDetails?.isArchiveLevel2) {
                        //   toasterWarning(PROJECT_CONST.LEVEL_2READ_ONLY_MSG);
                        //   return;
                        // }
                        dispatch(feedbackActions.setClassActiveInFeedBack({
                          classActive: emoji.score
                        }))
                      }}
                    >
                      <img src={emoji.emoji} alt={emoji?.emojiLabel} title={emoji?.emojiLabel} />
                      <p>{ emoji?.emojiLabel }</p>
                    </div>
                  ))}
                        </div>
                    </div>
                    <div className="release-libraray-provide-feedback-form-group summary">
                        <label>Provide Summary of Feedback:</label>
                        <textarea {...register("feedBackData")}></textarea>
                    </div>
                    <div className="release-libraray-provide-feedback-form-group">
                        <label>Enter Email:</label>
                        <input
                        type="email"
                        {...register("email")}
                        placeholder="Please enter your email to leave feedback"
                        onChange={debouncedOnChange}
                        />
                    </div>
                      {emailError && (<FormErrorMsg message={'Please Enter Email'} />)}
                    {emailSytaxError && (<FormErrorMsg message={'Please Enter a Valid Email'} />)}
                    <div className="release-libraray-provide-feedback-form-btn-wrapper">
                     
               {
                      // (publicAuthorization &&
                      //   feedBackDetails?.release?.level2Data?.dnsSecurityTypeID ==
                      //     PROJECT_CONST.SECURITY_TYPE_JWT) ||
                      feedBackDetails?.release?.level2Data?.dnsSecurityTypeID ==
                        PROJECT_CONST.SECURITY_TYPE_PUBLIC ||
                        feedBackDetails?.release?.level2Data?.dnsSecurityTypeID ==
                        PROJECT_CONST.SECURITY_TYPE_PASSWORD ? (
                          <button className="btn-lib-common btn-light"  onClick={() => {
                            // window.location.href = feedBackDetails.libraryUrl.replace("www.","http://");
                            // window.location.href = "http://qa.simplereleasenotes.com/lib/trustaffMCalc2";
                            window.location.replace(feedBackDetails.libraryUrl);
                      }}><img src={closeDarkGreyImg} /> Close</button>
                      ) : (
                        ""
                      )
                    }
                    <button className={`btn-lib-common btn-dark ${!feedBackDetails?.feedbackEnable ? "disabled" : ""}`}   onClick={handleSubmit((data) => {
                    // if (feedBackDetails?.isArchiveLevel2) {
                    //   toasterWarning(PROJECT_CONST.LEVEL_2READ_ONLY_MSG);
                    //   return;
                    // }
                    if (getValues("email") === '') {
                      setEmailError(true);

                      return;
                    } else {
                      setEmailError(false);
                    }
                    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                    if (emailRegex.test(getValues("email"))) {
                      setEmailSytaxError(false);
                    } else {
                      setEmailSytaxError(true);

                      return;
                    }

                    showOtpScreen(PROJECT_CONST.OTP_TYPE_SUBMIT_FEEDBACK);
                  })} disabled={!feedBackDetails?.feedbackEnable}><img src={publish} width="14"/> Submit</button>
                    </div>
                </div>
            </div>
          }
        </div>
        </>
    )
}

export default LibraryFeedBackSubmit