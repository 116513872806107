import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";
import { authentication, resetPassword } from "./api/AutorizationApi";
import { setLocalStorage } from "../utils/helper/commonHelper";
import { toasterSuccess } from "../utils/helper/commonHelper";
import { PROJECT_CONST } from "../utils/constants/projectConst";
import { ERROR_CODE_CONST } from "../utils/constants/errorCode";
import { toasterError } from "../utils/helper/commonHelper";

const AutorizationSlice = createSlice({
  name: STORE_CONST.AUTORIZATION_NAMESPACE,
  initialState: {
    isLoad: false,
    redirectRelease: false,
    hasLogout: false,
  },
  reducers: {
    logout(state, action) {
      state.hasLogout = action.payload.hasLogout;
    },
    setRedirectRelease(state, action) {
      state.redirectRelease = action.payload.redirectRelease;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authentication.pending, (state, { payload }) => {
      state.isLoad = true;
      state.redirectRelease = false;
    });
    builder.addCase(authentication.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        localStorage.setItem("loginInfo", JSON.stringify(payload?.response));
        setLocalStorage("sort", '');
        state.redirectRelease = true;
      }
    });
    builder.addCase(authentication.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(resetPassword.pending, (state, {payload}) => {
      state.isLoad = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, {payload}) => {
        state.isLoad = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          toasterSuccess(PROJECT_CONST.USER_RESET_PASSWORD_SUCCESS_MSG);
        } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
          && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
          ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
        ) {
          toasterError(payload?.message);
        }
    });
    builder.addCase(resetPassword.rejected, (state, {payload}) => {
      state.isLoad = false;
    });
  },
});

export const autorizationActions = AutorizationSlice.actions;

export default AutorizationSlice;
