 import { logoReleaseImg,releaseNoteLogo, returnImg } from "../../utils/imports/images";
 import { STORE_CONST } from "../../utils/constants/store";
 import { useSelector } from "react-redux";
 import Release_Number from "../../data/Release_Number.json"
 import { useState, useEffect } from "react";
 import { useLocation } from "react-router-dom";
 import { PROJECT_CONST } from "../../utils/constants/projectConst";
 
 function ReleaseTitle () {
  const location = useLocation();
  const findLocationCustomerFeedback = location.pathname.includes('feedback/customer-feedback')
    const [currentReleaseVersion, setCurrentReleaseVersion] = useState("")

      const level2Data = useSelector(
        (state) =>
          state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedbackLibraryDetails?.level2Data
      );

      const level1Data = useSelector(
        (state) =>
          state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedbackLibraryDetails?.level1Data
      );

      const feedBackDetails = useSelector(
        (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedBackDetails
      );

        // get the application current release version

  useEffect(() => {
    if(Release_Number){
      setCurrentReleaseVersion(Release_Number.releases[0]?.Release_Number)
    }
  },[Release_Number])

    return (
        <>
        <div className="release-title-wrapper release-page-center">
        <div className="release-title-left-wrapper">
            <div className="release-title-left">
                <h1>Release Notes Library</h1>
                <h2>{level1Data?.level1Name} | {level2Data?.level2Name} </h2>
            </div>
            {
              // (publicAuthorization &&
              //   feedBackDetails?.release?.level2Data?.dnsSecurityTypeID ==
              //     PROJECT_CONST.SECURITY_TYPE_JWT) ||
              findLocationCustomerFeedback && (
              feedBackDetails?.release?.level2Data?.dnsSecurityTypeID ==
                PROJECT_CONST.SECURITY_TYPE_PUBLIC ||
                feedBackDetails?.release?.level2Data?.dnsSecurityTypeID ==
                PROJECT_CONST.SECURITY_TYPE_PASSWORD) ? (
                  <button className="btn-bg-grey"  onClick={() => {
                    // window.location.href = feedBackDetails.libraryUrl.replace("www.","http://");
                    // window.location.href = "http://qa.simplereleasenotes.com/lib/trustaffMCalc2";
                    window.location.replace(feedBackDetails.libraryUrl);
              }}><img src={returnImg} /> Return to Library</button>
              ) : (
                ""
              )
            }
          
          </div>
            <div className="powered-by">
                <p>Powered by</p>
                {/* <img src={logoReleaseImg}/> */}
                <div className="top-icons-wrapper flex">
                  <div className="logo-icon" onClick={() => window.location.reload()}>
                    <img src={releaseNoteLogo} />
                    <div className="logo-icon-text">
                      <h1>Simple Release Notes</h1>
                      <small>Show off your work</small>
                      <p>Version {currentReleaseVersion}</p>
                    </div>

                   </div>
                </div>
                {/* <p>Release Version {currentReleaseVersion}</p> */}
            </div>
        </div>
        </>
    )
 }

 export default ReleaseTitle;