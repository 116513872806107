import React from "react";

function FormErrorMsg({ message }) {
  return (
    <>
      <span className="error-message">{message}</span>
    </>
  );
}

export default FormErrorMsg;
