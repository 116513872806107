import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";
import {
  releaseNoteList,
  releaseNoteDetails,
  releaseNoteCreate,
  updateReleaseNote,
  getStatusList,
  // getBarChartDetails,
  // getSubscribers,
  getReleaseHistory,
  deleteReleaseNote,
  releaseNotePreview,
  uploadFile,
  getScheduleList,
  getJiraCredentials,
  getJiraKeys,
  getProjects,
  getTicketData,
  saveJiraCred,
  updateJiraCred,
  getJiraLoadedDetails,
  getJiraDetails,
  releaseNoteFeedbackGridDetails,
  releaseNoteFeedbackList,
  createJiraTicketForAnRelease,
  getCollaborators,
  deleteUploadedFile
} from "./api/ReleaseNoteApi";
import { PROJECT_CONST } from "../utils/constants/projectConst";
import { setLocalStorage, toasterError, toasterSuccess } from "../utils/helper/commonHelper";
import { ERROR_CODE_CONST } from "../utils/constants/errorCode";

const ReleaseNoteSlice = createSlice({
  name: STORE_CONST.RELEASENOTE_NAMESPACE,
  initialState: {
    isLoad: false,
    callReleaseListApiAfterStatusListApi: false,
    releaseCount: 0,
    statusArray: [],
    filterType: PROJECT_CONST.FILTER_TYPE_INITIAL,
    releaseNote: {
      details: {},
      hasApiLoaded: false,
    },
    releaseDetails: {
      list: [],
      draftCount: 0,
      publishedCount: 0,
      currentMonthYear: "",
      releaseFetchCompleted: false,
    },
    redirectReleaseNote: {
      success: false,
      releaseId: 0,
    },
    releasewithfeedbackkey: {},
    filterStatus: PROJECT_CONST.ALL_STATUS_FILTER,
    publishApicall: {
      hasApiCall: false,
      apiInput: {},
    },
    statusList: [],
    filterApplied: false,
    type: "",
    barchartDetails: {},
    subscriberDetails: {},
    historyDetails: {
      list: [],
    },
    releaseNotePreview: "",
    scheduleDetails: {
      list: []
    },
    jiraCredentialDetails: {
      list: [],
      closeJiraPopup: {
        hasClose: false,
        credentialId: 0,
      },
      hasApiLoaded: false,
      newJira:"",
    },
    jiraKeyDetails: {
      list: [],
    },
    jiraProjectDetails: {
      list: [],
      hasApiLoaded: false,
    },
    jiraTicketDetails: {
      tableList: {},
      url: "",
      issueName: [],
    },
    jiraLoadedDetails: {
      details: {},
      hasApiLoaded: false,
    },
    jiraEditDetails: {
      details: {},
    },
    temporaryReleaseNoteDetails: {},
    tagLists: [],
    copyToReleaseNote: "",
    open: false,
    tagsList: [],
    releaseNoteFeedbackGrid: {
      list: [],
      hasApiLoaded: false,
    },
    releaseNoteFeedback: {
      list: [],
      hasApiLoaded: false,
    },
    collaboratorDetails: {
      list: [],
      hasApiLoaded: false,
      openCollaboratorPopUp: false,
    },
    clickedReleaseId: 0,
    noDataWithSearch: false,
    closefeedBackJiraPopUp: false,
    callGlobalFeedbackApiFromRelease: false,
    callReleaseListApi: false,
    sort: {},
    clickedReleaseIdForSchedule: null,
    releaseData: {},
    releaseNoteErrors: {
      releaseShortNameLengthError: false,
      releaseShortNameError: false
    },
    releaseDescriptionCount: 0
  },
  reducers: {
    setClosefeedBackJiraPopUp(state, action) {
      state.closefeedBackJiraPopUp = action.payload.closefeedBackJiraPopUp;
    },
    setIsLoad(state, action) {
      state.isLoad = action.payload.isLoad;
    },
    setStatusArray(state, action) {
      state.statusArray = action.payload.statusArray;
    },
    setReleaseListToEmpty(state, action) {
      state.releaseDetails.list = action.payload.releaseDetails;
    },
    setReleaseCount(state, action) {
      state.releaseCount = action.payload.releaseCount;
    },
    resetRedirect(state, action) {
      state.redirectReleaseNote.success = false;
      state.redirectReleaseNote.releaseId = 0;
      state.redirectReleaseNote.level2Id = 0;
    },
    setRedirect(state, action) {
      state.redirectReleaseNote = action.payload;
    },
    setFilterType(state, action) {
      state.filterType = action.payload.filterType;
    },
    setJiraCredPopupDetails(state, action) {
      state.jiraCredentialDetails.closeJiraPopup =
        action.payload.closeJiraPopupAction;
    },
    resetJIraTicketList(state, action) {
      state.jiraTicketDetails.tableList = {};
    },
    resetJiraProject(state, action) {
      state.jiraProjectDetails.list = [];
    },
    resetJiraKeyDetails(state, action) {
      state.jiraKeyDetails.list = [];
    },
    setTemporaryReleaseNoteDetails(state, action) {
      state.temporaryReleaseNoteDetails =
        action.payload.temporaryReleaseNoteDetails;
    },
    setTagsList(state, action) {
      state.tagLists = action.payload.tagLists;
    },
    setJiraCredApiLoaded(state, action) {
      state.jiraCredentialDetails.hasApiLoaded = false;
    },
    setJiraDetailsApiLoaded(state, action) {
      state.jiraLoadedDetails.hasApiLoaded = false;
    },
    setJiraProjectApiLoaded(state, action) {
      state.jiraProjectDetails.hasApiLoaded = false;
    },
    copyToReleaseNoteAction(state, action) {
      state.copyToReleaseNote = action.payload.copyToReleaseNote;
      state.releaseDescriptionCount = action.payload.descriptionCount;
    },
    setSelectedTags(state, action) {
      state.releaseNote.details = action.payload.selectedTagList;
    },
    setColorPickerOpen(state, action) {
      state.open = action.payload.open;
    },
    setTemporaryTagsSelected(state, action) {
      state.tagsList = action.payload.tagsList;
    },
    setReleaseFeedbackList(state, action) {
      state.releaseNoteFeedback.list = action.payload;
    },
    setReleaseNoteApiLoaded(state, action) {
      state.releaseNote.hasApiLoaded = action.payload.hasApiLoaded;
    },
    setReleaseNoteFeedbackGridApiLoaded(state, action) {
      state.releaseNoteFeedbackGrid.hasApiLoaded = action.payload.hasApiLoaded;
    },
    setReleaseIdForClick(state, action) {
      state.clickedReleaseId = action.payload.clickedReleaseId;
    },
    setCollaboratorPopUp(state, action) {
      state.collaboratorDetails.openCollaboratorPopUp =
        action.payload.openCollaboratorPopUp;
    },
    setCallReleaseListApi(state, action) {
      state.callReleaseListApi = action.payload.callReleaseListApi;
    },
    setSortForReleaseTable(state, action) {
      state.sort = action.payload.sort
    },
    setReleaseIdForScheduleReleaseClick(state, action) {
      state.clickedReleaseIdForSchedule = action.payload.clickedReleaseIdForSchedule;
    },
    setReleaseNotSaveData(state, action) {
      state.releaseData = action.payload.releaseData
    },
    setErrorsForReleaseSave(state, action) {
      state.releaseNoteErrors = action.payload.releaseNoteErrors
    },
    setCallGlobalFeedbackApi(state, action) {
      state.callGlobalFeedbackApiFromRelease = action.payload.callGlobalFeedbackApiFromRelease;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(releaseNoteList.pending, (state, { payload }) => {
      state.isLoad = true;
      state.releaseCount = 0;
      state.releaseDetails.releaseFetchCompleted = false
    });
    builder.addCase(releaseNoteList.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.releaseDetails.list = payload?.response?.releaseList;
        state.releaseDetails.currentMonthYear =
          payload?.response?.currentMonthYear;
        state.releaseCount = payload?.response?.releaseList.length;
        state.noDataWithSearch = payload?.response?.noDataWithSearch;
        state.releaseDetails.releaseFetchCompleted = true
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.releaseDetails.list = [];
        state.releaseDetails.currentMonthYear = "";
        state.releaseCount = 0;
        state.noDataWithSearch = false;
        toasterError(payload?.message);
      } else {
        if (payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION) {
          if (payload?.response?.level2Deleted) {
            state.releaseDetails.list = [];
            state.releaseDetails.currentMonthYear = "";
            state.releaseCount = 0;
            state.noDataWithSearch = false;
          }
        }
      }
    });
    builder.addCase(releaseNoteList.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.releaseCount = 0;
      state.releaseDetails.releaseFetchCompleted = false

    });
    builder.addCase(getStatusList.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callReleaseListApiAfterStatusListApi = false;
    });
    builder.addCase(getStatusList.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.statusList = payload?.response?.statuses;
        state.callReleaseListApiAfterStatusListApi = true;
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.statusList = [];
        toasterError(payload?.message);
      }
    });
    builder.addCase(getStatusList.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callReleaseListApiAfterStatusListApi = false;
    });
    // builder.addCase(getBarChartDetails.pending, (state, { payload }) => {
    //   state.isLoad = true;
    // });
    // builder.addCase(getBarChartDetails.fulfilled, (state, { payload }) => {
    //   state.isLoad = false;
    //   if (!payload?.hasError && payload?.errorCode === -1) {
    //     state.barchartDetails = payload?.response;
    //   } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
    //     && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
    //     ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
    //   ) {
    //     state.barchartDetails = {};
    //     toasterError(payload?.message);
    //   }
    // });
    // builder.addCase(getBarChartDetails.rejected, (state, { payload }) => {
    //   state.isLoad = false;
    // });
    // builder.addCase(getSubscribers.pending, (state, { payload }) => {
    //   state.isLoad = true;
    // });
    // builder.addCase(getSubscribers.fulfilled, (state, { payload }) => {
    //   state.isLoad = false;
    //   if (!payload?.hasError && payload?.errorCode === -1) {
    //     state.subscriberDetails = payload?.response;
    //   } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
    //     && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
    //     ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
    //   ) {
    //     state.subscriberDetails = {};
    //     toasterError(payload?.message);
    //   }
    // });
    // builder.addCase(getSubscribers.rejected, (state, { payload }) => {
    //   state.isLoad = false;
    // });
    builder.addCase(releaseNoteDetails.pending, (state, { payload }) => {
      state.isLoad = true;
      state.releaseNote.hasApiLoaded = false;
    });
    builder.addCase(releaseNoteDetails.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.sort = {};
        let selectedTag = [];
        let releaseTags;
        if (payload?.response?.releaseList == null) {
          releaseTags = [];
        } else {
          releaseTags = payload?.response?.releaseList?.releaseTags;
        }
        if (payload?.response?.releaseList?.releaseShortName != "") {
          state.releaseNoteErrors.releaseShortNameError = false;
        } else {
          state.releaseNoteErrors.releaseShortNameError = true;
        }
        state.redirectReleaseNote = {
          ...state.redirectReleaseNote,
          level2Id: payload?.response?.releaseList?.releaseId,
          releaseId: payload?.response?.releaseList?.releaseId,
        }
        state.releaseNote = {
          ...state.releaseNote,
          details: {
            ...payload?.response?.releaseList,
            releaseTags: releaseTags,
            choiceId: payload?.response?.preferences?.releaseChoiceId,
            latestVersion: payload?.response?.preferences?.useLatest,
          },
          hasApiLoaded: true,
        };
        payload?.response?.releaseList?.releaseTags.forEach((row) => {
          selectedTag.push(row?.tag?.tagId);
        });
        state.releaseData = {
          releaseShortName: payload?.response?.releaseList?.releaseShortName,
          releaseLongName: payload?.response?.releaseList?.releaseLongName,
          releaseImageName: payload?.response?.releaseList?.releaseImageName,
          releaseTags: selectedTag,
          releaseNote: payload?.response?.releaseList?.releaseNote,
          releaseParentId: payload?.response?.releaseList?.releaseParentId,
          customerId: payload?.response?.releaseList?.customerId,
          level2Id: payload?.response?.releaseList?.level2Id,
          releaseId: payload?.response?.releaseList?.releaseId,
        }
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.releaseNote = {
          details: {},
          hasApiLoaded: true,
        };
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.releaseNote = {
          details: {},
          hasApiLoaded: true,
        };
        toasterError(payload?.message);
      }
    });
    builder.addCase(releaseNoteDetails.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.releaseNote.hasApiLoaded = false;
    });

    builder.addCase(updateReleaseNote.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(updateReleaseNote.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        /* Release id always will be change when edit */
        if (payload?.response?.type === PROJECT_CONST.SAVE) {
          toasterSuccess(PROJECT_CONST.RELEASE_NOTE_UPDATED_MSG);
        } else {
          toasterSuccess(PROJECT_CONST.PUBLISH_MSG);
        }
        state.redirectReleaseNote = {
          success: true,
          releaseId: payload?.response?.releaseList?.releaseId ? payload?.response?.releaseList?.releaseId : payload?.response?.release?.releaseId,
          level2Id: payload?.response?.releaseList?.level2Id ? payload?.response?.releaseList?.level2Id : payload?.response?.release?.level2Id,
        };
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.redirectReleaseNote = {
          success: false,
          releaseId: 0,
          level2Id: 0,
        };
      } else if (payload?.hasError && payload?.errorCode === 1028) {
        // toasterError(payload?.message);
        state.redirectReleaseNote = {
          success: false,
          releaseId: 0,
          level2Id: 0,
        };
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.redirectReleaseNote = {
          success: false,
          releaseId: 0,
          level2Id: 0,
        };
        toasterError(payload?.message);
      }
    });
    builder.addCase(updateReleaseNote.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(getReleaseHistory.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getReleaseHistory.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.historyDetails.list = payload?.response?.release;
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.historyDetails.list = [];
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.historyDetails.list = [];
        toasterError(payload?.message);
      }
    });
    builder.addCase(getReleaseHistory.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(releaseNoteCreate.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(releaseNoteCreate.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.sort = {};
        localStorage.setItem("releaseParentId", payload?.response?.releaseList?.releaseParentId);
        state.redirectReleaseNote = {
          success: true,
          releaseId: payload?.response?.releaseList?.releaseId,
          level2Id: payload?.response?.releaseList?.level2Id,
          // fromPreferences: false
        };
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.redirectReleaseNote = {};
        toasterError(payload?.message);
      }
    });
    builder.addCase(releaseNoteCreate.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(deleteReleaseNote.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callReleaseListApi = false;
    });
    builder.addCase(deleteReleaseNote.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        const index = state.releaseDetails.list.findIndex(
          (x) => Number(x.releaseId) === Number(payload?.response?.releaseId)
        );
        if (index > -1) {
          state.releaseDetails.list.splice(index, 1);
        }

        state.redirectReleaseNote = {
          success: true,
          releaseId: 0,
          level2Id: 0,
        };
        state.callReleaseListApi = true;
        toasterSuccess(PROJECT_CONST.RELEASE_DELETE_MSG);
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.redirectReleaseNote = {
          success: false,
          releaseId: 0,
          level2Id: 0,
        };
        state.releaseDetails.list = [];
      } else if (payload?.hasError && payload?.errorCode === 1028) {
        state.redirectReleaseNote = {
          success: false,
          releaseId: 0,
          level2Id: 0,
        };
        state.releaseDetails.list = [];
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.redirectReleaseNote = {
          success: false,
          releaseId: 0,
          level2Id: 0,
        };
        toasterError(payload?.message);
      }
    });
    builder.addCase(deleteReleaseNote.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callReleaseListApi = false;
    });
    builder.addCase(releaseNotePreview.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(releaseNotePreview.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.releaseNotePreview = payload?.response?.preview;
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.releaseNotePreview = "";
        toasterError(payload?.message);
      }
    });
    builder.addCase(releaseNotePreview.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(uploadFile.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(uploadFile.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.releaseData = {
          ...state.releaseData,
          releaseImageName: payload?.response?.releaseList?.releaseImageName
        }
        toasterSuccess(payload?.message)
      } else if (payload?.hasError && payload?.errorCode === 1028) {
        toasterError(payload?.message);
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(uploadFile.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(deleteUploadedFile.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(deleteUploadedFile.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.releaseData = {
          ...state.releaseData,
          releaseImageName: payload?.response?.releaseList?.releaseImageName
        }
        toasterSuccess(payload?.message);
      } else if (payload?.hasError && payload?.errorCode === 1028) {
        toasterError(payload?.message);
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(deleteUploadedFile.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(getScheduleList.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getScheduleList.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.scheduleDetails.list = payload?.response?.schedules;
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.scheduleDetails.list = [];
        toasterError(payload?.message);
      }
    });
    builder.addCase(getScheduleList.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(getJiraCredentials.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getJiraCredentials.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.jiraCredentialDetails = {
          ...state.jiraCredentialDetails,
          list: payload?.response?.jiraCredentials,
          hasApiLoaded: true,
        };
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.jiraCredentialDetails = {}
        toasterError(payload?.message);
      }
    });
    builder.addCase(getJiraCredentials.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(getJiraKeys.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getJiraKeys.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.jiraKeyDetails.list = payload?.response?.issueTypes;
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.jiraKeyDetails.list = [];
        toasterError(payload?.message);
      }
    });
    builder.addCase(getJiraKeys.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(getProjects.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getProjects.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.jiraProjectDetails = {
          ...state.jiraProjectDetails,
          list: payload?.response?.jiraProjects,
          hasApiLoaded: true,
        };
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.jiraProjectDetails = {};
        toasterError(payload?.message);
      }
    });
    builder.addCase(getProjects.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(getTicketData.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getTicketData.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.jiraTicketDetails.tableList = payload?.response?.jiraProjects
          ?.tableData
          ? payload?.response?.jiraProjects?.tableData
          : {};
        state.jiraTicketDetails.url = payload?.response?.jiraProjects?.url
          ? payload?.response?.jiraProjects?.url
          : "";
        state.jiraTicketDetails.issueName = payload?.response?.jiraProjects
          ?.issueName
          ? payload?.response?.jiraProjects?.issueName
          : [];
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.jiraTicketDetails.tableList = [];
        state.jiraTicketDetails.url = "";
        state.jiraTicketDetails.issueName = "";
        toasterError(payload?.message);
      } else {
        if (
          !payload?.hasError &&
          payload?.errorCode === ERROR_CODE_CONST.JIRA_FAILED_ERROR
        ) {
          state.jiraTicketDetails.issueName = payload?.response?.jiraProjects
            ?.issueName
            ? payload?.response?.jiraProjects?.issueName
            : [];
        }
      }
    });
    builder.addCase(getTicketData.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(saveJiraCred.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(saveJiraCred.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        let jiraList = state.jiraCredentialDetails.list
          ? state.jiraCredentialDetails.list
          : [];
        state.jiraCredentialDetails.list = [...jiraList, payload?.response];
        state.jiraCredentialDetails.closeJiraPopup = {
          ...state.jiraCredentialDetails.closeJiraPopup,
          hasClose: true,
          credentialId: 0,
        };
        state.jiraCredentialDetails.newJira = payload?.response;
        toasterSuccess(PROJECT_CONST.JIRA_CRED_CREATED_MSG);
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.jiraCredentialDetails.list = [];
        state.jiraCredentialDetails.closeJiraPopup = {}
        toasterError(payload?.message);
      }
    });
    builder.addCase(saveJiraCred.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(updateJiraCred.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(updateJiraCred.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        const index = state.jiraCredentialDetails.list.findIndex(
          (x) => Number(x.id) === Number(payload?.response?.id)
        );
        if (index > -1) {
          state.jiraCredentialDetails.list[index] = payload?.response;
        }
        state.jiraCredentialDetails.closeJiraPopup = {
          ...state.jiraCredentialDetails.closeJiraPopup,
          hasClose: true,
          credentialId: payload?.response?.id,
        };
        toasterSuccess(PROJECT_CONST.JIRA_CRED_UPDATED_MSG);
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.jiraCredentialDetails.list = [];
        state.jiraCredentialDetails.closeJiraPopup = {};
        toasterError(payload?.message);
      }
    });
    builder.addCase(updateJiraCred.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(getJiraLoadedDetails.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getJiraLoadedDetails.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.jiraLoadedDetails = {
          details: payload?.response,
          hasApiLoaded: true,
        };
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.jiraLoadedDetails = {};
        toasterError(payload?.message);
      }
    });
    builder.addCase(getJiraLoadedDetails.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(getJiraDetails.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(getJiraDetails.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getJiraDetails.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.jiraEditDetails = {
          details: payload?.response,
        };
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.jiraEditDetails = {};
        toasterError(payload?.message);
      }
    });
    builder.addCase(
      releaseNoteFeedbackGridDetails.pending,
      (state, { payload }) => {
        state.isLoad = true;
      }
    );
    builder.addCase(
      releaseNoteFeedbackGridDetails.fulfilled,
      (state, { payload }) => {
        state.isLoad = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          state.releaseNoteFeedbackGrid = {
            list: payload?.response?.feedbackGrid
              ? payload?.response?.feedbackGrid
              : [],
            hasApiLoaded: true,
          };
        } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
          && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
          ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
        ) {
          state.releaseNoteFeedbackGrid = {};
          toasterError(payload?.message);
        }
      }
    );
    builder.addCase(
      releaseNoteFeedbackGridDetails.rejected,
      (state, { payload }) => {
        state.isLoad = false;
      }
    );
    builder.addCase(releaseNoteFeedbackList.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(releaseNoteFeedbackList.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.releaseNoteFeedback = {
          list: payload?.response?.feedbackData
            ? payload?.response?.feedbackData
            : [],
          hasApiLoaded: true,
        };
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.releaseNoteFeedback = {};
        toasterError(payload?.message);
      }
    });
    builder.addCase(releaseNoteFeedbackList.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(createJiraTicketForAnRelease.pending, (state, { payload }) => {
      state.isLoad = true;
      state.closefeedBackJiraPopUp = false;
      state.callGlobalFeedbackApiFromRelease = false;
    });
    builder.addCase(createJiraTicketForAnRelease.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.closefeedBackJiraPopUp = true
        state.callGlobalFeedbackApiFromRelease = true;
        toasterSuccess(PROJECT_CONST.JIRA_TICKET_CREATION);
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(createJiraTicketForAnRelease.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.closefeedBackJiraPopUp = false
      state.callGlobalFeedbackApiFromRelease = false;
    });

    builder.addCase(getCollaborators.pending, (state, { payload }) => {
      state.isLoad = true;
      state.openCollaboratorPopUp = false;
    });
    builder.addCase(getCollaborators.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.collaboratorDetails = {
          list: payload?.response?.collaborators
            ? payload?.response?.collaborators
            : [],
          hasApiLoaded: true,
          openCollaboratorPopUp: true,
        };
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.collaboratorDetails = {
          list: [],
          hasApiLoaded: false,
          openCollaboratorPopUp: false,
        };
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.collaboratorDetails = {
          list: [],
          hasApiLoaded: false,
          openCollaboratorPopUp: false,
        };
        toasterError(payload?.message);
      }
    });
    builder.addCase(getCollaborators.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
  },
});

export const releaseNoteActions = ReleaseNoteSlice.actions;

export default ReleaseNoteSlice;
