import React from "react";
import "./Success.css";
import { STORE_CONST } from "../../utils/constants/store";
import { useSelector } from "react-redux";
import { greenTick } from "../../utils/imports/images";

function Success({ responseAction, msg }) {
  
  return (
    <React.Fragment>
      <div className="popup-overlay"></div>
      <div className="popup-min success-popup">
        <div className="popup-min-top">
          <img src={greenTick}/>
          <h2>{msg ? msg : ""}</h2>
        </div>
        <div className="popup-min-bottom flex success-close">
          <div className="popup-min-bottom-right flex">
            <button
              onClick={(event) => responseAction({ isSubmit: true })}
              className="blue-button confirmation-icon-padding save"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Success;
