import { logo, notificationImg } from "../../utils/imports/images";
import { useDispatch, useSelector } from "react-redux";
import { STORE_CONST } from "../../utils/constants/store";
import { SidebarActions } from "../../store/SidebarSlice";
import { useEffect, useState } from "react";
import { sendApplicationFeedback } from "../../store/api/SidebarApi";
import { allStorageData, openNewTab } from "../../utils/helper/commonHelper";
import FeedBack from "../common/FeedBack";
import { PROJECT_CONST } from "../../utils/constants/projectConst";
import Success from "../common/Success";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../common/LoadingSpinner";

function LibraryHeader({ handleSubscribe }) {
  const dispatch = useDispatch();
  const [feedbackSubmitOpen, setFeedbackSubmitOpen] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [dns, setDnsName] = useState("");
  const { key, dnsName } = useParams();
  const applicationFeedbackMsg = useSelector(
    (state) => state[STORE_CONST.SIDEBAR_NAMESPACE]?.applicationFeedbackMsg
  );
  const customerAttributes = useSelector(
    (state) =>
      state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedbackLibraryDetails
        ?.customerAttributes
  );
  const level1Data = useSelector(
    (state) =>
      state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedbackLibraryDetails?.level1Data
  );
  const subscribeEnable = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.subscribeEnable
  );
  const openWindowCapturePopUp = useSelector(
    (state) => state[STORE_CONST.SIDEBAR_NAMESPACE]?.triggerCapturePopUp
  );
  const closeDataCapturePopUp = useSelector(
    (state) => state[STORE_CONST.SIDEBAR_NAMESPACE]?.closeDataCapturePopUp
  );
  const isLoad = useSelector(
    (state) => state[STORE_CONST.SIDEBAR_NAMESPACE]?.isLoad
  );
  const closeApplicationFeedbackSuccessMsg = useSelector(
    (state) =>
      state[STORE_CONST.SIDEBAR_NAMESPACE]?.closeApplicationFeedbackSuccessMsg
  );

  useEffect(() => {
    if (openWindowCapturePopUp != "") {
      setFeedbackSubmitOpen(true);
    }
  }, [openWindowCapturePopUp]);

  useEffect(() => {
    if (window.location.href.indexOf("lib") > -1) {
      setDnsName(dnsName);
    } else {
      const queryParameters = new URLSearchParams(window.location.search);
      setDnsName(queryParameters.get("dns-name"));
    }
  }, []);

  const onClick = (data) => {
    dispatch(
      SidebarActions.setCaptureWindow({
        captureWindow: false,
      })
    );
    dispatch(
      SidebarActions.setWindowCapturePopUp({
        triggerCapturePopUp: "",
      })
    );
    dispatch(
      sendApplicationFeedback({
        header: {
          dnsName: dns,
        },
        param: {
          info: allStorageData(PROJECT_CONST.EXTERNAL_PAGE),
          notes: data?.notes,
          fromRoute: "outSideApplication",
          base64Image: openWindowCapturePopUp.replace(
            /^data:image\/png;base64,/,
            ""
          ),
        },
      })
    );
  };

  const cancelPopUp = () => {
    setFeedbackSubmitOpen(false);
    dispatch(
      SidebarActions.setCaptureWindow({
        captureWindow: false,
      })
    );
    dispatch(
      SidebarActions.setWindowCapturePopUp({
        triggerCapturePopUp: "",
      })
    );
  };

  useEffect(() => {
    if (closeDataCapturePopUp) {
      setFeedbackSubmitOpen(false);
      setSuccessPopUp(true);
    }
  }, [closeDataCapturePopUp]);

  const close = () => {
    setSuccessPopUp(false);
  };

  useEffect(() => {
    if (closeApplicationFeedbackSuccessMsg) {
      setSuccessPopUp(false);
    }
  }, [closeApplicationFeedbackSuccessMsg]);

  return (
    <>
      <header>
        <div className="release-page-center release-page-header-wrapper">
          <div className="logo-wrapper flex">
            <img
              className={
                level1Data?.customer?.CustomerWebSite !== ""
                  ? "header-logo-image"
                  : ""
              }
              src={customerAttributes?.caLogoPath}
              onClick={() => {
                if (level1Data?.customer?.CustomerWebSite !== "") {
                  const newUrl =
                    level1Data?.customer?.CustomerWebSite.startsWith(
                      "http://"
                    ) ||
                    level1Data?.customer?.CustomerWebSite.startsWith("https://")
                      ? level1Data?.customer?.CustomerWebSite
                      : `http://${level1Data?.customer?.CustomerWebSite}`;
                  openNewTab(newUrl);
                }
              }}
            />
            <span className="company-name">
              {level1Data?.customer?.CustomerName}
            </span>
          </div>
          <div className="submit-feedback-icon-div">
            <span
              className="submit-feedback-icon"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  SidebarActions.setCaptureWindow({
                    captureWindow: true,
                  })
                );
              }}
            ></span>
            {subscribeEnable && (
              <button
                className="btn-common btn-white btn-with-img"
                onClick={handleSubscribe}
              >
                <img src={notificationImg} />
                Subscribe to Updates
              </button>
            )}
          </div>
        </div>
      </header>
      {feedbackSubmitOpen && (
        <FeedBack onClick={onClick} cancelPopUp={cancelPopUp} />
      )}
      {successPopUp && <Success responseAction={close} msg={applicationFeedbackMsg}></Success>}
      {isLoad && <LoadingSpinner />}
    </>
  );
}

export default LibraryHeader;
