import React, { useEffect, useLayoutEffect, useState } from "react";
import "../../components/releaseNote/ReleaseTag.css";
import { useDispatch, useSelector } from "react-redux";
import { getTagListForFeedBack } from "../../store/api/TagApi";
import { STORE_CONST } from "../../utils/constants/store";
import {
  getSubscriberDetailsWithId,
  saveSubscriberPreferenceData,
} from "../../store/api/FeedbackApi";
import { feedbackActions } from "../../store/FeedbackSlice";
import { tagActions } from "../../store/TagSlice";
import Scrollbars from "react-custom-scrollbars-2";
import { PROJECT_CONST } from "../../utils/constants/projectConst";
import { useForm } from "react-hook-form";
import { getLocalStorage, setLocalStorage } from "../../utils/helper/commonHelper";
import { closeGrey } from "../../utils/imports/images";

function SubscriptionPreferencesPopUp({
  level2Id,
  onClick,
  cancelPopUp,
  responseAction,
}) {
  const dispatch = useDispatch();
  const [tagList, setSelectedTags] = useState([]);
  const [subscriberAllFlag, setSubscriberAllFlag] = useState(false);
  const [preferenceHeight, setPreferenceHeight] = useState(240);
  const [filteredData, setFilteredData] = useState([]);

  const tagListWithOutAuthorization = useSelector(
    (state) => state[STORE_CONST.TAG_NAMESPACE]?.tagListWithOutAuthorization
  );
  const feedBackDetails = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedBackDetails
  );
  const getSubscriberTagList = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.subscriberTagDetails
  );

  const subscribeToAllFlag = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.SubscriberSendAll
  );
  const securityType = getLocalStorage("securityType")
  ? getLocalStorage("securityType")
  : "";
  const [publicAuthorization, setPublicAuthorization] = useState(false);
  const authorisationDetails = useSelector(
    (state) => state[STORE_CONST.DNS_SETTINGS_NAME_SPACE]?.authorisationDetails
  );
 
  const {
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    setError
  } = useForm({
    mode: "all",
    defaultValues: {},
  });

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const publicAuthorization = getLocalStorage("publicAuthorization")
      ? getLocalStorage("publicAuthorization")
      : "";
    setPublicAuthorization(publicAuthorization);
  }, []);

  const handleWindowResize = () => {
    if (window.innerHeight < 560) {
      setPreferenceHeight(180);
    } else {
      setPreferenceHeight(240);
    }
  };

  useLayoutEffect(() => {
    handleWindowResize();
  }, []);

  useEffect(() => {
    dispatch(
      getTagListForFeedBack(
        {
          header: {
            token: publicAuthorization
            ? publicAuthorization
            : authorisationDetails?.details?.token,
            dnsName: feedBackDetails?.release?.level2Data?.level2DNSRegistrationName,
            securityType: securityType? securityType: 0
          },
          param: {
            id:level2Id,
            isShowAll: true
          }
      }
      )
    );
  }, []);

  useEffect(() => {
    if (feedBackDetails?.subscriber?.SubscriberID != 0) {
      dispatch(
        getSubscriberDetailsWithId(
          {
            header: {
              dnsName: feedBackDetails?.release?.level2Data?.level2DNSRegistrationName,
              securityType: securityType? securityType: 0
            },
            param: {
              subscriberId: feedBackDetails?.subscriber?.SubscriberID
            }
        }
        )
      );
      dispatch(
        feedbackActions.setIsLoad({
          isLoad: true,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (getSubscriberTagList.length) {
      setSelectedTags(getSubscriberTagList);
    }
  }, [getSubscriberTagList]);

  useEffect(() => {
    setFilteredData(tagListWithOutAuthorization);
    if (getLocalStorage("searchDataForSubscriptionTagsList") != null) {
      filter(getLocalStorage("searchDataForSubscriptionTagsList"))
      setValue("search", getLocalStorage("searchDataForSubscriptionTagsList"))
    }
  }, [tagListWithOutAuthorization])

  const saveSubscriberPrefernceData = () => {
    responseAction({
      isSubmit: true,
      subscriptionDetails: {
        subscriberId: feedBackDetails?.subscriber?.SubscriberID,
        tagIds: tagList,
        sendAll: subscribeToAllFlag,
        level2Id: level2Id,
        email: feedBackDetails?.email
      },
    });
  };
  
  /**
   * function to search tag lists
   * @param {*} data 
   */
  const filter = (data) => {
    setFilteredData(tagListWithOutAuthorization.filter(tags => (data) ? tags.name.toLowerCase().includes(data.toLowerCase()) : tags))
  }

  return (
    <React.Fragment>
      <div className="popup-overlay"></div>
      <div className="popup-min add-tags-popup taglist-popup">  
        <div className="popup-min-top">
          <div className="popup-min-top-header flex">
            <h2>Subscription Preferences</h2>
            <span className="popup-close" onClick={cancelPopUp}><img width={16} src={closeGrey}/></span>
          </div>
          
          <div className="nav-buttons flex">
            <div className="searchWrapper tags-searchwrapper">
              <input id="input" type="search" {...register(`search`, {})} placeholder="Search Tags" onChange={(e) => {
                filter(e.target.value)
                setLocalStorage("searchDataForSubscriptionTagsList", e.target.value);
              }} />
            </div>
          </div>
          <div className="add-tags-list-wrapper tags-list-wrapper">
            <Scrollbars
              autoHeight={true}
              autoHeightMax={preferenceHeight + "px"}
              autoHide={true}
            >
              <ul className="add-tags-list">
                {filteredData && 
                (filteredData.length > 0)?
                  filteredData.map((tag, key) => (
                    <li
                      className={
                        tagList.includes(tag.tagId) ? "flex active" : "flex"
                      }
                      key={key}
                      onClick={() => {
                        let tagId = tag.tagId;
                        if (tagList.includes(tagId)) {
                          setSelectedTags((current) =>
                            current.filter((tag) => {
                              return tag !== tagId;
                            })
                          );
                        } else {
                          setSelectedTags((prev) => [...prev, tag.tagId]);
                        }
                      }}
                    >
                      <span
                        className="tag-color"
                        style={{ backgroundColor: tag.tagColour }}
                      ></span>
                      <span className="tag-text">{tag.name}</span>
                    </li>
                  )): 
                  <p className="no-data">No tags found!</p>
                  }
              </ul>
            </Scrollbars>
          </div>
          <div className="toggle-switch-button-wrapper flex">
            <div className="flex toggle-switch-item">
              <span>Subscribe to All Releases</span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={subscribeToAllFlag}
                  onChange={(e) => {
                    dispatch(
                      feedbackActions.setSubscriberSendAll({
                        subscriberSendAll: e.target.checked,
                      })
                    );
                  }}
                />
                <span className="slider round"></span>
              </label>
            </div>
            {/* <div className="flex toggle-switch-item"> 
                            <span>Subscribe For Tag</span>
                            <label className="switch">
                                <input type="checkbox" />
                                    <span className="slider round"></span>
                            </label>
                           
                        </div> */}
          </div>
        </div>
        <div className="popup-min-bottom flex  popup-min-bottom-tmargin">
          <div className="popup-min-bottom-right flex">
          <button
              className="close-button-no-icons popup-close close"
              onClick={cancelPopUp}
            >
              Close
            </button>
            <button
              className="blue-button save"
              onClick={saveSubscriberPrefernceData}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SubscriptionPreferencesPopUp;
