import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";
import {
  deleteTags,
  getTagList,
  getTagListForFeedBack,
  saveTag,
  updateTag,
} from "./api/TagApi";
import { PROJECT_CONST } from "../utils/constants/projectConst";
import { toasterError, toasterSuccess } from "../utils/helper/commonHelper";
import { ERROR_CODE_CONST } from "../utils/constants/errorCode";

const TagSlice = createSlice({
  name: STORE_CONST.TAG_NAMESPACE,
  initialState: {
    isLoad: false,
    isShowTagModal: false,
    tagDetails: {
      tagList: [],
      tagFormatArr: [],
      paginationArr: [],
      page: 0,
    },
    closeAddTag: false,
    closeEditTag: false,
    selectedTagList: [],
    applyTags: {
      hasApplied: false,
    },
    temporaryTagsList: [],
    callTagListApi: false,
    updatedTagDetails: {
      list: [],
    },
    deletedTagDetails: {},
    open: false,
    clickedTagId: 0,
    tagListWithOutAuthorization: [],
    subscriptionPreferencePopUpShowFromSubscription: false,
    showSubscriptionPreference: {
      isShow: false,
    },
    noData: false,
  },
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload.isLoad;
    },
    showHideModal(state, action) {
      state.isShowTagModal = action.payload.isShowTagModal;
    },
    setCloseAddTag(state, action) {
      state.closeAddTag = action.payload.closeAddTag;
    },
    setCloseEditTag(state, action) {
      state.closeEditTag = action.payload.closeEditTag;
    },
    setTagsApplied(state, action) {
      state.applyTags = {
        ...state.applyTags,
        ...action.payload,
      };
    },
    setTemporaryTagsList(state, action) {
      state.temporaryTagsList = action.payload.temporaryTagsList;
    },
    setCallTagList(state, action) {
      state.callTagListApi = action.payload.callTagListApi;
    },
    setUpdatedTags(state, action) {
      state.updatedTagDetails.list = action.payload.updatedTags;
    },
    setDeletedTags(state, action) {
      state.deletedTagDetails = action.payload.deletedTags;
    },
    setColorPickerOpen(state, action) {
      state.open = action.payload.open;
    },
    setTagIdClicked(state, action) {
      state.clickedTagId = action.payload.clickedTagId;
    },
    setSubscriptionPreferencePopUpShowFromSubscription(state, action) {
      state.showSubscriptionPreference = {
        ...state.showSubscriptionPreference,
        isShow: action.payload.isShow,
      };
    },
    setFilterForTagList(state, action) {
      state.tagDetails = {
        ...state.tagDetails,
        ...action?.payload,
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTagList.pending, (state, { payload }) => {
      state.isLoad = true;
      state.noData = false
    });
    builder.addCase(getTagList.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.tagDetails.tagList = payload?.response?.tagList;
        let tagFormatArr = [];
        payload?.response?.tagList.map((row) => {
          tagFormatArr.push({
            label: row?.name,
            value: row?.tagId,
            color: row?.tagColour
              ? row?.tagColour
              : PROJECT_CONST.DEFAULT_COLOR,
          });
        });
        state.tagDetails.tagFormatArr = tagFormatArr;
        state.tagDetails.paginationArr = payload?.response?.paginationArr
        state.noData = payload?.response?.NoData;
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.tagDetails.tagList = [];
        state.tagDetails.tagFormatArr = [];
        state.noData = false
      }else if(payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode === 
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
        ){
          state.tagDetails.tagList = [];
          state.tagDetails.tagFormatArr = [];
          toasterError(payload?.message);
          state.noData = false
        }
    });
    builder.addCase(getTagList.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.noData = false
    });
    builder.addCase(saveTag.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(saveTag.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.callTagListApi = true;
        state.updatedTagDetails.list = payload?.response?.tagDetails;
        let index;
        payload?.response?.tagDetails.map((item, i) => {
          index = state.tagDetails.tagList.findIndex(
            (obj) => obj.tagId === item.tagId
          );

          if (index !== -1) {
            state.tagDetails.tagList[index] = item;
          } else {
            state.tagDetails.tagList = [...state.tagDetails.tagList, item];
          }
        });
        if (index === -1) {
          toasterSuccess(PROJECT_CONST.TAG_CREATED_MSG);
        } else {
          toasterSuccess(PROJECT_CONST.TAG_UPDATED_MSG);
        }
        //show success toaster

        //remove modal
        state.isShowTagModal = false;
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.updatedTagDetails.list = [];
        state.tagDetails.tagList = [];
        state.callTagListApi = false;
      }else if(payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode === 
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
        ){
          state.updatedTagDetails.list = [];
        state.tagDetails.tagList = [];
        state.callTagListApi = false;
          toasterError(payload?.message);
        }
    });
    builder.addCase(saveTag.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(updateTag.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(updateTag.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        const index = state.tagDetails.tagList.findIndex(
          (x) => x.tagId === payload?.response?.tagDetails?.tagId
        );
        state.tagDetails.tagList[index] = payload?.response?.tagDetails;
        //show success toaster
        toasterSuccess(PROJECT_CONST.TAG_UPDATED_MSG);
        //remove modal
        state.isShowTagModal = false;
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.tagDetails.tagList = [];
        state.callTagListApi = false;
      }else if(payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode === 
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
        ){
          state.tagDetails.tagList = [];
          state.callTagListApi = false;
          toasterError(payload?.message);
        }
    });
    builder.addCase(updateTag.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(deleteTags.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(deleteTags.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.callTagListApi = true;
        state.deletedTagDetails = payload?.response?.tagDetails;
        const index = state.tagDetails.tagList.findIndex(
          (x) => x.tagId === payload?.response?.tagDetails?.tagId
        );
        if (index > -1) {
          state.tagDetails.tagList.splice(index, 1);
        }
        if(payload?.length > 1) {
          toasterSuccess(PROJECT_CONST.TAGS_DELETED_MSG);
        }else{
          toasterSuccess(PROJECT_CONST.TAG_DELETED_MSG);
        }
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.callTagListApi = false;
        state.deletedTagDetails = {};
        state.tagDetails.tagList = [];
      } else if(payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode === 
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
        ){
          state.callTagListApi = false;
          state.deletedTagDetails = {};
          state.tagDetails.tagList = [];
          toasterError(payload?.message);
        }
    });
    builder.addCase(deleteTags.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(getTagListForFeedBack.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getTagListForFeedBack.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.tagListWithOutAuthorization = payload?.response?.tagList;
      }else if(payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode === 
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
        ){
          state.tagListWithOutAuthorization = []
          toasterError(payload?.message);
        }
    });
    builder.addCase(getTagListForFeedBack.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
  },
});

export const tagActions = TagSlice.actions;

export default TagSlice;
