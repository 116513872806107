export const ERROR_CODE_CONST = {
  VALIDATION_ERROR: 1008,
  JIRA_FAILED_ERROR: 1025,
  ARCHIVE_ERROR: 1031,
  LEVEL2_DELETION: 1027,
  LEVEL1_DELETION:1029,
  NO_LEVEL_2_EXIST_CODE:1040,
  RELEASE_DELETED: 1028,
  COMMON_ERROR_CODE: 1001,
  INVITATION_EMAIL_LINK_EXPIRE: 1013,
  INVITATION_EMAIL_SUCCESS: 1014,
  INVITATION_EMAIL_USER_DELETED: 1012,
  UUID_DECODE_ERROR: 1009
};
