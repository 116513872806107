import React from "react";
import { STORE_CONST } from "../../utils/constants/store";
import { useDispatch, useSelector } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import { Debounce } from "../../utils/helper/commonHelper";
import { PROJECT_CONST } from "../../utils/constants/projectConst";
import { feedbackActions } from "../../store/FeedbackSlice";
import { tagActions } from "../../store/TagSlice";

function Tags() {
  const dispatch = useDispatch();
  const feedBackDetails = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedBackDetails
  );
   return (
    <>
      <div className="library-left-container">
        <h3>Tags</h3>
        <div className="tag-cloud-listing-wrapper">
          <Scrollbars
            autoHeight={true}
            autoHeightMax={`calc(100vh - 240px)`}
            autoHide={true}
          >
            <ul className="tag-cloud-listing">
              {/* <li className="flex active">
              <span
                className="tag-color"
                style={{ backgroundColor: "#000000" }}
              ></span>
              <span className="tag-text">
                Negotiation <span className="count">(25)</span>
              </span>
            </li> */}
              {feedBackDetails?.release?.releaseTags?.length !== 0 &&
                feedBackDetails?.release?.releaseTags?.map((tag, index) => (
                  <li
                    // onChange={debouncedOnChange}
                    // onClick={() => onClickTag(tag)}
                    // key={tag?.tagId}
                    // className={
                    //   "flex col-word-break " +
                    //   (tagIds.includes(tag?.tagId) ? "active" : "")
                    // }
                    style={{ wordBreak: "break-word" }}
                    key={tag?.tag?.tagId}
                  >
                    <span
                      className="tag-color"
                      style={{ backgroundColor: tag?.tag?.tagColour }}
                    ></span>
                    <span className="tag-text">{tag?.tag?.name}</span>
                  </li>
                ))}
              {feedBackDetails?.release?.releaseTags?.length === 0 && <li>No Data Found</li>}
            </ul>
          </Scrollbars>
          <button className="white-button notification" onClick={() => {
            if(feedBackDetails?.subscriber?.SubscriberIsActive) {
              console.log('dfdf');
              dispatch(tagActions.setSubscriptionPreferencePopUpShowFromSubscription({
                isShow: true
              }))
            }
          }}><span>Subscription Preferences</span></button>
          {/* <div className="form-group form-checkbox-group release-library-checkbox-wrapper">
            <label className="form-checkbox">
              <input type="checkbox" name="check_remember" />
              <span className="label-text">
                Only show releases with my feedback
              </span>
              <span className="checkmark"></span>
            </label>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Tags;
