import { configureStore } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";

import CommonSlice from "./CommonSlice";
import SidebarSlice from "./SidebarSlice";
import ReleaseNoteSlice from "./ReleaseNoteSlice";
import TagSlice from "./TagSlice";
import DlSlice from "./DlSlice";
import FeedbackSlice from "./FeedbackSlice";
import AutorizationSlice from "./AutorizationSlice";
import PreferenceSlice from "./PreferenceSlice";
import SettingsSlice from "./SettingsSlice";
import DnsSettingsSlice from "./DnsSettingsSlice";
import FileReadSlice from "./FileReadSlice";
import SubscribersSlice from "./SubscribersSlice";

const store = configureStore({
  reducer: {
    [STORE_CONST.SIDEBAR_NAMESPACE]: SidebarSlice.reducer,
    [STORE_CONST.COMMON_NAMESPACE]: CommonSlice.reducer,
    [STORE_CONST.RELEASENOTE_NAMESPACE]: ReleaseNoteSlice.reducer,
    [STORE_CONST.TAG_NAMESPACE]: TagSlice.reducer,
    [STORE_CONST.DL_NAMESPACE]: DlSlice.reducer,
    [STORE_CONST.FEEDBACK_NAMESPACE]: FeedbackSlice.reducer,
    [STORE_CONST.COMMON_NAMESPACE]: CommonSlice.reducer,
    [STORE_CONST.AUTORIZATION_NAMESPACE]: AutorizationSlice.reducer,
    [STORE_CONST.PREFERENCE_NAME_SPACE]: PreferenceSlice.reducer,
    [STORE_CONST.SETTINGS_NAME_SPACE]: SettingsSlice.reducer,
    [STORE_CONST.DNS_SETTINGS_NAME_SPACE]: DnsSettingsSlice.reducer,
    [STORE_CONST.FILE_READING]: FileReadSlice.reducer,
    [STORE_CONST.GLOBAL_SUBSCRIBERS]: SubscribersSlice.reducer,
  },
});

export default store;
