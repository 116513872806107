import React, { useEffect, useState } from "react";
import "./Library.css";
import "../../components/dashboard/ReleaseTable.css";
import ReleaseList from "../../components/library/ReleaseList";
import { useDispatch, useSelector } from "react-redux";
import { getFeedBackDetails, getFeedBackDetailsWithReleaseId, getFeedbackRelease } from "../../store/api/FeedbackApi";

import ReleaseDate from "../../components/library/ReleaseDate";
import { PROJECT_CONST } from "../../utils/constants/projectConst";
import { STORE_CONST } from "../../utils/constants/store";
import FeedBackSubmit from "../../components/feedback/FeedBackSubmit";
import Tags from "../../components/feedback/Tag";
import { feedbackActions } from "../../store/FeedbackSlice";
import { checkSecurityType, pageAuthorization } from "../../store/api/DnsSettingsApi";
import Unauthorization from "../unauthorization/Unauthorization";
import { getLocalStorage, setLocalStorage } from "../../utils/helper/commonHelper";
import { useNavigate } from "react-router-dom";
import customHistory from "../../utils/lib/history";

function FeedBack() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const [pageNotFound, setPageNotFound] = useState(false);
  const [authorizationFailed, setAuthorizationFailed] = useState(false);

  const jwtToken = queryParameters.get("jwt-token")
    ? decodeURIComponent(queryParameters.get("jwt-token"))
    : '';
  const dnsName = queryParameters.get("dns-name")
    ? decodeURIComponent(queryParameters.get("dns-name"))
    : '';
  const encryptedReleaseId = queryParameters.get("encryptedId")
    ? queryParameters.get("encryptedId")
    : null;
  const showFeedbackPage = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.showFeedbackPage
  );

  const token = queryParameters.get("token")
    ? decodeURIComponent(queryParameters.get("token"))
    : null;
  const callSecurityTypeApi = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.callSecurityTypeApi
  );
  const feedBackDetails = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedBackDetails
  );
  const securityTypeDetails = useSelector(
    (state) => state[STORE_CONST.DNS_SETTINGS_NAME_SPACE]?.securityTypeDetails
  );
  const authorisationDetails = useSelector(
    (state) => state[STORE_CONST.DNS_SETTINGS_NAME_SPACE]?.authorisationDetails
  );
  const redirect = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.redirect
  );
  const level2ArchiveStatus = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.level2ArchiveStatus
  );

  useEffect(() => {
    const publicAuthorization = getLocalStorage("publicAuthorization")
      ? getLocalStorage("publicAuthorization")
      : "";
    if (publicAuthorization) {
      if (encryptedReleaseId || (jwtToken && dnsName && encryptedReleaseId)) {
        getReleaseWithId();
      } else if (!level2ArchiveStatus) {
        getFeedBackWithReleaseKey();
        dispatch(
          feedbackActions.setShowFeedBackPage({
            showFeedbackPage: true,
          })
        );
      }
    } else {
      if (authorisationDetails && authorisationDetails?.hasApiLoaded) {
        if (
          (authorisationDetails?.details?.authenticationType ==
            PROJECT_CONST.SECURITY_TYPE_JWT &&
            !authorisationDetails?.details?.token) ||
          authorisationDetails?.details?.token
        ) {
          if (authorisationDetails?.details?.hasAuthorisation) {
            setLocalStorage(
              "publicAuthorization",
              authorisationDetails?.details?.token
            );
            dispatch(
              feedbackActions.setShowFeedBackPage({
                showFeedbackPage: true,
              })
            );
            if (encryptedReleaseId || (jwtToken && dnsName && encryptedReleaseId)) {
              getReleaseWithId();
            } else if (!level2ArchiveStatus) {
              getFeedBackWithReleaseKey();
            }
          } else {
            setAuthorizationFailed(true);
            dispatch(
              feedbackActions.setShowFeedBackPage({
                showFeedbackPage: false,
              })
            );
          }
        } else {
          setPageNotFound(true);
        }
      }
    }
  }, [authorisationDetails]);

  useEffect(() => {
    if (feedBackDetails?.pStatus &&
      securityTypeDetails?.details?.sType != PROJECT_CONST.SECURITY_TYPE_JWT) {
      setPageNotFound(true);
      dispatch(
        feedbackActions.setShowFeedBackPage({
          showFeedbackPage: false,
        })
      );
    } else {
      setPageNotFound(false);
      dispatch(
        feedbackActions.setShowFeedBackPage({
          showFeedbackPage: true,
        })
      );
    }
  }, [feedBackDetails])

  useEffect(() => {
    if (securityTypeDetails?.hasApiLoaded) {
      if (securityTypeDetails?.details?.sType == PROJECT_CONST.SECURITY_TYPE_JWT
      ) {
        getPageAuthorisation();
      } else {
        if (encryptedReleaseId || (jwtToken && dnsName && encryptedReleaseId)) {
          getReleaseWithId();
        } else if (!level2ArchiveStatus) {
          getFeedBackWithReleaseKey();
        }
      }
      setLocalStorage(
        "securityType",
        securityTypeDetails?.details?.sType
      );
    }
  }, [securityTypeDetails]);

  /**
 * function to redirect with token
 */
  useEffect(() => {
    if (redirect != "") {
      if (encryptedReleaseId || (jwtToken && dnsName && encryptedReleaseId)) {
        getReleaseWithId(true);
      } else if (!level2ArchiveStatus) {
        customHistory.replace(redirect);
        getFeedBackWithReleaseKey(true);
      }
    }
  }, [redirect]);

  const getPageAuthorisation = () => {
    dispatch(pageAuthorization({ param: { dnsName: dnsName }, header: jwtToken ? "Bearer " + jwtToken : "" }));
  };

  useEffect(() => {
    // dispatch(checkSecurityType({ dnsName: dnsName }));
    dispatch(checkSecurityType({ param:{dnsName: dnsName}, header: {
      dnsName: dnsName,
    }, }));
  }, []);

  const getReleaseWithId = (afterSubmit = false) => {
    const securityType = getLocalStorage("securityType")
      ? getLocalStorage("securityType")
      : "";
    const publicAuthorization = getLocalStorage("publicAuthorization")
      ? getLocalStorage("publicAuthorization")
      : "";
    let param = {};
    if (afterSubmit) {
      param = {
        email: feedBackDetails?.email,
        encryptedString: encryptedReleaseId,
        securityType: securityTypeDetails?.details?.sType ? securityTypeDetails?.details?.sType : securityType
      }
    } else {
      param = {
        encryptedString: encryptedReleaseId,
        securityType: securityTypeDetails?.details?.sType ? securityTypeDetails?.details?.sType : securityType
      }
    }
    dispatch(
      getFeedBackDetailsWithReleaseId({
        header: {
          token: publicAuthorization
            ? publicAuthorization
            : '',
          dnsName: dnsName
        },
        param: param
      })
    );
  }
  const getFeedBackWithReleaseKey = (afterSubmit = false) => {
    if (token != null) {
      const publicAuthorization = getLocalStorage("publicAuthorization")
        ? getLocalStorage("publicAuthorization")
        : "";
      let param = {};
      if (afterSubmit) {
        param = {
          email: feedBackDetails?.email,
          releaseParentId: feedBackDetails.release.releaseParentId,
        }
      } else {
        param = {
          token: token,
          email: feedBackDetails?.email,
          releaseParentId: feedBackDetails?.release?.releaseParentId,
        }
      }
      dispatch(
        getFeedBackDetails({
          header: {
            token: publicAuthorization
              ? publicAuthorization
              : '',
            dnsName: dnsName
          },
          param: param
        })
      );
    }
  }


  return (
    <>
      {showFeedbackPage &&
        <div className="feedback-outer-wrapper ">
          <Tags />
          <div className="right-main-container">
            <FeedBackSubmit />
          </div>
        </div>
      }
      {pageNotFound && <Unauthorization />}
      {authorizationFailed && (
        <Unauthorization message="Invalid Token" errorCode="401" />
      )}
    </>
  );
}

export default FeedBack;
