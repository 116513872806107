import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";

const FileReadSlice = createSlice({
  name: STORE_CONST.FILE_READING,
  initialState: {
    isLoad: false,
    excelColumns: [],
    excelSheetName: [],
    selectedExcelSheetName: '',
    tempFileName: '',
    tempFileType: '',
    sheetOpen: false,
    selectedOption: '',
    index: ''
  },
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload.isLoad;
    },
    setExcelColumns(state, action) {
      state.excelColumns = action.payload.excelColumns;
    },
    setExcelSheetName(state, action) {
      state.excelSheetName = action.payload.excelSheetName;
    },
    setSelectedExcelSheetName(state, action) {
      state.selectedExcelSheetName = action.payload.selectedExcelSheetName;
    },
    setTemporaryFileName(state, action) {
      state.tempFileName = action.payload.tempFileName;
    },
    setFileType(state, action) {
      state.tempFileType = action.payload.tempFileType;
    },
    setSheetOpen(state, action) {
      state.sheetOpen = action.payload.sheetOpen;
    },
    setSelectedOption(state, action) {
      state.selectedOption = action.payload.selectedOption;
    },
    setIndex(state, action) {
      state.index = action.payload.index;
    }
  },
  extraReducers: (builder) => {},
});

export const fileReadActions = FileReadSlice.actions;

export default FileReadSlice;
