import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../utils/constants/apiUrl";
import { STORE_CONST } from "../../utils/constants/store";
import customAxios from "../../utils/lib/customAxios";
// import { SidebarActions } from "../SidebarSlice";

const getLevelOneList = createAsyncThunk(
  `${STORE_CONST.SIDEBAR_NAMESPACE}/getLevelOneList`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.get(API_URL.LEVEL_ONE_LIST);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getLevelTwoList = createAsyncThunk(
  `${STORE_CONST.SIDEBAR_NAMESPACE}/getLevelTwoList`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.LEVEL_TWO_LIST, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const createLevelOne = createAsyncThunk(
  `${STORE_CONST.SIDEBAR_NAMESPACE}/createLevelOne`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.LEVEL_ONE_CREATE, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const createLevelTwo = createAsyncThunk(
  `${STORE_CONST.SIDEBAR_NAMESPACE}/createLevelTwo`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.LEVEL_TWO_CREATE, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const updateLevelOne = createAsyncThunk(
  `${STORE_CONST.SIDEBAR_NAMESPACE}/updateLevelOne`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.patch(API_URL.LEVEL_ONE_UPDATE, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const updateLevelTwo = createAsyncThunk(
  `${STORE_CONST.SIDEBAR_NAMESPACE}/updateLevelTwo`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.patch(API_URL.LEVEL_TWO_UPDATE, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const setLevel2Archive = createAsyncThunk(
  `${STORE_CONST.SIDEBAR_NAMESPACE}/setLevel2Archive`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.patch(
        API_URL.SET_LEVEL_TWO_ARCHIVE,
        arg
      );
      // return data;
      return {
        ...data,
        response: {
          ...data.response,
          level2Id: arg.level2Id,
          level1Id: arg.level1Id,
          isArchived: arg.isArchived,
        },
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const setLevel1Archive = createAsyncThunk(
  `${STORE_CONST.SIDEBAR_NAMESPACE}/setLevel1Archive`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.patch(
        API_URL.SET_LEVEL_ONE_ARCHIVE,
        arg
      );
      // return data;
      return {
        ...data,
        response: {
          ...data.response,
          level1Id: arg.level1Id,
          isArchived: arg?.isArchived,
        },
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const deleteLevel1 = createAsyncThunk(
  `${STORE_CONST.SIDEBAR_NAMESPACE}/deleteLevel1`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.DELETE_LEVEL_ONE, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const deleteLevel2 = createAsyncThunk(
  `${STORE_CONST.SIDEBAR_NAMESPACE}/deleteLevel2`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.DELETE_LEVEL_TWO, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getArchiveDetails = createAsyncThunk(
  `${STORE_CONST.SIDEBAR_NAMESPACE}/getArchiveDetails`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.GET_ARCHIVE_DETAILS, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const sendApplicationFeedback = createAsyncThunk(
  `${STORE_CONST.SIDEBAR_NAMESPACE}/sendApplicationFeedback`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      const { data } = await customAxios.post(API_URL.SEND_APPLICATION_FEEDBACK, arg.param,
        {headers: headers});
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);
export {
  sendApplicationFeedback,
  getLevelOneList,
  getLevelTwoList,
  createLevelOne,
  createLevelTwo,
  updateLevelOne,
  updateLevelTwo,
  setLevel2Archive,
  getArchiveDetails,
  setLevel1Archive,
  deleteLevel1,
  deleteLevel2,
};
