import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../utils/constants/apiUrl";
import { STORE_CONST } from "../../utils/constants/store";
import customAxios from "../../utils/lib/customAxios";

const saveDnsSetings = createAsyncThunk(
  `${STORE_CONST.DNS_SETTINGS_NAME_SPACE}/saveDnsSetings`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.SAVE_DNS_SETTINGS,
        arg.param
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const checkSecurityType = createAsyncThunk(
  `${STORE_CONST.DNS_SETTINGS_NAME_SPACE}/checkSecurityType`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      const { data } = await customAxios.post(API_URL.CHECK_SECURITY_TYPE, arg?.param, {
        headers: headers,
      });
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const pageAuthorization = createAsyncThunk(
  `${STORE_CONST.DNS_SETTINGS_NAME_SPACE}/pageAuthorization`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.PAGE_AUTHORISATION,
        arg?.param,
        arg?.header
          ? {
              headers: { domainAuthorization: arg?.header },
            }
          : {}
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export { saveDnsSetings, checkSecurityType, pageAuthorization };
