import React from "react";

function CustomToast({ message }) {
    return (
        <React.Fragment>
            <div>
                <div dangerouslySetInnerHTML={{ __html: message }} />
            </div>
        </React.Fragment>
    );
}

export default CustomToast;
