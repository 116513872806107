import React, { useEffect, useRef, useState, useMemo } from "react";
import "../../components/releaseNote/ReleaseTag.css";
import { useDispatch, useSelector } from "react-redux";
import { tagActions } from "../../store/TagSlice";
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { deleteTags, getTagList, saveTag } from "../../store/api/TagApi";
import { STORE_CONST } from "../../utils/constants/store";
import LoadingSpinner from "../common/LoadingSpinner";
import { releaseNoteActions } from "../../store/ReleaseNoteSlice";
import Confirmation from "../common/Confirmation";
import { SketchPicker } from 'react-color'
import { generateRandomColor, getLocalStorage } from "../../utils/helper/commonHelper";
import { isNotEmpty } from "../../utils/helper/commonHelper";
import { closeGrey, plusIcon, saveWhite } from "../../utils/imports/images";
import Scrollbars from "react-custom-scrollbars-2";

let uniqueId = 1;
function AddTags({ cancelPopUp }) {
    const outsideActionRef = useRef(null);
    const dispatch = useDispatch();
    const [randomColors, setRandomColors] = useState([]);
    const [actionPos, setActionPos] = useState({});
    const [index, setIndex] = useState();
    const [colorPickerOpen, setColorPickerOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedTagColors, setSelectedTagColors] = useState([]);
    const [temporaryArray, setTemporaryArray] = useState([]);
    const [cancelClicked, setCancelClicked] = useState(false);
    const [isShowConfirmation, setIsShowConfirmation] = useState(false);
    const [tagId, setTagId] = useState();
    const [indexWhenDeleteClick, setIndexWhenDeleteClick] = useState();
    const [includedColors, setIncludedColors] = useState(['#e8c15e', '#000000', '#e2375e', '#32aaaa']);
    const [elementId, setElementId] = useState();
    const isLoad = useSelector(
        (state) => state[STORE_CONST.TAG_NAMESPACE]?.isLoad
    );

    const open = useSelector(
        (state) => state[STORE_CONST.RELEASENOTE_NAMESPACE]?.open
    );
    const [color, setColors] = useState({});

    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        watch,
        setValue,
        setError
    } = useForm({
        mode: "all"
    });
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: "tagName"
    });

    /** function to show drop down */
    const showAction = (e, i) => {
        const elem = e.target;
        const rect = elem.getBoundingClientRect();
        setActionPos({ top: (rect.top - 10), left: (rect.left - 83) });
        setIndex(i);
        generateRandomColors();
        if (!includedColors.includes(fields[i].style.backgroundColor)) {
            setColors(fields[i].style.backgroundColor)
        }
    };

    /** function to create text fields */
    const appendText = () => {
        setActionPos({})
        setIndex()
        append({ uniqueId: uniqueId, text: "", style: { backgroundColor: '#e8c15e' } })
        uniqueId++
    };

    /** function for form submit */
    const onSubmit = (res) => {
        let apiData = [];
        let hasError = false;
        if (temporaryArray.length > 0) {
            temporaryArray.map((value, i) => {
                if (!isNotEmpty(value.text)) {
                    hasError = true
                    setError(`tagName[${i}].text`);
                    document.getElementById('tag_text_' + i).focus();
                    return;
                }
                if (value.text) {
                    apiData.push({
                        name: value.text,
                        tagColour: value.style.backgroundColor,
                        level2Id: Number(getLocalStorage("activeLevelTwoId"))
                    })
                }
            })
        }
        if (!hasError && !cancelClicked) {
            if (apiData.length > 0) {
                dispatch(tagActions.setIsLoad({
                    isLoad: true
                }))
                dispatch(saveTag({
                    data: apiData
                }))
                setTemporaryArray([])
                remove()
            }
        }
    }

    /** for setting when color picker selects */
    useEffect(() => {
        if (color.hasOwnProperty('hex') && color.hex != "") {
            let singleValue;
            singleValue = watch("tagName[" + index + "].text");

            if (!singleValue) {
                singleValue = fields[index].text
            }
            let value;
            value = { ...fields[index], text: singleValue, style: { backgroundColor: color.hex } }
            update(index, value)
            setValue(`tagName[${index}].text`, singleValue);

            const targetObject = fields.find((item, y) => y === index);
            if (targetObject) {
                fields[index] = {
                    ...fields[index],
                    style: {
                        backgroundColor: color.hex
                    }
                }
                setTemporaryArray(fields);
            }
        }
    }, [color])

    /** for setting color when drop down selects */
    const setColor = (color) => {
        if (color) {
            let singleValue;
            singleValue = watch(`tagName[${index}].text`);
            if (!singleValue) {
                singleValue = fields[index].text
            }
            let value;
            value = { ...fields[index], text: singleValue, style: { backgroundColor: color } }
            update(index, value)
            setValue(`tagName[${index}].text`, singleValue);
            /** ends */
            const targetObject = fields.find((items, y) => y === index);
            if (targetObject) {
                fields[index] = {
                    ...fields[index],
                    style: {
                        backgroundColor: color
                    }
                }
                setTemporaryArray(fields);
            }
            dispatch(releaseNoteActions.setColorPickerOpen({
                open: false
            }))
            setSelectedTagColors((prev) => [...prev, color])
        }
    }

    /** function to hide dropdown menu when click outside */
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                outsideActionRef.current &&
                !outsideActionRef.current.contains(event.target)
            ) {
                setActionPos({});
                dispatch(releaseNoteActions.setColorPickerOpen({
                    open: false
                }))
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [outsideActionRef]);

    useEffect(() => {
        if (open) {
            setSelectedColor()
        }
    }, [open])


    /** confirmation from delete pop up  */
    const confirmationesponseAction = (res) => {
        if (res.hasOwnProperty('isSubmit') && res.isSubmit && tagId != 0) {
            dispatch(
                deleteTags({
                    id: [tagId]
                })
            );
        } else {
            if (res.hasOwnProperty('isSubmit') && res.isSubmit) {
                remove(indexWhenDeleteClick);
                selectedTagColors.splice(indexWhenDeleteClick, 1)
                setSelectedTagColors(selectedTagColors)
                const index = temporaryArray.findIndex(obj => obj.id === elementId);
                if (index > -1) {
                    temporaryArray.splice(index, 1);
                    setTemporaryArray(temporaryArray)
                }
            }
            setIsShowConfirmation(false);
        }
    };

    /**
   * setting random colors
   */
    const generateRandomColors = () => {
        const newRandomColors = [];
        while (newRandomColors.length < 4) {
            const randomColor = generateRandomColor();
            if (!selectedTagColors.includes(randomColor) && !newRandomColors.includes(randomColor)) {
                newRandomColors.push(randomColor);
            }
        }
        setRandomColors(newRandomColors);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <>
            <div className="popup-overlay"></div>
            <div className="popup-min add-tags-popup">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="popup-min-top">
                        <div className="popup-min-top-header flex">
                            <h2>Add New Tag</h2>
                            <span className="popup-close" onClick={() => {
                                cancelPopUp()
                                setCancelClicked(true)
                            }}><img width={16} src={closeGrey}/></span>
                        </div>
                        <div className="edit-tags-list-wrapper">
                        <Scrollbars autoHeight={true} autoHeightMax={"420px"} autoHide={true} >
                            <ul className="edit-tags-list">
                                {fields.map((item, i) => (
                                    <li className="flex" key={i}>
                                        <div className={`tag-color-main ${index === i ? "clicked" : ""}`}>
                                            <span className="tag-color" style={item.style} onClick={(e) => {
                                                showAction(e, i)
                                                setSelectedColor(item.style.backgroundColor)
                                            }}></span>
                                        </div>
                                        <input
                                            type="text"
                                            {...register(`tagName[${i}].text`, {
                                                required: true
                                            })}
                                            control={control}
                                            id={`tag_text_` + i}
                                            className={(errors.tagName?.[i]?.text) ? 'invalid-error' : ''}
                                            defaultValue=""
                                            onKeyDown={handleKeyPress}
                                            onKeyUp={(event) => {
                                                const targetObject = fields.find((items, y) => item.uniqueId === items.uniqueId);
                                                if (targetObject) {
                                                    fields[i] = {
                                                        ...fields[i],
                                                        text: event.target.value
                                                    }
                                                    setTemporaryArray(fields);
                                                }
                                            }}
                                        />
                                        <span className="delete-tag" onClick={() => {
                                            setIndexWhenDeleteClick(i);
                                            setElementId(item.id)
                                            if (item.hasOwnProperty('tagId') && item.tagId != '') {
                                                setTagId(item.tagId);
                                                setIsShowConfirmation(true);
                                            } else {
                                                setIsShowConfirmation(true);
                                                setTagId(0)
                                            }
                                        }}></span>
                                    </li>
                                ))}
                            </ul>
                            </Scrollbars>
                        </div>
                        {/* <button className="button-dotted add-new" onClick={appendText}><span>Add New Tags</span></button> */}
                        <button className="button-dotted add-new flex" onClick={appendText}><img width={16} src={plusIcon}/>Add New Tags</button>
                    </div>
                    <div className="popup-min-bottom flex popup-min-bottom-tmargin"> 
                        <div className="popup-min-bottom-right flex">
                            <button className="close-button-no-icons close" onClick={() => {
                                cancelPopUp()
                                setCancelClicked(true)
                            }}>Cancel</button>
                            {(temporaryArray.length > 0) && <button className="blue-button save"><img width={15} src={saveWhite}/>Save</button>}
                            {(temporaryArray.length == 0) && <button className="blue-button save" disabled={true}><img width={15} src={saveWhite}/> Save</button>}
                        </div>
                    </div>
                </form>
            </div>
            <div style={{ top: actionPos?.top, left: actionPos?.left }}
                className={`tag-color-list ${Object.keys(actionPos).length ? "show" : ""
                    }`} ref={outsideActionRef}>
                {randomColors.map((color, i) => (
                    <span key={i} className={selectedColor ===  color? 'tag-color selected' : 'tag-color'} style={{ backgroundColor: color }} onClick={() => {
                        setColor(color);
                        setSelectedColor(color);
                    }}></span>
                ))}
                <span className="add-colorpicker" onClick={() => {
                    dispatch(releaseNoteActions.setColorPickerOpen({
                        open: true
                    }))
                }}>
                    {open && <SketchPicker color={color} onChange={setColors} />}
                </span>
            </div>
            {isShowConfirmation && (
                <Confirmation responseAction={confirmationesponseAction}></Confirmation>
            )}
            {isLoad && <LoadingSpinner />}
        </>
    );
}

export default AddTags;