import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../utils/constants/apiUrl";
import { STORE_CONST } from "../../utils/constants/store";
import customAxios from "../../utils/lib/customAxios";
import { getLocalStorage } from "../../utils/helper/commonHelper";
import { PROJECT_CONST } from "../../utils/constants/projectConst";

const getTagList = createAsyncThunk(
  `${STORE_CONST.TAG_NAMESPACE}/getTagList`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.TAG_LIST, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const saveTag = createAsyncThunk(
  `${STORE_CONST.TAG_NAMESPACE}/saveTag`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.ADD_TAG, arg.data);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const updateTag = createAsyncThunk(
  `${STORE_CONST.TAG_NAMESPACE}/updateTag`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.patch(API_URL.UPDATE_TAG, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const deleteTags = createAsyncThunk(
  `${STORE_CONST.TAG_NAMESPACE}/deleteTags`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.DELETE_TAG, arg);
      return {...data, length:arg?.length};
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getTagListForFeedBack = createAsyncThunk(
  `${STORE_CONST.TAG_NAMESPACE}/getTagListForFeedBack`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      headers["securityType"] = arg?.header?.securityType;
      if(arg?.header?.level2Id !== PROJECT_CONST.ZERO_CONST) {
        headers["Level2"] = arg?.header?.level2Id;
      }
      if (arg?.header?.token) {
        headers["Publicauthorization"] = "Bearer " + arg?.header?.token;
      }
      if (arg?.header?.isLibraryPage) {
        headers["IsLibraryPage"] = arg?.header?.isLibraryPage;
      }
      const { data } = await customAxios.post(
        API_URL.TAG_LIST_FOR_FEEDBACK,
        arg.param,
        {
          headers: headers,
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export { getTagList, saveTag, updateTag, deleteTags, getTagListForFeedBack };
