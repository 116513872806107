import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";
import { PROJECT_CONST } from "../utils/constants/projectConst";
import {
  convertDateTime,
  convertTZ,
  toasterError,
  toasterSuccess,
} from "../utils/helper/commonHelper";
import {
  getPreferenceData,
  multiStepCancel,
  publishData,
  singleStepCancel,
  updatePreferenceData,
} from "./api/Preference";
import store from "../store";
import { releaseNoteActions } from "./ReleaseNoteSlice";
import moment from "moment";
import { ERROR_CODE_CONST } from "../utils/constants/errorCode";

const PreferenceSlice = createSlice({
  name: STORE_CONST.PREFERENCE_NAME_SPACE,
  initialState: {
    isLoad: false,
    preferenceData: {},
    isPreferenceApiComplete: false,
    isPreferenceUpdateComplete: false,
    errors: {},
    activeState: {},
    preferenceDetails: {
      hasApiLoaded: false,
    },
    releaseRedirectDetails: {
      releaseId: 0,
      level2Id: 0,
      fromPreferences: false
    },
    useTimmer: false,
    preferenceSave: false,
    preferenceSaveType: '',
    publishLaterClicked: false,
    releaseDeleted: false,
    multiStepOpen:false
  },
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload.isLoad;
    },
    setMultiStepOpen(state, action) {
      state.multiStepOpen = action.payload.multiStepOpen;
    },
    setWhenToReleasePreferenceData(state, action) {
      state.preferenceData = action.payload.preferenceData;
    },
    setErrors(state, action) {
      state.errors = action.payload.errors;
    },
    setStep2Enable(state, action) {
      state.activeState = action.payload.activeState;
    },
    setUseTimmer(state, action) {
      state.useTimmer = action.payload.useTimmer;
    },
    setPreferenceToSave(state, action) {
      state.preferenceSave = action.payload.preferenceSave;
    },
    setPreferenceToSaveType(state, action) {
      state.preferenceSaveType = action.payload.preferenceSaveType;
    },
    setPublishLaterClick(state, action) {
      state.publishLaterClicked = action.payload.publishLaterClicked;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPreferenceData.pending, (state, { payload }) => {
      state.isLoad = true;
      state.isPreferenceApiComplete = false;
      state.releaseDeleted = false
    });
    builder.addCase(getPreferenceData.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.releaseDeleted = false
        state.isPreferenceApiComplete = true;
        let IndividualEmailsForStep1;
        let DistributionlistForStep1;
        let IndividualEmailsForStep2;
        let DistributionlistForStep2;
        let scheduleDate;
        let scheduleTimezone;
        let publishedPreferencesScheduleTime1;
        let publishedPreferencesScheduleTime2;
        let timeZoneForTime1;
        let timeZoneForTime2;
        let preferenceData = payload?.response?.data;
        if (
          preferenceData.preferences.publishedPreferencesScheduleTime1 !=
          null &&
          preferenceData.preferences.publishedPreferencesScheduleTime2 == null
        ) {
          scheduleDate = JSON.stringify(
            preferenceData.preferences.publishedPreferencesScheduleTime1
          );
          scheduleTimezone =
            preferenceData?.preferences?.publishedPreferencesSchedule1TimeZone;
          scheduleDate = JSON.stringify(
            convertTZ(
              new Date(moment.utc(JSON.parse(scheduleDate))),
              scheduleTimezone
            )
          );
        } else {
          scheduleDate = null;
          scheduleTimezone = "";
        }
        if (
          preferenceData.preferences.publishedPreferencesScheduleTime1 !=
          null &&
          preferenceData.preferences.publishedPreferencesScheduleTime2 != null
        ) {
          publishedPreferencesScheduleTime1 = JSON.stringify(
            preferenceData.preferences.publishedPreferencesScheduleTime1
          );

          timeZoneForTime1 =
            preferenceData.preferences.publishedPreferencesSchedule1TimeZone;
          timeZoneForTime2 =
            preferenceData.preferences.publishedPreferencesSchedule2TimeZone;

          publishedPreferencesScheduleTime1 = JSON.stringify(
            convertTZ(
              new Date(
                moment.utc(JSON.parse(publishedPreferencesScheduleTime1))
              ),
              timeZoneForTime1
            )
          );
          publishedPreferencesScheduleTime2 = JSON.stringify(
            preferenceData.preferences.publishedPreferencesScheduleTime2
          );
          publishedPreferencesScheduleTime2 = JSON.stringify(
            convertTZ(
              new Date(
                moment.utc(JSON.parse(publishedPreferencesScheduleTime2))
              ),
              timeZoneForTime2
            )
          );
        } else {
          publishedPreferencesScheduleTime1 = null;
          publishedPreferencesScheduleTime2 = null;
          timeZoneForTime1 = null;
          timeZoneForTime2 = null;
        }

        if (preferenceData.step1.IndividualEmails == null) {
          IndividualEmailsForStep1 = [];
        } else {
          IndividualEmailsForStep1 = preferenceData.step1.IndividualEmails;
        }
        if (preferenceData.step1.Distributionlist == null) {
          DistributionlistForStep1 = [];
        } else {
          DistributionlistForStep1 = preferenceData.step1.Distributionlist;
        }

        if (preferenceData.step2.IndividualEmails == null) {
          IndividualEmailsForStep2 = [];
        } else {
          IndividualEmailsForStep2 = preferenceData.step2.IndividualEmails;
        }
        if (preferenceData.step2.Distributionlist == null) {
          DistributionlistForStep2 = [];
        } else {
          DistributionlistForStep2 = preferenceData.step2.Distributionlist;
        }

        let step1 = {};
        let step2 = {};
        if (payload?.response?.data?.preferences?.releaseChoiceID === 1) {
          step1 = {};
          step2 = {};
        } else if (payload?.response?.data?.preferences?.releaseChoiceID === 2) {
          if (payload?.response?.data?.preferences?.step1.date != '' &&
            payload?.response?.data?.preferences?.step1.time != '') {
            var time = payload?.response?.data?.preferences?.step1?.time.split(':')
            step1 = {
              date: JSON.stringify(payload?.response?.data?.preferences?.step1?.date),
              hour: time[0],
              minute: time[1],
              meridiem: payload?.response?.data?.preferences?.step1?.meridiem,
              timeZone: payload?.response?.data?.preferences?.step1?.timeZone
            };
          }
          step2 = {};
        } else if (payload?.response?.data?.preferences?.releaseChoiceID === 3) {
          if (payload?.response?.data?.preferences?.step2.date != '' &&
            payload?.response?.data?.preferences?.step2.time != '') {
            var step1Time = payload?.response?.data?.preferences?.step1?.time.split(':');
            var step2Time = payload?.response?.data?.preferences?.step2?.time.split(':')
            step1 = {
              date: JSON.stringify(payload?.response?.data?.preferences?.step1?.date),
              hour: step1Time[0],
              minute: step1Time[1],
              meridiem: payload?.response?.data?.preferences?.step1?.meridiem,
              timeZone: payload?.response?.data?.preferences?.step1?.timeZone
            };
            step2 = {
              date: JSON.stringify(payload?.response?.data?.preferences?.step2?.date),
              hour: step2Time[0],
              minute: step2Time[1],
              meridiem: payload?.response?.data?.preferences?.step2?.meridiem,
              timeZone: payload?.response?.data?.preferences?.step2?.timeZone
            };
          }
        }

        state.preferenceData = preferenceData = {
          preferences: {
            ...payload?.response?.data?.preferences,
            scheduleDate: scheduleDate,
            publishedPreferencesScheduleTime1:
              publishedPreferencesScheduleTime1,
            publishedPreferencesScheduleTime2:
              publishedPreferencesScheduleTime2,
            useLatest: preferenceData.preferences.publishedPreferencesUseLatest,
            scheduleTimezone: scheduleTimezone,
            permenentScheduleTime1: publishedPreferencesScheduleTime1,
            permenentScheduleTime2: publishedPreferencesScheduleTime2,
            permenentScheduleDate: scheduleDate,
            permenentScheduleTimezone: scheduleTimezone,
            permenenttimeZoneForTime1: timeZoneForTime1,
            permenenttimeZoneForTime2: timeZoneForTime2,
            publishedPreferencesScheduleDate1: '',
            publishedPreferencesScheduleDate2: '',
            publishedPreferencesScheduleTimeHour1: '',
            publishedPreferencesScheduleTimeHour2: '',
            publishedPreferencesScheduleTimeMinute1: '',
            publishedPreferencesScheduleTimeMinute2: '',
            publishedPreferencesScheduleTimeAmPm1: '',
            publishedPreferencesScheduleTimeAmPm2: '',
            step1: step1,
            step2: step2
          },
          step1: {
            ...preferenceData.step1,
            Distributionlist: DistributionlistForStep1,
            IndividualEmails: IndividualEmailsForStep1,
          },
          step2: {
            ...preferenceData.step1,
            Distributionlist: DistributionlistForStep2,
            IndividualEmails: IndividualEmailsForStep2,
          },
        };

        state.errors = {
          step1: {
            dateError: false,
            timeError: false,
            timeZoneError: false,
            emailError: false
          },
          step2: {
            dateError: false,
            timeError: false,
            timeZoneError: false,
            emailError: false
          }
        };
        state.activeState = {
          step1: true,
          step2: false,
        };

        state.preferenceDetails = {
          ...state.preferenceDetails,
          hasApiLoaded: true,
        };
        state.releaseRedirectDetails.fromPreferences = false;
      } else if (payload?.hasError && payload?.errorCode === ERROR_CODE_CONST.RELEASE_DELETED
      ) {
        state.preferenceData = {};
        state.releaseDeleted = payload?.response?.releaseDeleted
        toasterError(payload?.message);
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.preferenceData = {};
        toasterError(payload?.message);
      }
    });
    builder.addCase(getPreferenceData.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.isPreferenceApiComplete = false;
      state.releaseDeleted = false
    });
    builder.addCase(updatePreferenceData.pending, (state, { payload }) => {
      state.isLoad = true;
      state.isPreferenceUpdateComplete = false;
    });
    builder.addCase(updatePreferenceData.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.isPreferenceUpdateComplete = true;
        state.releaseRedirectDetails = {
          ...state.releaseRedirectDetails,
          releaseId: payload?.response?.release?.releaseId,
          level2Id: payload?.response?.release?.level2Id,
          fromPreferences: true
        };
        if (payload?.response?.type === PROJECT_CONST.SAVE) {
          toasterSuccess(PROJECT_CONST.PUBLIC_PREFERENCES_UPDATE_MSG);
        } else if (payload?.response?.type === PROJECT_CONST.PUBLISH) {
          toasterSuccess(PROJECT_CONST.PUBLISH_MSG);
        }
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
      ) {
        state.releaseRedirectDetails = {
          releaseId: 0,
          level2Id: 0,
          fromPreferences: false
        };
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.releaseRedirectDetails = {
          releaseId: 0,
          level2Id: 0,
          fromPreferences: false
        };
        toasterError(payload?.message);
      }
    });
    builder.addCase(updatePreferenceData.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.isPreferenceUpdateComplete = false;
    });
    builder.addCase(publishData.pending, (state, { payload }) => {
      state.isLoad = true;
      state.isPreferenceUpdateComplete = false;
    });
    builder.addCase(publishData.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.isPreferenceUpdateComplete = true;
        if (payload?.response?.fromReleaseDetails) {
          state.releaseRedirectDetails = {
            ...state.releaseRedirectDetails,
            releaseId: payload?.response?.release?.releaseId,
            level2Id: payload?.response?.release?.level2Id,
          };
        }
        toasterSuccess(PROJECT_CONST.PUBLISH_MSG);
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.releaseRedirectDetails = {
          releaseId: 0,
          level2Id: 0,
        };
        toasterError(payload?.message);
      }
    });
    builder.addCase(publishData.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.isPreferenceUpdateComplete = false;
    });

    builder.addCase(singleStepCancel.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(singleStepCancel.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        toasterSuccess(PROJECT_CONST.SINGLE_STEP_CANCEL_MSG);
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(singleStepCancel.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(multiStepCancel.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(multiStepCancel.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        if (payload?.choice === 3) {
          toasterSuccess(PROJECT_CONST.MULTI_STEP_CANCEL_MSG);
        } else {
          toasterSuccess(PROJECT_CONST.SINGLE_STEP_CANCEL_MSG);
        }
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.releaseRedirectDetails = {
          releaseId: 0,
          level2Id: 0,
        };
        toasterError(payload?.message);
      }
    });
    builder.addCase(multiStepCancel.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
  },
});

export const PreferenceSliceActions = PreferenceSlice.actions;

export default PreferenceSlice;
