import React from "react";
import "./ReleaseTags.css";
import Scrollbars from "react-custom-scrollbars-2";
import { closeGrey } from "../../utils/imports/images";

function ReleaseTags({ tags, removeTags, closeTagpopup, fromPage = '' }) {
  return (
    <>
      <div>
        <div className="popup-overlay"></div>
        <div className="popup-min tags-popup">
          <div className="popup-min-top">
            <div className="popup-min-top-header flex">
              <h2>View Tags</h2>
              <span className="popup-close" onClick={closeTagpopup}><img width={16} src={closeGrey}/></span>
            </div>
            <ul className="tags-wrapper">
            <Scrollbars autoHeight={true} autoHeightMax={"420px"} autoHide={true} >
              {tags && (tags.length)? tags.map((tag, index) => (
                <li key={index} className="tag-item flex">
                  {/* <span className="tag-bg" style={{backgroundColor:'#ebebeb'}}> */}
                  <span
                    className="tag-color"
                    style={{ backgroundColor: tag.color }}
                  ></span>
                  <span className="tag-name">{tag.label}</span>
                 {fromPage === 'dashboardFilter' || fromPage === "releaseFilter" && <div className="filter-tag-multiselect-close" onClick={() => {
                    removeTags(tag)
                  }}></div>
                  } 
                  {/* </span> */}
                </li>
              )):
              <span className="no-tags-found">No tags found!</span>
              }
              </Scrollbars>
            </ul>
          </div>
          <div className="popup-min-bottom  popup-min-bottom-tmargin">
            <div className="popup-min-bottom-left">
              <button
                className="close-button close"
                onClick={() => closeTagpopup()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReleaseTags;
