export const API_URL = {
  LEVEL_ONE_LIST: "customer/level1/list",
  LEVEL_ONE_CREATE: "customer/level1/add",
  LEVEL_ONE_UPDATE: "customer/level1/edit",
  LEVEL_TWO_LIST: "customer/level2/list",
  LEVEL_TWO_CREATE: "customer/level2/add",
  LEVEL_TWO_UPDATE: "customer/level2/edit",
  RELEASE_NOTE_LIST: "release/level2/list",
  RELEASE_NOTE_DETAILS: "release/details",
  RELEASE_NOTE_FEEDBACK_GRID_DETAILS: "release/feedback/grid",
  RELEASE_NOTE_FEEDBACK_LIST: "release/feedback/list",
  RELEASE_NOTE_CREATE: "release/create",
  RELEASE_NOTE_UPDATE: "release/save",
  RELEASE_NOTE_DELETE: "release/delete",
  RELEASE_NOTE_PREVIEW: "release/preview",
  RELEASE_NOTE_PREVIEW_BY_KEY: "release/viewrelease",
  RELEASE_NOTE_HISTORY: "release/history/list",
  RELEASE_NOTE_FILE_UPLOAD: "release/image-upload",
  PUBLISH_RELEASE_NOTE: "release/publish",
  GET_RELEASE_NUMBER: "release/getreleasewithfeedbackkey",
  STATUS_LIST: "release/status/list",
  SCHEDULE_LIST: "release/schedules",
  BAR_CHART_DETAILS: "release/no-of-release",
  SUBSCRIBERS: "release/subscribers",
  TAG_LIST: "tag/list",
  ADD_TAG: "tag/bulkaddedit",
  UPDATE_TAG: "tag/edit",
  DELETE_TAG: "tag/delete",
  DL_LIST: "distribution/list",
  SAVE_DL_LIST: "distribution/add",
  UPDATE_DL_LIST: "distribution/edit",
  DELETE_DL_LIST: "distribution/delete",
  DL_MEMBER_LIST: "target/list",
  DL_LIST_NAME: "distribution/details",
  DL_SAVE_MEMBER: "target/add",
  DL_UPDATE_MEMBER: "target/edit",
  DL_DELETE_MEMBER: "target/delete",
  VERIFY_TARGET: "target/verified",
  BULK_EMAIL_IMPORT: "target/bulk-add",
  RESEND_VERIFY_TARGET: "target/send-email",
  FEEDBACK_RELEASE_LIST: "feedback/release/list",
  FEEDBACK_HEADER: "feedback/release/header",
  // FEEDBACK_LIST_BY_RELEASE_ID: "feedback/release/list",
  SAVE_FEEDBACK: "feedback/add",
  // REVIEW_FEEDBACK: "feedback/reviewed",
  UNSUBSCRIBE: "subscriber/unsubscribe",
  APP_DETAILS: "common/data",
  LOGIN: "authenticate",
  PREFERENCEAPI: "release/getpreference",
  UPDATE_PREFERENCE_API: "release/preference/update",
  PUBLISH_DATA: "release/publishpreferences",
  JIRA_CREDENTIALS: "jira/getjiracredentials",
  JIRA_KEYS: "jira/getjirakeys",
  JIRA_PROJECTS: "jira/getprojects",
  JIRA_TICKET: "jira/getticketdata",
  SAVE_JIRA_CRED: "jira/savecredentials",
  UPDATE_JIRA_CRED: "jira/updatecredentials",
  JIRA_LOADED_DETAILS: "jira/getloadeddata",
  SEND_OTP: "feedback/subscriber/send-otp",
  SUBMIT_OTP: "feedback/subscriber/verification",
  SEND_OTP_FEEDBACK: "feedback/add/send-otp",
  SUBMIT_OTP_FEEDBACK_VERIFICATION: "feedback/add/verification",
  RESEND_OTP: "feedback/subscriber/resend-otp",
  RESEND_OTP_FEEDBACK: "feedback/add/resend-otp",
  JIRA_EDIT_CREDENTIALS: "jira/getjiracredential",
  DELETE_FEED_BACK: "feedback/delete",
  FEED_BACK_UPDATE: "feedback/edit",
  RELEASE_DETAILS_WITH_RELEASE_ID: "release/getreleasewithid",
  ALL_GLOBAL_FEED_BACK: "feedback/all",
  GET_EMOJIS: "customer/details",
  REPLY: "feedback/reply-feedback",
  FORWARD: "feedback/forward-feedback",
  FEEDBACK_READ: "feedback/reviewed",
  SETTINGS_SAVE: "settings/social-media/save",
  SETTINGS_DETAILS: "settings/social-media/details",
  SETTINGS_RESET_PASSWORD: "settings/user/reset-password",
  SETTINGS_UPDATE_PROFILE: "settings/user/profile-update",
  SETTINGS_JIRA_CREDENTIALS: "jira/credentials/list",
  SETTINGS_JIRA_MARK_SHARED: "jira/credentials/mark-shared",
  SETTINGS_JIRA_STATUS_CHANGE: "jira/credentials/enabledisable",
  SETTINGS_JIRA_CRED_DELETE: "jira/credentials/delete",
  TAG_LIST_FOR_FEEDBACK: "taglist",
  GET_SUBSCRIBER_DETAILS_WITH_ID: "subscriber/details",
  SAVE_SUBSCRIBER_PREFERENCE_DATA: "subscriber/subscribe",
  SETTINGS_FEEDBACK_DETAILS: "settings/user/level2",
  SETTINGS_LEVEL2_USER_UPDATE: "settings/user/level2-update",
  SETTINGS_UPLOAD_IMG: "settings/user/picture-update",
  SETTINGS_DELETE_IMG: "settings/user/picture-delete",
  GET_DNS_SECURITY_TYPE: "settings/admin/dns-list",
  SAVE_DNS_SETTINGS: "settings/admin/submit-security-type",
  CHECK_SECURITY_TYPE: "securitytype",
  PAGE_AUTHORISATION: "pageauthorisation",
  SINGLE_STEP_CANCEL: "release/cancel/single-step",
  MULTI_STEP_CANCEL: "release/cancel/multi-step",
  SETTINGS_USER_LIST: "settings/user/list",
  SETTINGS_USER_INVITE: "settings/user/send-invite",
  SETTINGS_USER_ADD: "user/add",
  SETTINGS_ADMIN_PROFILE_UPDATE: "settings/user/admin-profile-update",
  SETTINGS_USER_DETAILS_BY_TOKEN: "user/invite-details",
  CREATE_JIRA_TICKET_FOR_AN_RELEASE: "jira/ticket/create",
  GET_GLOBAL_SUBSCRIBERS: "subscribers/list",
  CHANGE_STATUS_FOR_GLOBAL_SUBSCRIBERS: "subscribers/toggleactive",
  RELEASE_NOTE_COLLABORATOR_LIST: "release/collaborators",
  SET_LEVEL_ONE_ARCHIVE: "customer/level1/archive",
  SET_LEVEL_TWO_ARCHIVE: "customer/level2/archive",
  GET_ARCHIVE_DETAILS: "customer/archive/details",
  DELETE_LEVEL_ONE: "customer/level1/delete",
  DELETE_LEVEL_TWO: "customer/level2/delete",
  SUBSCRIBER_DELETE: "subscribers/delete",
  GLOBAL_FEEDBACK_DELETE: "feedback/id/delete",
  USER_DEACTIVATE: "settings/delete-user",
  USER_RESEND_INVITATION: "settings/user/resend-invite",
  CHECK_RELEASE_DELETED: "release/check-deleted",
  GET_BUSINESS_DATA: "settings/customer/get-level1-level2/label",
  SAVE_BUSINESS_DATA: "customer/update/level1-level2/label",
  UPLOAD_FEEDBACK_ICONS: "settings/admin/upload-feedback-icon",
  RESET_TO_DEFAULT: "settings/admin/reset-icon-data",
  DELETE_FEEDBACK_ICON: "settings/admin/delete-icon",
  SAVE_FEEDBACK_DATA: "settings/admin/update-feedback-data",
  RELEASE_NOTE_DELETE_FILE: "release/delete-image",
  GET_FEEDBACK_PERCENTAGE: "feedback/calculate-percentage",
  USER_RESET_PASSWORD: "/user/reset-password",
  SETTINGS_COMPANY_PROFILE: "settings/customer-profile",
  SETTINGS_COMPANY_PROFILE_LOGO: "settings/company-logo-update",
  SETTINGS_UPDATE_COMPANY_PROFILE: "settings/company-profile-update",
  SETTINGS_COMPANY_PROFILE_LOGO_DELETE: "settings/company-profile-delete",
  SEND_APPLICATION_FEEDBACK: "user/send-application-feedback",
  UNSUBSCRIBE_FROM_EMAIL: "subscription/unsubscribe",
  REPORT_AS_SPAM: "report-spam/submit",
  GET_SPAM_REASON_LIST: "report-spam/reasons/list",
  GET_APPLICATION_TOKEN: "settings/app-token/list",
  CREATE_APPLICATION_TOKEN: "settings/app-token/create",
  DELETE_APPLICATION_TOKEN: "settings/app-token/delete",
  GET_JIRA_CONNECTION_STATUS: "jira/credentials/connection-status"
};
