import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../utils/constants/apiUrl";
import { STORE_CONST } from "../../utils/constants/store";
import customAxios from "../../utils/lib/customAxios";
import { PROJECT_CONST } from "../../utils/constants/projectConst";
import { getLocalStorage } from "../../utils/helper/commonHelper";

const getFeedbackRelease = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/getFeedbackRelease`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      if (arg?.header?.token) {
        headers["Publicauthorization"] = "Bearer " + arg?.header?.token;
      }
      const { data } = await customAxios.post(
        API_URL.FEEDBACK_RELEASE_LIST,
        arg.param,
        {
          headers: headers,
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getfeedbackHeader = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/getfeedbackHeader`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.FEEDBACK_HEADER, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const feedBackSubmit = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/feedBackSubmit`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      headers["securityType"] = arg?.header?.securityType;
      if(arg?.header?.level2Id) {
        headers["Level2"] = arg?.header?.level2Id
      }
      if (arg?.header?.token) {
        headers["Publicauthorization"] = "Bearer " + arg?.header?.token;
      }
      const { data } = await customAxios.post(
        API_URL.SAVE_FEEDBACK,
        arg.param,
        {
          headers: headers,
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getFeedBackDetails = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/getFeedBackDetails`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      if(arg?.header?.level2Id && arg?.header?.level2Id !== PROJECT_CONST.ZERO_CONST) {
        headers["Level2"] = arg?.header?.level2Id
      }
      if (arg?.header?.token) {
        headers["Publicauthorization"] = "Bearer " + arg?.header?.token;
      }
      if(arg?.header?.isLibraryPage) {
        headers["IsLibraryPage"] = arg?.header?.isLibraryPage;
      }
      const { data } = await customAxios.post(
        API_URL.GET_RELEASE_NUMBER,
        arg.param,
        {
          headers: headers,
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const submitOtp = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/submitOtp`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      headers["securityType"] = arg?.header?.securityType;
      if(arg?.header?.level2Id) {
        headers["Level2"] = arg?.header?.level2Id;
      }
      if (arg?.header?.token) {
        headers["Publicauthorization"] = "Bearer " + arg?.header?.token;
      }
      if(arg?.header?.isLibraryPage) {
        headers["IsLibraryPage"] = arg?.header?.isLibraryPage;
      }
      let url = API_URL.SUBMIT_OTP;
      if (arg?.param?.otpType == PROJECT_CONST.OTP_TYPE_SUBMIT_FEEDBACK) {
        url = API_URL.SUBMIT_OTP_FEEDBACK_VERIFICATION;
      }

      const { data } = await customAxios.post(
        url,
        arg.param,
        {
          headers: headers,
        }
      );
      if(Object.keys(arg?.extraParam).length) {
        return {
          ...data,
          extraParam: arg?.extraParam
        }
      }else{
        return data;
      }
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const sendOtp = createAsyncThunk(
  `${STORE_CONST.COMMON_NAMESPACE}/senOtp`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      headers["securityType"] = arg?.header?.securityType;
      if(arg?.header?.level2Id && arg?.header?.level2Id !== PROJECT_CONST.ZERO_CONST) {
        headers["Level2"] = arg?.header?.level2Id
      }
      if (arg?.header?.token) {
        headers["Publicauthorization"] = "Bearer " + arg?.header?.token;
      }
      if(arg?.header?.isLibraryPage) {
        headers["IsLibraryPage"] = arg?.header?.isLibraryPage;
      }
      let url = API_URL.SEND_OTP;
      if (
        arg?.param?.otpType == PROJECT_CONST.OTP_TYPE_SUBMIT_FEEDBACK ||
        arg?.param?.otpType == PROJECT_CONST.OTP_TYPE_DELETE_FEEDBACK
      ) {
        url = API_URL.SEND_OTP_FEEDBACK;
      }
      const { data } = await customAxios.post(
        url,
        arg?.param,
        {
          headers: headers,
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const reSendOtp = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/reSendOtp`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      headers["securityType"] = arg?.header?.securityType;
      if(arg?.header?.level2Id) {
        headers["Level2"] = arg?.header?.level2Id;
      }
      if (arg?.header?.token) {
        headers["Publicauthorization"] = "Bearer " + arg?.header?.token;
      }
      if(arg?.header?.isLibraryPage) {
        headers["IsLibraryPage"] = arg?.header?.isLibraryPage;
      }
      let url = API_URL.RESEND_OTP;
      if (arg?.param?.otpType == PROJECT_CONST.OTP_TYPE_SUBMIT_FEEDBACK) {
        url = API_URL.RESEND_OTP_FEEDBACK;
      }
      const { data } = await customAxios.post(
        url,
        arg?.param,
        {
          headers: headers,
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const deleteFeedback = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/deleteFeedback`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      if(arg?.header?.level2Id) {
        headers["Level2"] = arg?.header?.level2Id;
      }
      headers["securityType"] = arg?.header?.securityType;
      if (arg?.header?.token) {
        headers["Publicauthorization"] = "Bearer " + arg?.header?.token;
      }
      const { data } = await customAxios.post(
        API_URL.DELETE_FEED_BACK,
        arg.param,
        {
          headers: headers,
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const updateFeedBack = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/updateFeedBack`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      if(arg?.header?.level2Id) {
        headers["Level2"] = arg?.header?.level2Id
      }
      headers["securityType"] = arg?.header?.securityType;
      if (arg?.header?.token) {
        headers["Publicauthorization"] = "Bearer " + arg?.header?.token;
      }
      const { data } = await customAxios.patch(
        API_URL.FEED_BACK_UPDATE,
        arg.param,
        {
          headers: headers,
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const unsubscribeUser = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/unsubscribeUser`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      if(arg?.header?.level2Id) {
        headers["Level2"] = arg?.header?.level2Id;
      }
      headers["securityType"] = arg?.header?.securityType;
      if (arg?.header?.token) {
        headers["Publicauthorization"] = "Bearer " + arg?.header?.token;
      }
      const { data } = await customAxios.post(
        API_URL.UNSUBSCRIBE,
        arg.param,
        {
          headers: headers,
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const unsubscribeUserFromMail = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/unsubscribeUserFromMail`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.UNSUBSCRIBE, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getFeedBackDetailsWithReleaseId = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/getFeedBackDetailsWithReleaseId`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      if(arg?.header?.level2Id) {
        headers["Level2"] = arg?.header?.level2Id;
      }
      if (arg?.header?.token) {
        headers["Publicauthorization"] = "Bearer " + arg?.header?.token;
      }
      if(arg?.header?.isLibraryPage) {
        headers["IsLibraryPage"] = arg?.header?.isLibraryPage;
      }
      const { data } = await customAxios.post(
        API_URL.RELEASE_DETAILS_WITH_RELEASE_ID,
        arg.param,
        {
          headers: headers,
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getAllFeedBacksForLevel1andLevel2 = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/getAllFeedBacksForLevel1andLevel2`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.ALL_GLOBAL_FEED_BACK,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getEmojisForCustomer = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/getEmojisForCustomer`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.get(API_URL.GET_EMOJIS);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const replyForAnFeedback = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/replyForAnFeedback`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.REPLY, arg);
      // return {
      //   data: data,
      //   feedBackIds: arg?.feedbackIds
      // }
      return {
        ...data,
        response: {
          ...data?.response,
          feedBackIds: arg?.feedbackIds,
        },
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const forwardForAnFeedback = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/forwardForAnFeedback`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.FORWARD, arg);
      return {
        data: data,
        feedBackIds: arg?.feedbackIds,
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const feedBackAsRead = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/feedBackAsRead`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.patch(API_URL.FEEDBACK_READ, arg);
      return {
        data: data,
        isRead: arg.isRead,
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getSubscriberDetailsWithId = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/getSubscriberDetailsWithId`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      headers["Level2"] = getLocalStorage("level2IdForFeedback")? getLocalStorage("level2IdForFeedback"): "";
      headers["securityType"] = arg?.header?.securityType;
      const { data } = await customAxios.post(
        API_URL.GET_SUBSCRIBER_DETAILS_WITH_ID,
        arg.param,
        {
          headers: headers,
        }
      );
      
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const saveSubscriberPreferenceData = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/saveSubscriberPreferenceData`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      if(arg?.header?.level2Id) {
        headers["Level2"] = arg?.header?.level2Id;
      }
      headers["securityType"] = arg?.header?.securityType;
      if (arg?.header?.token) {
        headers["Publicauthorization"] = "Bearer " + arg?.header?.token;
      }
      const { data } = await customAxios.post(
        API_URL.SAVE_SUBSCRIBER_PREFERENCE_DATA,
        arg.subscriptionDetails,
        {
          headers: headers,
        }
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const deleteFeedBackById = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/deleteFeedBackById`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.GLOBAL_FEEDBACK_DELETE, arg);
      return {
        ...data,
        length: arg?.length
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);


const getFeedbackPercentage = createAsyncThunk(
  `${STORE_CONST.FEEDBACK_NAMESPACE}/getFeedbackPercentage`,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.GET_FEEDBACK_PERCENTAGE,
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export {
  saveSubscriberPreferenceData,
  getSubscriberDetailsWithId,
  getFeedBackDetailsWithReleaseId,
  feedBackAsRead,
  forwardForAnFeedback,
  replyForAnFeedback,
  getEmojisForCustomer,
  getAllFeedBacksForLevel1andLevel2,
  unsubscribeUserFromMail,
  unsubscribeUser,
  deleteFeedback,
  updateFeedBack,
  getFeedbackRelease,
  getfeedbackHeader,
  feedBackSubmit,
  getFeedBackDetails,
  submitOtp,
  sendOtp,
  reSendOtp,
  deleteFeedBackById,
  getFeedbackPercentage
};
