import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../utils/constants/apiUrl";
import { STORE_CONST } from "../../utils/constants/store";
import customAxios from "../../utils/lib/customAxios";

const getGlobalSubscribersList = createAsyncThunk(
  `${STORE_CONST.GLOBAL_SUBSCRIBERS}/getGlobalSubscribersList`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.GET_GLOBAL_SUBSCRIBERS,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const markSubscriberAsAciveDeactive = createAsyncThunk(
  `${STORE_CONST.GLOBAL_SUBSCRIBERS}/markSubscriberAsAciveDeactive`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.CHANGE_STATUS_FOR_GLOBAL_SUBSCRIBERS,
        arg
      );
      return {
        ...data,
        response: {
          ...data.response,
          isActive: arg?.isActive,
        },
      };
      // return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const deleteSubscriber = createAsyncThunk(
  `${STORE_CONST.GLOBAL_SUBSCRIBERS}/deleteSubscriber`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.SUBSCRIBER_DELETE,
        arg
      );
      return {
        ...data,
        length: arg?.length
      }
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const unSubscribeAuser = createAsyncThunk(
  `${STORE_CONST.GLOBAL_SUBSCRIBERS}/unSubscribeAuser`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.UNSUBSCRIBE_FROM_EMAIL,
        arg
      );

      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const submitOtpForUnsubscribe = createAsyncThunk(
  `${STORE_CONST.COMMON_NAMESPACE}/submitOtpForUnsubscribe`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      let headers = {};
      headers["DNSName"] = arg?.header?.dnsName;
      if(arg?.header?.isLibraryPage) {
        headers["IsLibraryPage"] = arg?.header?.isLibraryPage;
      }
      let url = API_URL.SUBMIT_OTP;
      const { data } = await customAxios.post(
        url,
        arg.param,
        {
          headers: headers,
        }
      );
     return {
      ...data,
      callUnSubscribe: arg?.param?.callUnSubscribe
     }
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export { getGlobalSubscribersList, markSubscriberAsAciveDeactive, deleteSubscriber, unSubscribeAuser, submitOtpForUnsubscribe };
