import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../utils/constants/apiUrl";
import { STORE_CONST } from "../../utils/constants/store";
import customAxios from "../../utils/lib/customAxios";

const saveSettings = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/save`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.SETTINGS_SAVE, arg?.data);
      return {
        ...data,
        response: {
          ...data.response,
          type: arg?.type,
        },
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getSettings = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/getSettings`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.get(API_URL.SETTINGS_DETAILS);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const resetPasswordSettings = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/resetPasswordSettings`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.SETTINGS_RESET_PASSWORD);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);
const updateProfileSettings = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/updateProfile`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.SETTINGS_UPDATE_PROFILE,
        arg
      );
      // return data;
      return {
        ...data,
        response: {
          ...data.response,
          details: arg,
        },
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getJiraCredentialsSettings = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/getJiraCredentialsSettings`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.SETTINGS_JIRA_CREDENTIALS,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const marksharedJira = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/marksharedJira`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.SETTINGS_JIRA_MARK_SHARED,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const statusChangeJira = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/statusChangeJira`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.SETTINGS_JIRA_STATUS_CHANGE,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const settingsFeedbackDetails = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/settingsFeedbackDetails`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.SETTINGS_FEEDBACK_DETAILS,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const settingslevel2UserUpdate = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/settingslevel2UserUpdate`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.patch(
        API_URL.SETTINGS_LEVEL2_USER_UPDATE,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const uploadFileSettings = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/uploadFileSettings`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.SETTINGS_UPLOAD_IMG,
        arg?.formData
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const deleteJiraCredSettings = createAsyncThunk(
  `${STORE_CONST.DL_NAMESPACE}/deleteJiraCredSettings`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.SETTINGS_JIRA_CRED_DELETE, arg);
      return {
        ...data,
        response: { ...data.response, jiraCredId: arg.credentialId },
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const deleteFileSettings = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/deleteFileSettings`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.SETTINGS_DELETE_IMG);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getDnsSecurityType = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/getDnsSecurityType`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.GET_DNS_SECURITY_TYPE);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const settingsUserList = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/settingsUserList`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.SETTINGS_USER_LIST, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const settingsUserInvite = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/settingsUserInvite`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.SETTINGS_USER_INVITE,
        arg
      );
      // return data;
      return {
        ...data,
        response: { ...data.response, index: arg.index },
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const settingsUserAdd = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/settingsUserAdd`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.SETTINGS_USER_ADD, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const settingsAdminProfileUpdate = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/settingsAdminProfileUpdate`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.SETTINGS_ADMIN_PROFILE_UPDATE,
        arg
      );
      // return data;
      return {
        ...data,
        response: { ...data.response, index: arg.index, uniqueId: arg?.uniqueId },
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getUserDetailsByToken = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/getUserDetailsByToken`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.SETTINGS_USER_DETAILS_BY_TOKEN,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const deActivateUser = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/deActivateUser`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.USER_DEACTIVATE,
        arg
      );
      return {
        ...data,
        response: { ...data.response, uniqueId: arg?.uniqueId },
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const resendUserInvitation = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/resendUserInvitation`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.USER_RESEND_INVITATION,
        arg
      );
      return {
        ...data,
        response: { ...data.response, uniqueId: arg?.uniqueId },
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getBusinessData = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/getBusinessData`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.GET_BUSINESS_DATA);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const saveBusinessData = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/saveBusinessData`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.SAVE_BUSINESS_DATA, arg?.data);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const uploadFeedbackIcons = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/uploadFeedbackIcons`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.UPLOAD_FEEDBACK_ICONS, arg?.formData);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const resetToDefaultForIcons = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/resetToDefaultForIcons`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.RESET_TO_DEFAULT, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const deleteFeedbackIcon = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/deleteFeedbackIcon`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.DELETE_FEEDBACK_ICON, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const saveFeedbackIcons = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/saveFeedbackIcons`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.SAVE_FEEDBACK_DATA, arg?.data);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

// get company profile details 

const getCompanyProfile = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/companyProfileDetails`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.get(API_URL.SETTINGS_COMPANY_PROFILE);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

// upload file for company profile logo

const uploadFileForCompanyProfileLogo = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/uploadFileForCompanyProfileLogo`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.SETTINGS_COMPANY_PROFILE_LOGO,
        arg?.formData
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);


const updateCompanyProfileSettings = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/updateCompanyProfile`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.patch(
        API_URL.SETTINGS_UPDATE_COMPANY_PROFILE,
        arg
      );
      // return data;
      return {
        ...data,
        response: {
          ...data.response,
          details: arg,
        },
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);


const deleteCompanyProfileLogo = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/deleteCompanyProfileLogo`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.SETTINGS_COMPANY_PROFILE_LOGO_DELETE);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getApplicationTokens = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/getApplicationTokens`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.GET_APPLICATION_TOKEN, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getJiraConnectionStatus = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/getJiraConnectionStatus`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.GET_JIRA_CONNECTION_STATUS, arg);
      
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const createApplicationToken = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/createApplicationToken`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.CREATE_APPLICATION_TOKEN, arg?.inputData);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const deleteApplicationToken = createAsyncThunk(
  `${STORE_CONST.SETTINGS_NAME_SPACE}/deleteApplicationToken`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.DELETE_APPLICATION_TOKEN, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);


export {
  deleteApplicationToken,
  createApplicationToken,
  getApplicationTokens,
  getJiraConnectionStatus,
  saveFeedbackIcons,
  deleteFeedbackIcon,
  resetToDefaultForIcons,
  uploadFeedbackIcons,
  saveBusinessData,
  getBusinessData,
  saveSettings,
  getSettings,
  resetPasswordSettings,
  updateProfileSettings,
  getJiraCredentialsSettings,
  marksharedJira,
  statusChangeJira,
  settingsFeedbackDetails,
  settingslevel2UserUpdate,
  uploadFileSettings,
  deleteJiraCredSettings,
  deleteFileSettings,
  getDnsSecurityType,
  settingsUserList,
  settingsUserInvite,
  settingsUserAdd,
  settingsAdminProfileUpdate,
  getUserDetailsByToken,
  deActivateUser,
  resendUserInvitation,
  getCompanyProfile,
  uploadFileForCompanyProfileLogo,
  updateCompanyProfileSettings,
  deleteCompanyProfileLogo
};
