import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";
import {
  deActivateUser,
  deleteFeedbackIcon,
  deleteFileSettings,
  deleteJiraCredSettings,
  getBusinessData,
  getDnsSecurityType,
  getJiraCredentialsSettings,
  getSettings,
  getUserDetailsByToken,
  marksharedJira,
  resendUserInvitation,
  resetPasswordSettings,
  resetToDefaultForIcons,
  saveBusinessData,
  saveFeedbackIcons,
  saveSettings,
  settingsAdminProfileUpdate,
  settingsFeedbackDetails,
  settingslevel2UserUpdate,
  settingsUserAdd,
  settingsUserInvite,
  settingsUserList,
  statusChangeJira,
  updateProfileSettings,
  uploadFeedbackIcons,
  uploadFileSettings,
  getCompanyProfile,
  updateCompanyProfileSettings,
  uploadFileForCompanyProfileLogo,
  deleteCompanyProfileLogo,
  getApplicationTokens,
  createApplicationToken,
  deleteApplicationToken,
  getJiraConnectionStatus,
} from "./api/SettingsApi";
import {
  getloginInfo,
  toasterError,
  toasterSuccess,
} from "../utils/helper/commonHelper";
import { PROJECT_CONST } from "../utils/constants/projectConst";
import { ERROR_CODE_CONST } from "../utils/constants/errorCode";

const SettingsSlice = createSlice({
  name: STORE_CONST.SETTINGS_NAME_SPACE,
  initialState: {
    isLoad: false,
    settingDetails: {
      details: {},
      jiraCredentialList: [],
    },
    updateProfileDetails: {
      hasApiLoaded: false,
    },
    userDetails: {
      list: [],
      hasApiLoaded: false,
    },
    userCreateDetails: {
      details: [],
      hasApiLoaded: false,
    },
    userInviteDetails: {
      details: [],
      hasApiLoaded: false,
      showInvitePage: false,
    },
    feedbackDetails: {},
    dnsSecurityType: [],
    urlForLibraryPage: "",
    redirectToLoginPage: false,
    isProfileOpen: false,
    hasLevel2Deleted: false,
    clickedId: 0,
    uniqueId: 1,
    businessData: {},
    uploadIconStatus: false,
    callSettingsApiForFeedbackIcons: false,
    companyProfileDetails: "",
    companyProfileLogo: "",
    callCompanyProfileApi: false,
    isDefaultLogo: false,
    message: "",
    applicationTokenList: [],
    applicationToken: "",
    callApplicationTokenList: false,
    callJiraConnectionListApi: false,
  },
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload.isLoad;
    },
    setProfileOpen(state, action) {
      state.isProfileOpen = action.payload.isProfileOpen;
    },
    updateJiraCredList(state, action) {
      const jiraCredIndex = state.settingDetails.jiraCredentialList.findIndex(
        (data) => data?.id == action.payload?.id
      );
      if (jiraCredIndex !== -1) {
        let credKey = "isEnabled";
        if (action.payload?.type == PROJECT_CONST.JIRA_CRED_SHARED) {
          credKey = "isShared";
        }
        state.settingDetails.jiraCredentialList[jiraCredIndex][credKey] =
          action.payload?.value;
      }
    },
    setUniqueIdForManageUsers(state, action) {
      state.clickedId = action.payload.clickedId;
    },
    setUniqueId(state, action) {
      state.uniqueId = action.payload.uniqueId;
    },
    setFeedBackIcons(state, action) {
      state.uploadIconStatus = action.payload.uploadIconStatus;
    },
    setSettingsFeedBackData(state, action) {
      state.feedbackDetails = action.payload.feedbackDetails;
    },
    resetCompanyProfileApiCall(state, action) {
      state.callCompanyProfileApi = action.payload?.callCompanyProfileApi;
    },
    setCallUserListApi(state, action) {
      state.userCreateDetails.hasApiLoaded = action.payload?.hasApiLoaded;
    },
    setApplicationToken(state, action) {
      state.applicationToken = action.payload?.applicationToken;
    },
    setJiraList(state, action) {
      state.settingDetails.jiraCredentialList = action.payload?.jiraList;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveSettings.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(saveSettings.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.settingDetails.details = payload?.response?.settings
          ? payload?.response?.settings
          : {};
        if (payload?.response?.type == PROJECT_CONST.FACEBOOK) {
          toasterSuccess(PROJECT_CONST.FACEBOOK_UPDATED_MSG);
        } else if (payload?.response?.type == PROJECT_CONST.LINKED_IN) {
          toasterSuccess(PROJECT_CONST.LINKEDIN_UPDATED_MSG);
        } else if (payload?.response?.type == PROJECT_CONST.TWITTER) {
          toasterSuccess(PROJECT_CONST.TWITTER_UPDATED_MSG);
        }
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.settingDetails.details = {};
        toasterError(payload?.message);
      }
    });
    builder.addCase(saveSettings.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(getSettings.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getSettings.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.settingDetails.details = payload?.response?.socialMediaDetails
          ? payload?.response?.socialMediaDetails
          : {};
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.settingDetails.details = {};
        toasterError(payload?.message);
      }
    });
    builder.addCase(getSettings.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(resetPasswordSettings.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(resetPasswordSettings.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        toasterSuccess(PROJECT_CONST.RESET_PASSWORD_SUCCESS_MSG);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(resetPasswordSettings.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(updateProfileSettings.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(updateProfileSettings.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        let userInfo = getloginInfo();
        const firstName = payload?.response?.details?.firstName
          ? payload?.response?.details?.firstName
          : "";
        const lastName = payload?.response?.details?.lastName
          ? payload?.response?.details?.lastName
          : "";
        userInfo["User"]["name"] = firstName;
        userInfo["User"]["family_name"] = lastName;
        localStorage.setItem("loginInfo", JSON.stringify(userInfo));
        state.updateProfileDetails = {
          ...state.updateProfileDetails,
          hasApiLoaded: true,
        };
        toasterSuccess(PROJECT_CONST.UPDATE_PROFILE_SUCCESS_MSG);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.updateProfileDetails = {};
        toasterError(payload?.message);
      }
    });
    builder.addCase(updateProfileSettings.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(
      getJiraCredentialsSettings.pending,
      (state, { payload }) => {
        state.isLoad = true;
        state.callJiraConnectionListApi = false;
      }
    );
    builder.addCase(
      getJiraCredentialsSettings.fulfilled,
      (state, { payload }) => {
        state.isLoad = false;
        state.hasLevel2Deleted = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          state.settingDetails.jiraCredentialList = payload?.response
            ?.jiraCredentials
            ? payload?.response?.jiraCredentials
            : [];
          state.callJiraConnectionListApi = true;
        } else if (payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION) {
          state.hasLevel2Deleted = true;
        } else if (
          payload?.hasError &&
          payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
          payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
          payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
        ) {
          state.hasLevel2Deleted = true;
          toasterError(payload?.message);
        }
      }
    );
    builder.addCase(
      getJiraCredentialsSettings.rejected,
      (state, { payload }) => {
        state.isLoad = false;
        state.callJiraConnectionListApi = false;
      }
    );
    builder.addCase(marksharedJira.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(marksharedJira.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        toasterSuccess(PROJECT_CONST.UPDATE_JIRA_MARK_SHARED_SUCCESS_MSG);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(marksharedJira.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(statusChangeJira.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callJiraConnectionListApi = false;
    });
    builder.addCase(statusChangeJira.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.callJiraConnectionListApi = true;
        toasterSuccess(PROJECT_CONST.UPDATE_JIRA_STATUS_CHANGE_SUCCESS_MSG);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(statusChangeJira.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callJiraConnectionListApi = false;
    });
    builder.addCase(settingsFeedbackDetails.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(settingsFeedbackDetails.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      state.hasLevel2Deleted = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.feedbackDetails = payload?.response?.data;
      } else if (payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION) {
        state.hasLevel2Deleted = true;
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.hasLevel2Deleted = true;
        toasterError(payload?.message);
      }
    });
    builder.addCase(settingsFeedbackDetails.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(uploadFileSettings.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(uploadFileSettings.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        let userInfo = getloginInfo();
        const profileImage = payload?.response?.profileImage
          ? payload?.response?.profileImage
          : "";
        userInfo["User"]["picture"] = profileImage;
        localStorage.setItem("loginInfo", JSON.stringify(userInfo));
        state.updateProfileDetails = {
          ...state.updateProfileDetails,
          hasApiLoaded: true,
        };
        toasterSuccess(PROJECT_CONST.UPLOAD_SETTINGS_IMAGE_SUCCESS_MSG);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.updateProfileDetails = {};
        toasterError(payload?.message);
      }
    });
    builder.addCase(uploadFileSettings.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(deleteJiraCredSettings.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(deleteJiraCredSettings.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        const index = state.settingDetails.jiraCredentialList.findIndex(
          (x) => x.id === payload?.response?.jiraCredId
        );
        if (index > -1) {
          // only splice array when item is found
          state.settingDetails.jiraCredentialList.splice(index, 1);
        }
        toasterSuccess(PROJECT_CONST.UPDATE_JIRA_CRED_DELETE_SUCCESS_MSG);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.settingDetails.jiraCredentialList = [];
        // toasterError(payload?.message);
      }
    });
    builder.addCase(deleteJiraCredSettings.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(deleteFileSettings.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(deleteFileSettings.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        let userInfo = getloginInfo();
        userInfo["User"]["picture"] = "";
        localStorage.setItem("loginInfo", JSON.stringify(userInfo));
        state.updateProfileDetails = {
          ...state.updateProfileDetails,
          hasApiLoaded: true,
        };

        toasterSuccess(PROJECT_CONST.REMOVE_SETTINGS_IMAGE_SUCCESS_MSG);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.updateProfileDetails = {};
        toasterError(payload?.message);
      }
    });
    builder.addCase(deleteFileSettings.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(settingslevel2UserUpdate.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(
      settingslevel2UserUpdate.fulfilled,
      (state, { payload }) => {
        state.isLoad = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          state.feedbackDetails.allowAnyoneToLeaveFeedback =
            payload?.response.allowAnyoneToLeaveFeedback;
          state.feedbackDetails.allowAnyoneToSubscribe =
            payload?.response.allowAnyoneToSubscribe;
          state.feedbackDetails.markAsReadAfterReplying =
            payload?.response.markAsReadAfterReplying;
        } else if (
          payload?.hasError &&
          payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
          payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
          payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
        ) {
          toasterError(payload?.message);
        }
      }
    );
    builder.addCase(settingslevel2UserUpdate.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(getDnsSecurityType.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getDnsSecurityType.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.dnsSecurityType = payload?.response?.dnsData;
        state.urlForLibraryPage = payload?.response?.publicPageUrl;
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.dnsSecurityType = [];
        state.urlForLibraryPage = "";
        toasterError(payload?.message);
      }
    });
    builder.addCase(getDnsSecurityType.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(settingsUserList.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(settingsUserList.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.userDetails.list = payload?.response?.users
          ? payload?.response?.users
          : [];
        state.userDetails.hasApiLoaded = true;
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.userDetails.list = [];
        toasterError(payload?.message);
      }
    });
    builder.addCase(settingsUserList.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(settingsUserInvite.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(settingsUserInvite.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.userCreateDetails = {
          details: payload?.response?.userData
            ? payload?.response?.userData
            : {},
          hasApiLoaded: true,
          index: payload?.response?.index,
        };
        toasterSuccess(PROJECT_CONST.USER_INVITE_SUCCESS_MSG);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.userCreateDetails = {};
        toasterError(payload?.message);
      } else {
        if (
          payload?.errorCode === ERROR_CODE_CONST.VALIDATION_ERROR &&
          payload?.response &&
          payload?.response?.validation
        ) {
          payload?.response?.validation.map((data) => {
            toasterError(data["message"]);
          });
        }
      }
    });
    builder.addCase(settingsUserInvite.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(settingsUserAdd.pending, (state, { payload }) => {
      state.isLoad = true;
      state.redirectToLoginPage = false;
    });
    builder.addCase(settingsUserAdd.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        toasterSuccess(PROJECT_CONST.USER_CREATE_SUCCESS_MSG);
        state.redirectToLoginPage = true;
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      } else {
        if (
          payload?.errorCode === ERROR_CODE_CONST.VALIDATION_ERROR &&
          payload?.response &&
          payload?.response?.validation
        ) {
          payload?.response?.validation.map((data) => {
            toasterError(data["message"]);
          });
        }
      }
    });
    builder.addCase(settingsUserAdd.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.redirectToLoginPage = false;
    });
    builder.addCase(getUserDetailsByToken.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getUserDetailsByToken.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.userInviteDetails = {
          details: payload?.response?.userDetails
            ? payload?.response?.userDetails
            : {},
          hasApiLoaded: true,
          showInvitePage: true,
        };
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.userInviteDetails = {
          hasApiLoaded: true,
          showInvitePage: false,
        };
        toasterError(payload?.message);
      } else if (
        !payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        (payload?.errorCode === ERROR_CODE_CONST.INVITATION_EMAIL_LINK_EXPIRE ||
          payload?.errorCode === ERROR_CODE_CONST.INVITATION_EMAIL_SUCCESS ||
          payload?.errorCode === ERROR_CODE_CONST.INVITATION_EMAIL_USER_DELETED)
      ) {
        state.userInviteDetails = {
          hasApiLoaded: true,
          showInvitePage: false,
        };
        state.message = payload?.message;
      }
    });
    builder.addCase(getUserDetailsByToken.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(
      settingsAdminProfileUpdate.pending,
      (state, { payload }) => {
        state.isLoad = true;
      }
    );
    builder.addCase(
      settingsAdminProfileUpdate.fulfilled,
      (state, { payload }) => {
        state.isLoad = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          state.userCreateDetails = {
            details: payload?.response?.userDetails
              ? payload?.response?.userDetails
              : {},
            hasApiLoaded: true,
            index: payload?.response?.index,
          };
          state.clickedId = payload?.response?.uniqueId;
          state.uniqueId = 1;
          toasterSuccess(PROJECT_CONST.USER_UPDATE_SUCCESS_MSG);
        } else if (
          payload?.hasError &&
          payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
          payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
          payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
        ) {
          state.userCreateDetails = {};
          toasterError(payload?.message);
        } else {
          if (
            payload?.errorCode === ERROR_CODE_CONST.VALIDATION_ERROR &&
            payload?.response &&
            payload?.response?.validation
          ) {
            payload?.response?.validation.map((data) => {
              toasterError(data["message"]);
            });
          }
        }
      }
    );
    builder.addCase(
      settingsAdminProfileUpdate.rejected,
      (state, { payload }) => {
        state.isLoad = false;
      }
    );
    builder.addCase(deActivateUser.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(deActivateUser.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.userCreateDetails.hasApiLoaded = true;
        state.uniqueId = 1;
        state.clickedId = payload?.response?.uniqueId;
        toasterSuccess(payload?.message);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(deActivateUser.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(resendUserInvitation.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(resendUserInvitation.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        const index = state.userDetails.list.findIndex(
          (x) =>
            Number(x.userId) === Number(payload?.response?.userData?.userId)
        );
        if (index > -1) {
          state.userDetails.list[index] = payload?.response?.userData;
        }
        state.uniqueId = 1;
        state.clickedId = payload?.response?.uniqueId;
        toasterSuccess(PROJECT_CONST.USER_RESEND_MSG);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(resendUserInvitation.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(getBusinessData.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getBusinessData.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.businessData = payload?.response;
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.COMMON_ERROR_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(getBusinessData.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(saveBusinessData.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(saveBusinessData.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.businessData = payload?.response;
        toasterSuccess(PROJECT_CONST.BUSINESS_HIERARCHY_MSG);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.COMMON_ERROR_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(saveBusinessData.rejected, (state, { payload }) => {
      state.isLoad = false;
    });

    builder.addCase(uploadFeedbackIcons.pending, (state, { payload }) => {
      state.isLoad = true;
      state.uploadIconStatus = false;
    });
    builder.addCase(uploadFeedbackIcons.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.uploadIconStatus = true;
        state.feedbackDetails = {
          ...state.feedbackDetails,
          level2Attribute: {
            ...payload?.response?.level2Attribute,
          },
        };
        toasterSuccess(payload?.message);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.COMMON_ERROR_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(uploadFeedbackIcons.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.uploadIconStatus = false;
    });

    builder.addCase(resetToDefaultForIcons.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callSettingsApiForFeedbackIcons = false;
    });
    builder.addCase(resetToDefaultForIcons.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.callSettingsApiForFeedbackIcons = true;
        toasterSuccess(payload?.message);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.COMMON_ERROR_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(resetToDefaultForIcons.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callSettingsApiForFeedbackIcons = false;
    });
    builder.addCase(deleteFeedbackIcon.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callSettingsApiForFeedbackIcons = false;
      state.uploadIconStatus = false;
    });
    builder.addCase(deleteFeedbackIcon.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.uploadIconStatus = true;
        state.feedbackDetails = {
          ...state.feedbackDetails,
          level2Attribute: {
            ...payload?.response?.level2Attribute,
          },
        };
        toasterSuccess(payload?.message);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.COMMON_ERROR_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(deleteFeedbackIcon.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.uploadIconStatus = false;
      state.callSettingsApiForFeedbackIcons = false;
    });

    builder.addCase(saveFeedbackIcons.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callSettingsApiForFeedbackIcons = false;
    });
    builder.addCase(saveFeedbackIcons.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.feedbackDetails = {
          ...state.feedbackDetails,
          level2Attribute: {
            ...payload?.response?.level2Attribute,
          },
        };
        toasterSuccess(payload?.message);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      } else if (
        payload?.hasError &&
        payload?.errorCode === ERROR_CODE_CONST.COMMON_ERROR_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(saveFeedbackIcons.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callSettingsApiForFeedbackIcons = false;
    });
    builder.addCase(getCompanyProfile.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(getCompanyProfile.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.companyProfileDetails = payload?.response?.companyData;
        state.companyProfileLogo = payload?.response?.companyData?.caLogoPath;
        state.isDefaultLogo = payload?.response?.isDefaultLogo;
      }
    });
    builder.addCase(getCompanyProfile.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(
      updateCompanyProfileSettings.pending,
      (state, { payload }) => {
        state.isLoad = true;
      }
    );
    builder.addCase(
      updateCompanyProfileSettings.fulfilled,
      (state, { payload }) => {
        state.isLoad = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          //  state.companyProfileDetails = payload?.response?.companyData
          state.callCompanyProfileApi = true;
          toasterSuccess(PROJECT_CONST.UPDATE_COMPANY_PROFILE_SUCCESS_MSG);
        } else if (
          payload?.hasError &&
          payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
          payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
          payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
        ) {
          state.updateProfileDetails = {};
          toasterError(payload?.message);
        }
      }
    );
    builder.addCase(
      updateCompanyProfileSettings.rejected,
      (state, { payload }) => {
        state.isLoad = false;
      }
    );
    builder.addCase(
      uploadFileForCompanyProfileLogo.pending,
      (state, { payload }) => {
        state.isLoad = true;
      }
    );
    builder.addCase(
      uploadFileForCompanyProfileLogo.fulfilled,
      (state, { payload }) => {
        state.isLoad = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          state.companyProfileLogo = payload?.response?.profileImage;
          state.callCompanyProfileApi = true;
          toasterSuccess(PROJECT_CONST.UPLOAD_SETTINGS_IMAGE_SUCCESS_MSG);
        } else if (
          payload?.hasError &&
          payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
          payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
          payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
        ) {
          state.updateProfileDetails = {};
          toasterError(payload?.message);
        }
      }
    );
    builder.addCase(
      uploadFileForCompanyProfileLogo.rejected,
      (state, { payload }) => {
        state.isLoad = false;
      }
    );

    builder.addCase(deleteCompanyProfileLogo.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(
      deleteCompanyProfileLogo.fulfilled,
      (state, { payload }) => {
        state.isLoad = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          state.companyProfileLogo = payload.response?.profileImage;
          state.callCompanyProfileApi = true;
          toasterSuccess(PROJECT_CONST.REMOVE_SETTINGS_IMAGE_SUCCESS_MSG);
        } else if (
          payload?.hasError &&
          payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
          payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
          payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
        ) {
          state.updateProfileDetails = {};
          toasterError(payload?.message);
        }
      }
    );
    builder.addCase(deleteCompanyProfileLogo.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(getApplicationTokens.pending, (state, { payload }) => {
      state.isLoad = true;
      state.applicationTokenList = [];
    });
    builder.addCase(getApplicationTokens.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.applicationTokenList = payload?.response?.applicationTokens;
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(getApplicationTokens.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.applicationTokenList = [];
    });
    builder.addCase(createApplicationToken.pending, (state, { payload }) => {
      state.isLoad = true;
      state.applicationToken = "";
      state.callApplicationTokenList = false;
    });
    builder.addCase(createApplicationToken.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.applicationToken = payload?.response?.token;
        state.callApplicationTokenList = true;
        toasterSuccess(PROJECT_CONST.APPLICATION_TOKEN_CREATE);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(createApplicationToken.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.applicationToken = "";
      state.callApplicationTokenList = false;
    });
    builder.addCase(deleteApplicationToken.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callApplicationTokenList = false;
    });
    builder.addCase(deleteApplicationToken.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.callApplicationTokenList = true;
        toasterSuccess(PROJECT_CONST.APPLICATION_TOKEN_DELETE);
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(deleteApplicationToken.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callApplicationTokenList = false;
    });
    builder.addCase(
      getJiraConnectionStatus.pending,
      (state, { payload }) => {}
    );
    builder.addCase(getJiraConnectionStatus.fulfilled, (state, { payload }) => {
      if (!payload?.hasError && payload?.errorCode === -1) {
        payload?.response?.jiraCredentials.map((status, i) => {
          let index = state.settingDetails.jiraCredentialList.findIndex(
            (data) => data?.id === status?.id
          );
          if (index !== -1) {
            state.settingDetails.jiraCredentialList[index].status =
              status.status;
            state.settingDetails.jiraCredentialList[index].statusMessage =
              status.statusMessage;
          }
        });
      } else if (
        payload?.hasError &&
        payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR &&
        payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION &&
        payload?.errorCode === ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(
      getJiraConnectionStatus.rejected,
      (state, { payload }) => {}
    );
  },
});

export const settingsActions = SettingsSlice.actions;

export default SettingsSlice;
