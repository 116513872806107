export const timeZoneAbbreviationsMap = {
    'A': ['Alpha Time Zone'],
    'ACST': ['Australian Central Standard Time', 'Australian Central Daylight Time'],
    'ACT': ['Acre Time', 'Australian Central Time'],
    'ACWST': ['Australian Central Western Standard Time'],
    'AEST': ['Australian Eastern Standard Time', 'Australian Eastern Daylight Time'],
    'AFT': ['Afghanistan Time'],
    'AKST': ['Alaska Standard Time', 'Alaska Daylight Time'],
    'ALMT': ['Alma-Ata Time'],
    'AMST': ['Amazon Summer Time'],
    'AMT': ['Amazon Time', 'Armenia Time', 'Armenia Standard Time', 'Amazon Standard Time'],
    'ANAT': ['Anadyr Time'],
    'AQTT': ['Aqtobe Time'],
    'ART': ['Argentina Time', 'Argentina Standard Time'],
    'AST': ['Arabia Standard Time', 'Atlantic Standard Time', 'Arabian Standard Time', 'Atlantic Daylight Time'],
    'AWST': ['Australian Western Standard Time', 'Australian Western Daylight Time'],
    'AZOST': ['Azores Standard Time', 'Azores Summer Time'],
    'AZOT': ['Azores Time'],
    'AZT': ['Azerbaijan Time', 'Azerbaijan Standard Time'],
    'B': ['Bravo Time Zone'],
    'BNT': ['Brunei Darussalam Time'],
    'BOT': ['Bolivia Time'],
    'BRST': ['Brasília Summer Time'],
    'BRT': ['Brasília Time', 'Brasilia Standard Time'],
    'BST': ['Bangladesh Standard Time', 'British Summer Time', 'Bougainville Standard Time'],
    'BTT': ['Bhutan Time'],
    'C': ['Charlie Time Zone'],
    'CAST': ['Casey Time'],
    'CAT': ['Central Africa Time'],
    'CCT': ['Cocos Islands Time'],
    'CDT': ['Cuba Daylight Time'],
    'CEST': ['Central European Summer Time'],
    'CET': ['Central European Time'],
    'CHAST': ['Chatham Standard Time', 'Chatham Daylight Time'],
    'CHOT': ['Choibalsan Standard Time'],
    'CHOST': ['Choibalsan Summer Time'],
    'CHST': ['Chamorro Standard Time'],
    'CHUT': ['Chuuk Time'],
    'CIST': ['Clipperton Island Standard Time'],
    'CIT': ['Central Indonesia Time'],
    'CKT': ['Cook Island Time'],
    'CLST': ['Chile Summer Time'],
    'CLT': ['Chile Standard Time'],
    'COST': ['Colombia Summer Time'],
    'COT': ['Colombia Time', 'Colombia Standard Time'],
    'CST': ['Central Daylight Time','Central Standard Time', 'China Standard Time', 'Cuba Standard Time', 'Taipei Standard Time'],
    'CT': ['Central Time'],
    'CVT': ['Cape Verde Time', 'Cape Verde Standard Time'],
    'CXT': ['Christmas Island Time'],
    'D': ['Delta Time Zone'],
    'DAVT': ['Davis Time'],
    'DDUT': ['Dumont d\'Urville Time'],
    'E': ['Echo Time Zone'],
    'EASST': ['Easter Island Summer Time'],
    'EAST': ['Easter Island Standard Time'],
    'EAT': ['East Africa Time'],
    'ECT': ['Eastern Caribbean Time', 'Ecuador Time'],
    'EEST': ['Eastern European Summer Time', 'Eastern European Standard Time'],
    'EET': ['Eastern European Time'],
    'EGST': ['Eastern Greenland Summer Time'],
    'EGT': ['East Greenland Time'],
    'EST': ['Eastern Standard Time', 'Eastern Daylight Time'],
    'ET': ['Eastern Time'],
    'F': ['Foxtrot Time Zone'],
    'FET': ['Further-eastern European Time'],
    'FJST': ['Fiji Summer Time'],
    'FJT': ['Fiji Time', 'Fiji Standard Time'],
    'FKST': ['Falkland Islands Summer Time'],
    'FKT': ['Falkland Islands Time'],
    'FNT': ['Fernando de Noronha Time'],
    'G': ['Golf Time Zone'],
    'GALT': ['Galápagos Time'],
    'GAMT': ['Gambier Islands Time'],
    'GET': ['Georgia Standard Time'],
    'GFT': ['French Guiana Time'],
    'GILT': ['Gilbert Island Time'],
    'GMT': ['Greenwich Mean Time'],
    'GST': ['Gulf Standard Time', 'South Georgia Time'],
    'GYT': ['Guyana Time'],
    'H': ['Hotel Time Zone'],
    'HAEC': ['Heure Avancée d\'Europe Centrale'],
    'HAST': ['Hawaii-Aleutian Standard Time', 'Hawaii-Aleutian Daylight Time'],
    'HKT': ['Hong Kong Time'],
    'HOVT': ['Hovd Time', 'Hovd Standard Time'],
    'HST': ['Hawaii Standard Time'],
    'I': ['India Time Zone'],
    'ICT': ['Indochina Time'],
    'IDT': ['Israel Daylight Time'],
    'IOT': ['Indian Ocean Time'],
    'IRKT': ['Irkutsk Time', 'Irkutsk Standard Time'],
    'IRST': ['Iran Standard Time', 'Iran Daylight Time'],
    'IST': ['Indian Standard Time', 'Irish Standard Time', 'Israel Standard Time', 'India Standard Time'],
    'JST': ['Japan Standard Time'],
    'K': ['Kilo Time Zone'],
    'KGT': ['Kyrgyzstan Time'],
    'KOST': ['Kosrae Time'],
    'KRAST': ['Krasnoyarsk Summer Time'],
    'KRAT': ['Krasnoyarsk Time', 'Krasnoyarsk Standard Time'],
    'KST': ['Korea Standard Time', 'Korean Standard Time'],
    'KUYT': ['Kuybyshev Time'],
    'L': ['Lima Time Zone'],
    'LHST': ['Lord Howe Standard Time', 'Lord Howe Daylight Time'],
    'LINT': ['Line Islands Time'],
    'M': ['Mike Time Zone'],
    'MAGT': ['Magadan Time', 'Magadan Standard Time'],
    'MART': ['Marquesas Islands Time', 'Marquesas Time'],
    'MAWT': ['Mawson Station Time'],
    'MET': ['Middle European Time'],
    'MEST': ['Middle European Summer Time'],
    'MHT': ['Marshall Islands Time'],
    'MIST': ['Macquarie Island Station Time'],
    'MIT': ['Marquesas Islands Time'],
    'MMT': ['Myanmar Standard Time', 'Myanmar Time'],
    'MSK': ['Moscow Standard Time', 'Volgograd Standard Time'],
    'MST': ['Mountain Standard Time', 'Mexican Pacific Standard Time', 'Yukon Time', 'Mountain Daylight Time'],
    'MT': ['Mountain Time'],
    'MUT': ['Mauritius Time', 'Mauritius Standard Time'],
    'MVT': ['Maldives Time'],
    'MYT': ['Malaysia Time'],
    'N': ['November Time Zone'],
    'NCT': ['New Caledonia Time'],
    'NFT': ['Norfolk Island Time', 'Norfolk Island Standard Time'],
    'NOVST': ['Novosibirsk Summer Time'],
    'NOVT': ['Novosibirsk Time', 'Novosibirsk Standard Time'],
    'NPT': ['Nepal Time'],
    'NRT': ['Nauru Time'],
    'NST': ['Newfoundland Standard Time', 'Newfoundland Daylight Time'],
    'NUT': ['Niue Time'],
    'NZST': ['New Zealand Standard Time', 'New Zealand Daylight Time'],
    'O': ['Oscar Time Zone'],
    'OMSST': ['Omsk Summer Time'],
    'OMST': ['Omsk Standard Time'],
    'ORAT': ['Oral Time'],
    'P': ['Papa Time Zone'],
    'PET': ['Peru Time', 'Philippine Standard Time'],
    'PETT': ['Kamchatka Time', 'Petropavlovsk-Kamchatski Standard Time'],
    'PGT': ['Papua New Guinea Time'],
    'PHOT': ['Phoenix Island Time'],
    'PHT': ['Philippine Time'],
    'PKT': ['Pakistan Standard Time'],
    'PMDT': ['Pierre & Miquelon Daylight Time'],
    'PMST': ['Pierre & Miquelon Standard Time', 'St. Pierre & Miquelon Daylight Time'],
    'PONT': ['Pohnpei Standard Time'],
    'PST': ['Pacific Standard Time', 'Pakistan Standard Time', 'Pacific Daylight Time'],
    'PT': ['Pacific Time'],
    'PWT': ['Palau Time'],
    'PYST': ['Paraguay Summer Time'],
    'PYT': ['Paraguay Time', 'Paraguay Standard Time'],
    'Q': ['Quebec Time Zone'],
    'QYZT': ['Qyzylorda Time'],
    'R': ['Romeo Time Zone'],
    'RET': ['Réunion Time'],
    'ROTT': ['Rothera Research Station Time'],
    'S': ['Sierra Time Zone'],
    'SAKT': ['Sakhalin Island Time', 'Sakhalin Standard Time'],
    'SAMT': ['Samara Time', 'Samara Standard Time'],
    'SAST': ['South African Standard Time', 'Seychelles Time'],
    'SBT': ['Solomon Islands Time'],
    'SDT': ['Samoa Daylight Time'],
    'SGT': ['Singapore Standard Time'],
    'SRET': ['Srednekolymsk Time'],
    'SRT': ['Suriname Time'],
    'SST': ['Samoa Standard Time', 'Singapore Standard Time'],
    'SYOT': ['Showa Station Time'],
    'T': ['Tango Time Zone'],
    'TAHT': ['Tahiti Time'],
    'TFT': ['French Southern and Antarctic Time'],
    'TJT': ['Tajikistan Time'],
    'TKT': ['Tokelau Time'],
    'TLT': ['Timor Leste Time'],
    'TMT': ['Turkmenistan Time'],
    'TOT': ['Tonga Time', 'Tonga Standard Time'],
    'TRT': ['Turkey Time'],
    'TVT': ['Tuvalu Time'],
    'U': ['Uniform Time Zone'],
    'ULAST': ['Ulaanbaatar Summer Time'],
    'ULAT': ['Ulaanbaatar Standard Time'],
    'UTC': ['Coordinated Universal Time'],
    'UYST': ['Uruguay Summer Time'],
    'UYT': ['Uruguay Standard Time'],
    'UZT': ['Uzbekistan Time', 'Uzbekistan Standard Time'],
    'V': ['Victor Time Zone'],
    'VET': ['Venezuelan Standard Time', 'Venezuela Time'],
    'VLAST': ['Vladivostok Summer Time'],
    'VLAT': ['Vladivostok Time', 'Vladivostok Standard Time'],
    'VOST': ['Vostok Station Time'],
    'VUT': ['Vanuatu Time'],
    'W': ['Whiskey Time Zone'],
    'WAKT': ['Wake Island Time'],
    'WAST': ['West Africa Summer Time'],
    'WAT': ['West Africa Time', 'West Africa Standard Time'],
    'WEST': ['Western European Summer Time'],
    'WET': ['Western European Time'],
    'WST': ['Western Sahara Summer Time', 'Western Standard Time', 'Apia Standard Time'],
    'WT': ['Western Sahara Standard Time'],
    'X': ['X-ray Time Zone'],
    'Y': ['Yankee Time Zone'],
    'YAKT': ['Yakutsk Time', 'Yakutsk Standard Time'],
    'YEKST': ['Yekaterinburg Summer Time'],
    'Z': ['Zulu Time Zone'],
    'WKT': ['West Kazakhstan Time'],
    'YEKT': ['Yekaterinburg Standard Time']
};
