import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../utils/constants/apiUrl";
import { STORE_CONST } from "../../utils/constants/store";
import customAxios from "../../utils/lib/customAxios";

const getDlist = createAsyncThunk(
  `${STORE_CONST.DL_NAMESPACE}/getDlist`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.DL_LIST, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);
const saveDlist = createAsyncThunk(
  `${STORE_CONST.DL_NAMESPACE}/saveDlist`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.SAVE_DL_LIST, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);
const updateDlist = createAsyncThunk(
  `${STORE_CONST.DL_NAMESPACE}/updateDlist`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.patch(API_URL.UPDATE_DL_LIST, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);
const deleteDlist = createAsyncThunk(
  `${STORE_CONST.DL_NAMESPACE}/deleteDlist`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.DELETE_DL_LIST, arg);
      return { ...data, length:arg?.length, response: { ...data.response, pdlId: arg.pdlId } };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getDMemberList = createAsyncThunk(
  `${STORE_CONST.DL_NAMESPACE}/getDMemberList`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.DL_MEMBER_LIST, arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);
const saveDMember = createAsyncThunk(
  `${STORE_CONST.DL_NAMESPACE}/saveDMember`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(API_URL.DL_SAVE_MEMBER, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);
const updateDMember = createAsyncThunk(
  `${STORE_CONST.DL_NAMESPACE}/updateDMember`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.patch(API_URL.DL_UPDATE_MEMBER, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const deleteDMember = createAsyncThunk(
  `${STORE_CONST.TAG_NAMESPACE}/deleteDMember`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const targetId = {
        targetId : arg?.targetId
      }
      const { data } = await customAxios.post(API_URL.DL_DELETE_MEMBER, targetId);
      return {
        ...data,
        listLength: arg?.listLength
      }
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const verifyTarget = createAsyncThunk(
  `${STORE_CONST.TAG_NAMESPACE}/verifyTarget`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.patch(API_URL.VERIFY_TARGET, arg);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const resendVerifyTarget = createAsyncThunk(
  `${STORE_CONST.TAG_NAMESPACE}/resendVerifyTarget`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.RESEND_VERIFY_TARGET,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getDistributionListName = createAsyncThunk(
  `${STORE_CONST.DL_NAMESPACE}/getDistributionListName`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.DL_LIST_NAME,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const bulkEmailImport = createAsyncThunk(
  `${STORE_CONST.DL_NAMESPACE}/bulkEmailImport`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.BULK_EMAIL_IMPORT,
        arg?.datas
      );
      return {
        ...data,
        fileFormat: arg?.fileFormat
      };
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export {
  getDlist,
  saveDlist,
  updateDlist,
  deleteDlist,
  getDMemberList,
  saveDMember,
  updateDMember,
  deleteDMember,
  verifyTarget,
  resendVerifyTarget,
  getDistributionListName,
  bulkEmailImport
};
