import React, { useEffect, useState } from "react";
import { STORE_CONST } from "../../utils/constants/store";
import { useDispatch, useSelector } from "react-redux";
import { feedbackActions } from "../../store/FeedbackSlice";
import { PROJECT_CONST } from "../../utils/constants/projectConst";

function ReleaseDate() {
  const dispatch = useDispatch();
  const [activeMonth, setActiveMonth] = useState("");

  const monthsData = useSelector(
    (state) =>
      state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedbackLibraryDetails?.monthsData
  );

  useEffect(() => {
    if(!activeMonth && monthsData.length) {
      setActiveMonth(monthsData[0]?.month);
    }
  }, [monthsData]);
  return (
    <>
      <div className="right-container release-library-right-container">
        <div className="release-library-date-container">
          <h3>Release Date</h3>
          <div className="release-date-timeline">
            {monthsData.length > 0 &&
              monthsData.map((month, index) => (
                <div
                  key={index}
                  className={
                    "release-date-item flex " +
                    (activeMonth && activeMonth == month?.month
                      ? "current"
                      : "")
                  }
                  onClick={() => {
                    setActiveMonth(month?.month);
                    dispatch(
                      feedbackActions.setFilter({
                        monthYear: month?.month + "-" + month?.year,
                        page: PROJECT_CONST.PAGINATION_DEFAULT_PAGE,
                      })
                    );
                  }}
                >
                  <div className="line">
                    <div className="round"></div>
                    <div className="history-line"></div>
                  </div>
                  <div className="release-month-wrapper">
                    <span className="release-month">
                      {month?.month} {month?.year}
                    </span>
                    <span className="release-num">
                      ({month?.numberOdRelease} releases)
                    </span>
                  </div>
                </div>
              ))}
            {!monthsData.length && <span>No Data Found</span>}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReleaseDate;
