import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";
import {
  deleteDlist,
  getDMemberList,
  getDlist,
  saveDMember,
  saveDlist,
  updateDMember,
  updateDlist,
  deleteDMember,
  verifyTarget,
  resendVerifyTarget,
  getDistributionListName,
  bulkEmailImport,
} from "./api/DlApi";
import { setLocalStorage, toasterError, toasterSuccess } from "../utils/helper/commonHelper";
import { PROJECT_CONST } from "../utils/constants/projectConst";
import { ERROR_CODE_CONST } from "../utils/constants/errorCode";
import {
  checkSecurityType,
  pageAuthorization,
  saveDnsSetings,
} from "./api/DnsSettingsApi";

const DnsSettingsSlice = createSlice({
  name: STORE_CONST.DNS_SETTINGS_NAME_SPACE,
  initialState: {
    isLoad: false,
    securityTypeDetails: {
      hasApiLoaded: false,
      details: {},
    },
    authorisationDetails: {
      hasApiLoaded: false,
      details: {},
    },
    // showReleaseLibrary: false,
    jwtToken: "",
    callSettingsApi: false,
  },
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload.isLoad;
    },
    setHideUi(state, action) {
      state.hideUi = action.payload.hideUi;
    },
    setJwtToken(state, action) {
      state.jwtToken = action.payload.jwtToken
    }
  },
  extraReducers: (builder) => {
    builder.addCase(saveDnsSetings.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callSettingsApi = false;
    });
    builder.addCase(saveDnsSetings.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.jwtToken = payload?.response?.token;
        state.callSettingsApi = true;
        toasterSuccess(PROJECT_CONST.DNS_SETTINGS_MSG);
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.jwtToken = "";
        toasterError(payload?.message);
      }
    });
    builder.addCase(saveDnsSetings.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callSettingsApi = false;
    });

    builder.addCase(checkSecurityType.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(checkSecurityType.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.securityTypeDetails = {
          ...state.securityTypeDetails,
          hasApiLoaded: true,
          details: payload?.response,
        };
      } else if (payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
        && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode ===
        ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
      ) {
        state.securityTypeDetails = {
          hasApiLoaded: false,
          details: {}
        };
        toasterError(payload?.message);
      }
    });
    builder.addCase(checkSecurityType.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(pageAuthorization.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(pageAuthorization.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        state.authorisationDetails = {
          ...state.authorisationDetails,
          hasApiLoaded: true,
          details: payload?.response,
        };
      } else if (payload?.hasError && payload?.errorCode === 1027) {
        toasterError(payload?.message);
      }
    });
    builder.addCase(pageAuthorization.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
  },
});

export const dnsSettingsActions = DnsSettingsSlice.actions;

export default DnsSettingsSlice;
