import React, { useEffect, useRef, useState } from "react";
import "./LibraryFeedBack.css";
import "./PublicLibrary.css";
import "../../components/dashboard/ReleaseTable.css";
import ReleaseList from "../../components/library/ReleaseList";
import { useDispatch, useSelector } from "react-redux";
import {
  getFeedBackDetails,
  getFeedBackDetailsWithReleaseId,
  getFeedbackRelease,
} from "../../store/api/FeedbackApi";

import ReleaseDate from "../../components/library/ReleaseDate";
import { PROJECT_CONST } from "../../utils/constants/projectConst";
import { STORE_CONST } from "../../utils/constants/store";
import FeedBackSubmit from "../../components/feedback/FeedBackSubmit";
import Tags from "../../components/feedback/Tag";
import { feedbackActions } from "../../store/FeedbackSlice";
import {
  checkSecurityType,
  pageAuthorization,
} from "../../store/api/DnsSettingsApi";
import Unauthorization from "../unauthorization/Unauthorization";
import {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  handleCapture,
} from "../../utils/helper/commonHelper";
import { useNavigate } from "react-router-dom";
import customHistory from "../../utils/lib/history";
import LibraryHeader from "../../components/library/LibraryHeader";
import LibraryFeedBackSubmit from "../../components/feedback/LibraryFeedBackSubmit";
import ProvideFeedBack from "../../components/feedback/ProvideFeedBack";
import ReleaseTitle from "../../components/library/ReleaseTitle";
import SubscribeToUpdatePopup from "../../components/common/SubscribeToUpdatePopup";
import { SidebarActions } from "../../store/SidebarSlice";

function LibraryFeedBack() {
  const totalRef = useRef(null);
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const [pageNotFound, setPageNotFound] = useState(false);
  const [authorizationFailed, setAuthorizationFailed] = useState(false);
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [preferencePopUp, setSubscriptionPreferencePopUp] = useState(false);
  const jwtToken = queryParameters.get("jwt-token")
    ? decodeURIComponent(queryParameters.get("jwt-token"))
    : "";
  const dnsName = queryParameters.get("dns-name")
    ? decodeURIComponent(queryParameters.get("dns-name"))
    : "";
  const encryptedReleaseId = queryParameters.get("encryptedId")
    ? queryParameters.get("encryptedId")
    : null;
  const showFeedbackPage = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.showFeedbackPage
  );

  const token = queryParameters.get("token")
    ? decodeURIComponent(queryParameters.get("token"))
    : null;
  const feedBackDetails = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.feedBackDetails
  );
  const securityTypeDetails = useSelector(
    (state) => state[STORE_CONST.DNS_SETTINGS_NAME_SPACE]?.securityTypeDetails
  );
  const authorisationDetails = useSelector(
    (state) => state[STORE_CONST.DNS_SETTINGS_NAME_SPACE]?.authorisationDetails
  );
  const redirect = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.redirect
  );
  const level2ArchiveStatus = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.level2ArchiveStatus
  );
  const openOtpScreen = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.openOtpScreen
  );
  const otpType = useSelector(
    (state) => state[STORE_CONST.FEEDBACK_NAMESPACE]?.otpType
  );
  const captureImage = useSelector(
    (state) => state[STORE_CONST.SIDEBAR_NAMESPACE]?.captureWindow
  );

  useEffect(() => {
    if (authorisationDetails && authorisationDetails?.hasApiLoaded) {
      if (
        (authorisationDetails?.details?.authenticationType ==
          PROJECT_CONST.SECURITY_TYPE_JWT &&
          !authorisationDetails?.details?.token) ||
        authorisationDetails?.details?.token
      ) {
        if (authorisationDetails?.details?.hasAuthorisation) {
          dispatch(
            feedbackActions.setShowFeedBackPage({
              showFeedbackPage: true,
            })
          );
          if (
            encryptedReleaseId ||
            (jwtToken && dnsName && encryptedReleaseId)
          ) {
            getReleaseWithId();
          } else if (!level2ArchiveStatus) {
            getFeedBackWithReleaseKey();
          }
        } else {
          setAuthorizationFailed(true);
          dispatch(
            feedbackActions.setShowFeedBackPage({
              showFeedbackPage: false,
            })
          );
        }
      } else {
        setPageNotFound(true);
      }
    }
  }, [authorisationDetails]);

  useEffect(() => {
    if (
      feedBackDetails?.pStatus &&
      securityTypeDetails?.details?.sType != PROJECT_CONST.SECURITY_TYPE_JWT
    ) {
      setPageNotFound(true);
      dispatch(
        feedbackActions.setShowFeedBackPage({
          showFeedbackPage: false,
        })
      );
    } else {
      setPageNotFound(false);
      dispatch(
        feedbackActions.setShowFeedBackPage({
          showFeedbackPage: true,
        })
      );
      if(Object.keys(feedBackDetails).length > 0) {
        const showSubscriptionUpdatePopUp = queryParameters.get("subscribe-to-updates")
        ? queryParameters.get("subscribe-to-updates")
        : null;
        console.log(showSubscriptionUpdatePopUp, 'showSubscriptionUpdatePopUp')
        console.log(preferencePopUp, 'preferencePopUp')
        if(showSubscriptionUpdatePopUp === 'true' && preferencePopUp) {
          setShowSubscribePopup(true);
          showOtpScreen(PROJECT_CONST.OTP_TYPE_SUBSCRIBE);
          setSubscriptionPreferencePopUp(false);
        }
      }
    }
  }, [feedBackDetails]);

  useEffect(() => {
    const onPageLoad = () => {
      setSubscriptionPreferencePopUp(true)
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);

      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  useEffect(() => {
    if (securityTypeDetails?.hasApiLoaded) {
      if (
        securityTypeDetails?.details?.sType == PROJECT_CONST.SECURITY_TYPE_JWT
      ) {
        getPageAuthorisation();
      } else {
        if (encryptedReleaseId || (jwtToken && dnsName && encryptedReleaseId)) {
          getReleaseWithId();
        } else if (!level2ArchiveStatus) {
          getFeedBackWithReleaseKey();
        }
      }
      setLocalStorage("securityType", securityTypeDetails?.details?.sType);
      removeLocalStorage("activeLevelTwoId");
    }
  }, [securityTypeDetails]);

  /**
   * function to redirect with token
   */
  useEffect(() => {
    if (redirect != "") {
      if (encryptedReleaseId || (jwtToken && dnsName && encryptedReleaseId)) {
        getReleaseWithId(true);
      } else if (!level2ArchiveStatus) {
        customHistory.replace(redirect);
        getFeedBackWithReleaseKey(true);
      }
    }
  }, [redirect]);

  const getPageAuthorisation = () => {
    dispatch(
      pageAuthorization({
        param: { 
          dnsName: dnsName, 
          token: token
        },
        header: jwtToken ? "Bearer " + jwtToken : "",
      })
    );
  };

  useEffect(() =>{
    securityType();
  }, [])
  
  const securityType = () => {
    dispatch(
      checkSecurityType({
        param: { 
          dnsName: dnsName,
          token: token
        },
        header: {
          dnsName: dnsName
        },
      })
    );
  }

  const getReleaseWithId = (afterSubmit = false) => {
    const securityType = getLocalStorage("securityType")
      ? getLocalStorage("securityType")
      : "";
    let param = {};
    if (afterSubmit) {
      param = {
        email: feedBackDetails?.email,
        encryptedString: encryptedReleaseId,
        securityType: securityTypeDetails?.details?.sType
          ? securityTypeDetails?.details?.sType
          : securityType,
      };
    } else {
      param = {
        encryptedString: encryptedReleaseId,
        securityType: securityTypeDetails?.details?.sType
          ? securityTypeDetails?.details?.sType
          : securityType,
      };
    }
    dispatch(
      getFeedBackDetailsWithReleaseId({
        header: {
          token: authorisationDetails?.details?.token ? authorisationDetails?.details?.token : "",
          dnsName: dnsName,
          isLibraryPage: 'false',
          level2Id: securityTypeDetails?.details?.level2Id 
        },
        param: param,
      })
    );
  };
  const getFeedBackWithReleaseKey = (afterSubmit = false) => {
    if (token != null) {
      let param = {};
      if (afterSubmit) {
        param = {
          email: feedBackDetails?.email,
          releaseParentId: feedBackDetails.release.releaseParentId,
        };
      } else {
        param = {
          token: token,
          email: feedBackDetails?.email,
          releaseParentId: feedBackDetails?.release?.releaseParentId,
        };
      }
      dispatch(
        getFeedBackDetails({
          header: {
            token: authorisationDetails?.details?.token ? authorisationDetails?.details?.token : "",
            dnsName: dnsName,
            isLibraryPage: 'false',
            level2Id: securityTypeDetails?.details?.level2Id 
          },
          param: param,
        })
      );
    }
  };

  const handleSubscribe = () => {
    setShowSubscribePopup(true);
    showOtpScreen(PROJECT_CONST.OTP_TYPE_SUBSCRIBE);
  };

  const showOtpScreen = (type = 0) => {
    dispatch(
      feedbackActions.setOtpType({
        otpType: type,
      })
    );
  };

  const closeSubscribePopup = () => {
    const search = document.getElementsByName("search-sub-tag");
    search[0].value = "";
    setLocalStorage("searchDataForSubscriptionTagsList", "");
    // dispatch(
    //     feedbackActions.setSubscriberSendAll({
    //       subscriberSendAll: false,
    //     })
    //   );
    //   removeLocalStorage("selectedTags");
    //   removeLocalStorage("email")
    setShowSubscribePopup(false);
    // dispatch(
    //   feedbackActions.removeSubscriberTags({
    //     removeTags: [],
    //   })
    // )
  };

  useEffect(() => {
    if (openOtpScreen) {
      setShowSubscribePopup(false);
      dispatch(
        feedbackActions.setOtpType({
          otpType: otpType,
        })
      );
    }
  }, [openOtpScreen]);

  useEffect(() => {
    const capture = async () => {
      if (captureImage) {
        if (totalRef.current) {
          const imgData = await handleCapture(totalRef.current, true);
          if (imgData) {
            dispatch(
              SidebarActions.setWindowCapturePopUp({
                triggerCapturePopUp: imgData,
              })
            );
          }
        }
      }
    };
    capture();
  }, [captureImage]);
  return (
    <div ref={totalRef}>
      {showSubscribePopup && (
        <SubscribeToUpdatePopup
          level2Id={feedBackDetails?.level2Id}
          closeSubscribePopup={closeSubscribePopup}
        />
      )}
      {showFeedbackPage && (
        <div className="release-page-main-wrapper">
          <LibraryHeader handleSubscribe={handleSubscribe} />
          <ReleaseTitle />
          <LibraryFeedBackSubmit showSubscribePopup={showSubscribePopup} />
          {/* <ProvideFeedBack/> */}
        </div>
      )}
      {pageNotFound && <Unauthorization />}
      {authorizationFailed && (
        <Unauthorization message="Invalid Token" errorCode="401" />
      )}
    </div>
  );
}

export default LibraryFeedBack;
