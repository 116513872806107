import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../utils/constants/apiUrl";
import { STORE_CONST } from "../../utils/constants/store";
import customAxios from "../../utils/lib/customAxios";

const getAppDetails = createAsyncThunk(
  `${STORE_CONST.COMMON_NAMESPACE}/getAppDetails`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.get(API_URL.APP_DETAILS);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const checkReleaseDeleted = createAsyncThunk(
  `${STORE_CONST.COMMON_NAMESPACE}/checkReleaseDeleted`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.CHECK_RELEASE_DELETED,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const reportAsSpam = createAsyncThunk(
  `${STORE_CONST.COMMON_NAMESPACE}/reportAsSpam`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.post(
        API_URL.REPORT_AS_SPAM,
        arg
      );
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

const getReasonListForSpam = createAsyncThunk(
  `${STORE_CONST.COMMON_NAMESPACE}/getReasonListForSpam`,
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await customAxios.get(
        API_URL.GET_SPAM_REASON_LIST,);
      return data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);
export { getAppDetails, checkReleaseDeleted, reportAsSpam, getReasonListForSpam };
