import React from "react";
import "./LoadingSpinner.css";
import PropagateLoader from "react-spinners/PropagateLoader";

export default function LoadingSpinner() {
  return (
    <div className="parentDisable" width="100%">
      <div className="overlay-box">
        <PropagateLoader size={25} color={"white"} loading={true} />
      </div>
    </div>
  );
}
