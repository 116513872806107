import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { closeGrey, publish } from "../../utils/imports/images";

function FeedBack({ onClick, cancelPopUp }) {
    const [descriptionLength, setDescriptionLength] = useState(0);

  /**
   * declare use form
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
  } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  /**
   * function for on submit form data
   */
  const onSubmit = () => {
    onClick({
      notes: getValues('description')
    })
  };

  return (
    <React.Fragment>
      <div className="popup-overlay"></div>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="popup-min reply-popup application-feedback-popup">
        <div className="popup-min-top">
          <div className="popup-min-top-header flex">
            <h2>Application Feedback</h2>
          </div>
          <div className="tags-wrapper textarea-form-wrapper">
            <p>
            A screenshot of the application window only has been taken and will be sent to the Simple Release Notes team upon submission below
            </p>
              <textarea
              className="application-feedback-popup-text-area"
              maxLength={1000}
                {...register("description", { required: true })}
                onInput={(e)=>{
                    if(e.target.value.length >= 1001) { 
                        return
                    }
                    setDescriptionLength(e.target.value.length)
                }}
              ></textarea>
              <div className="count-label">
                <div>
                {errors.description && (
                  <span className="error-message">Description is required</span>
                )}
                </div>
                <label>{descriptionLength}/1000</label>
              </div>
          </div>
        </div>
        <div className="popup-min-bottom flex popup-min-bottom-tmargin">
          <div className="popup-min-bottom-right flex">
            <button
              className="close-button-no-icons close"
              onClick={cancelPopUp}
            >
              Close
            </button>
            <button className="blue-button save" type="submit">
              <img src={publish} width={15} />
              Submit
            </button>
          </div>
        </div>
      </div>
      </form>
    </React.Fragment>
  );
}

export default FeedBack;
