import React, { useEffect, useRef, useState } from "react";
import { STORE_CONST } from "../../utils/constants/store";
import { useDispatch, useSelector } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import { useForm } from "react-hook-form";
import { deleteImg, createNew } from "../../utils/imports/images";
import AddTags from "./AddTags";
import { deleteTags, getTagList, saveTag } from "../../store/api/TagApi";
import { tagActions } from "../../store/TagSlice";
import { SketchPicker } from "react-color";
import Confirmation from "../common/Confirmation";
import LoadingSpinner from "../common/LoadingSpinner";
import {
  Debounce,
  generateRandomColor,
  getArchiveStatus,
  getLocalStorage,
  setLocalStorage,
  toasterError,
} from "../../utils/helper/commonHelper";
import { isNotEmpty } from "../../utils/helper/commonHelper";
import Pagination from "../common/pagination/Pagination";
import { PROJECT_CONST } from "../../utils/constants/projectConst";

function TagList({ productId }) {
  const dispatch = useDispatch();
  const outsideActionRef = useRef(null);
  const textActionRef = useRef(null);
  const selectRef = useRef(null);
  const selectAllCheckBoxRef = useRef(null);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedTagColors, setSelectedTagColors] = useState([]);
  const [showAddTagList, setAddTagPopUp] = useState(false);
  const [actionPos, setActionPos] = useState({});
  const [color, setColors] = useState({});
  const [tDetails, setTagDetails] = useState();
  const [previousId, setPreviousId] = useState(0);
  const [randomColors, setRandomColors] = useState([]);
  const [isShowConfirmation, setIsShowConfirmation] = useState(false);
  const [sort, setSort] = useState({});
  const [includedColors, setIncludedColors] = useState([
    "#e8c15e",
    "#000000",
    "#e2375e",
    "#32aaaa",
  ]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectUnselect, setSelectUnselect] = useState([]);
  const [sortTypeForTagName, setSortTypeForTagName] = useState({});

  const tagDetails = useSelector(
    (state) => state[STORE_CONST.TAG_NAMESPACE]?.tagDetails.tagList
  );
  const open = useSelector((state) => state[STORE_CONST.TAG_NAMESPACE]?.open);
  const deletedTagDetails = useSelector(
    (state) => state[STORE_CONST.TAG_NAMESPACE]?.deletedTagDetails
  );
  const isLoad = useSelector(
    (state) => state[STORE_CONST.TAG_NAMESPACE]?.isLoad
  );
  const callTagListApiAfterSuccess = useSelector(
    (state) => state[STORE_CONST.TAG_NAMESPACE]?.callTagListApi
  );
  const clickedTagId = useSelector(
    (state) => state[STORE_CONST.TAG_NAMESPACE]?.clickedTagId
  );
  const archiveStatus = useSelector(
    (state) => state[STORE_CONST.SIDEBAR_NAMESPACE]?.archiveStatus
  );
  const activeLevelOneId = useSelector(
    (state) => state[STORE_CONST.SIDEBAR_NAMESPACE]?.activeLevelOneId
  );
  const paginationArr = useSelector(
    (state) =>
      state[STORE_CONST.TAG_NAMESPACE]?.tagDetails
        ?.paginationArr
  );
  const page = useSelector(
    (state) => state[STORE_CONST.TAG_NAMESPACE]?.tagDetails.page
  );
  const noData = useSelector(
    (state) => state[STORE_CONST.TAG_NAMESPACE]?.noData
  );
  const levelMenuDetails = useSelector(
    (state) => state[STORE_CONST.SIDEBAR_NAMESPACE]?.levelMenuDetails
  );
  const {
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    setError,
  } = useForm({
    mode: "all",
    defaultValues: {},
  });

  /**
   * function to call tag list api function after success
   */
  useEffect(() => {
    if (callTagListApiAfterSuccess) {
      cancelResponse();
      tagListCall();
      dispatch(
        tagActions.setCallTagList({
          callTagListApi: false,
        })
      );
    }
  }, [callTagListApiAfterSuccess]);

  /**
   * call tag list api function on inital load
   */
  useEffect(() => {
    if (!callTagListApiAfterSuccess) {
      tagListCall();
    }
  }, [activeLevelOneId]);

  /**
   * function to call tag list api
   */
  const tagListCall = () => {
    let data = {};
    data = {
      sort: sort,
      searchQuery: getLocalStorage("searchDataForTagsList") ? String(getLocalStorage("searchDataForTagsList")) : "",
      limit: PROJECT_CONST.PAGINATION_DEFAULT_LIMIT,
      page: page ? page : PROJECT_CONST.PAGINATION_DEFAULT_PAGE,
      id: Number(getLocalStorage("activeLevelTwoId")),
    };
    dispatch(
      getTagList(data)
    );
  };

  /**
   * for soting purpose
   */
  useEffect(() => {
    if (Object.keys(sort).length) {
      tagListCall();
    }
  }, [sort])

  /**
   * function to close add tag popup
   */
  const cancelResponse = () => {
    setAddTagPopUp(false);
  };

  /** function to show drop down */
  const showAction = (e, tagColor) => {
    const elem = e.target;
    const rect = elem.getBoundingClientRect();
    setActionPos({ top: rect.top - 10, left: rect.left - 45 });
    generateRandomColors();
    if (!includedColors.includes(tagColor)) {
      setColors(tagColor);
    }
  };

  /**
   * setting random colors
   */
  const generateRandomColors = () => {
    const newRandomColors = [];
    while (newRandomColors.length < 4) {
      const randomColor = generateRandomColor();
      if (
        !selectedTagColors.includes(randomColor) &&
        !newRandomColors.includes(randomColor)
      ) {
        newRandomColors.push(randomColor);
      }
    }
    setRandomColors(newRandomColors);
  };

  /**
   * function to open text field when click on text 2 times
   * @param {*} id 
   * @param {*} value 
   */
  const doubleClickHandler = (id, value) => {
    resetPrevRow();
    var element = document.getElementById("edit-text_" + id);
    var element1 = document.getElementById("tag_name_" + id);
    if (element.classList.contains("edit-text")) {
      element.classList.add("edit-text-visible");
      document.getElementById("name_text_" + id).focus();
      setValue(`tagName[${id}].text`, value);
    }
    if (element1.classList.contains("tag-name")) {
      element1.classList.add("tag-span");
    }
  };

  /**
   * function to reset the row
   */
  const resetPrevRow = () => {
    var element = document.getElementById("edit-text_" + previousId);
    var element1 = document.getElementById("tag_name_" + previousId);
    if (element.classList.contains("edit-text")) {
      element.classList.remove("edit-text-visible");
    }
    if (element1.classList.contains("tag-name")) {
      element1.classList.remove("tag-span");
    }
  };

  /**
   * function triggers when key down 
   * @param {*} id 
   * @param {*} e 
   * @param {*} tags 
   */
  const keyDown = (id, e, tags) => {
    if (e.key === "Enter") {
      if (
        watch(`tagName[${id}].text`) != "" &&
        isNotEmpty(watch(`tagName[${id}].text`))
      ) {
        var element = document.getElementById("edit-text_" + id);
        var element1 = document.getElementById("tag_name_" + id);
        if (element.classList.contains("edit-text")) {
          element.classList.remove("edit-text-visible");
        }
        if (element1.classList.contains("tag-name")) {
          element1.classList.remove("tag-span");
        }
        dispatch(
          tagActions.setIsLoad({
            isLoad: true,
          })
        );
        dispatch(
          saveTag({
            data: [
              {
                name: watch(`tagName[${id}].text`),
                tagColour: tags.tagColour,
                level2Id: Number(getLocalStorage("activeLevelTwoId")),
                tagId: tags.tagId,
              },
            ],
          })
        );
      } else {
        setError(`tagName[${id}].text`);
      }
    }
  };

  /**
   * to set selected colors and search datas
   */
  useEffect(() => {
    tagDetails.map((list, key) => {
      setSelectedTagColors((prev) => [...prev, list.tagColour]);
    });
    if (getLocalStorage("searchDataForTagsList") != null) {
      setValue("search", getLocalStorage("searchDataForTagsList"));
    }
  }, [tagDetails]);

  /** for setting color when drop down selects */
  const setColor = (color) => {
    if (color) {
      saveTagDetails(color);
    }
  };

  /** function to hide dropdown menu when click outside */
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        outsideActionRef.current &&
        !outsideActionRef.current.contains(event.target)
      ) {
        setActionPos({});
        dispatch(
          tagActions.setColorPickerOpen({
            open: false,
          })
        );
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [outsideActionRef]);

  useEffect(() => {
    if (Object.keys(deletedTagDetails).length) {
      setIsShowConfirmation(false);
    }
  }, [deletedTagDetails]);

  /** for setting when color picker selects */
  useEffect(() => {
    if (color.hasOwnProperty("hex") && color.hex != "") {
      saveTagDetails(color.hex);
    }
  }, [color]);

  /**
   * function to save tag 
   * @param {*} color 
   */
  const saveTagDetails = (color) => {
    if (tDetails) {
      dispatch(
        tagActions.setIsLoad({
          isLoad: true,
        })
      );
      dispatch(
        saveTag({
          data: [
            {
              name: tDetails.name,
              tagColour: color,
              level2Id: Number(getLocalStorage("activeLevelTwoId")),
              tagId: tDetails.tagId,
            },
          ],
        })
      );
    }
  };

  /** confirmation from delete pop up  */
  const confirmationesponseAction = (res) => {
    if (res.hasOwnProperty("isSubmit") && res.isSubmit && selectUnselect.length) {
      dispatch(
        deleteTags({
          id: selectUnselect,
          length: selectUnselect.length
        })
      );
      dispatch(
        tagActions.setFilterForTagList({
          page: PROJECT_CONST.PAGINATION_DEFAULT_PAGE,
        })
      );
      setSelectUnselect([]);
      selectAllCheckBoxRef.current.checked = false;
      selectRef.current.value = 0;
    } else {
      setIsShowConfirmation(false);
      setSelectUnselect([]);
      selectAllCheckBoxRef.current.checked = false;
      selectRef.current.value = 0;
    }
  };

  /**
   * function on outside click
   */
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [previousId]);

  /**
   * handle click outside function
   * @param {*} event 
   */
  const handleClickOutside = (event) => {
    if (
      textActionRef.current &&
      !textActionRef.current.contains(event.target)
    ) {
      resetPrevRow();
    }
  };

  /**
   * function trigger when page clicked
   * @param {*} page 
   */
  const responseData = (page) => {
    dispatch(tagActions.setFilterForTagList({ page: page?.currentPage }));
  };

  useEffect(() => {
    if (page != 0) {
      setSelectUnselect([]);
      selectAllCheckBoxRef.current.checked = false;
      tagListCall();
    }
  }, [page]);

  const search = () => {
    dispatch(
      tagActions.setFilterForTagList({
        page: PROJECT_CONST.PAGINATION_DEFAULT_PAGE,
      })
    );
    tagListCall();
    setSelectUnselect([]);
    selectAllCheckBoxRef.current.checked = false;
  }
  const debouncedOnChange = Debounce(search, 800);

  /**
   * function to make selection when select all checkbox clicks
   * @param {*} event
   */
  const selectAll = (event) => {
    if (event.target.checked != undefined) {
      if (event.target.checked) {
        setSelectUnselect(
          tagDetails.map((tag) => {
            return tag.tagId;
          })
        );
      } else {
        setSelectUnselect([]);
      }
    }
  };

  /**
   * function trigger when check box in list clicks
   * @param {*} e
   * @param {*} list
   */
  const handleCheckboxChange = (e, list) => {
    let selectList = [...selectUnselect];
    let selectIndex = selectList.findIndex(
      (data) => data === list?.tagId
    );
    if (e.target.checked) {
      if (selectIndex == -1) {
        setSelectUnselect((prev) => [...prev, list?.tagId]);
      }
    } else {
      selectList.splice(selectIndex, 1);
      setSelectUnselect(() => [...selectList]);
    }
  };

  /**
   * function trigger when select 'bulk delete option'
   * @param {*} value 
   */
  const makeActions = () => {
    if (selectUnselect.length) {
      setIsShowConfirmation(true);
    } else {
      toasterError("Please Select Tags");
      selectRef.current.value = 0;
    }
  }

  /**
   * function to handle sorting
   */
  const handleSortToggle = () => {
    try {
      setSortTypeForTagName(prevState => ({
        sortBy: 'tagName',
        sortType: prevState.sortType === 'asc' ? 'desc' : 'asc'
      }));
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (Object.keys(sortTypeForTagName).length) {
      setSort(sortTypeForTagName);
    }

  }, [sortTypeForTagName]);

  useEffect(() => {
    window.addEventListener("beforeunload", removeSortData);
    return () => {
      window.removeEventListener("beforeunload", removeSortData);
    };
  }, []);

  const removeSortData = () => {
    setLocalStorage("sortForTag", '')
  };

  useEffect(() => {
    if(selectUnselect.length > 0) {
      if(selectUnselect.length === tagDetails.length) {
        selectAllCheckBoxRef.current.checked = true;
      }else{
        selectAllCheckBoxRef.current.checked = false;
      }
    }else{
      selectAllCheckBoxRef.current.checked = false;
    }
  }, [selectUnselect])

  return (
    <React.Fragment>
      <div className="middle-container">
        <div className="middle-container-top flex hidden-sm bg-grey-new level-headings-main">
          <div className="middle-container-level-top-headings">
          <div className="flex middle-container-header-left">
            <h1>Tags</h1>
          </div>
          <div className="nav-buttons flex">
            <div className="searchWrapper tags-searchwrapper">
              <input
                id="input"
                type="search"
                {...register(`search`, {})}
                placeholder="Search"
                onChange={(e) => { debouncedOnChange(); setLocalStorage("searchDataForTagsList", e.target.value) }}
              />
            </div>
          </div>
          </div>
          <div className="level-headings">
          <span className="levelone-name">{levelMenuDetails?.level1Name}</span>
          {levelMenuDetails?.level1Name && levelMenuDetails?.level2Name && <span className="level-name-seperator">|</span>}
          <span className="leveltwo-name">{levelMenuDetails?.level2Name}</span>
        </div>
        </div>
        <div className="middle-container-bottom flex bg-grey-new feedback-middle-container">
          <div className="bg-white tagslist-main-wrapper">
            <div className="dashboard-table-headings flex tags-heading">
              <div className="tags-heading-left">
                <div className="form-group form-checkbox-group">
                  <label className="form-checkbox" onClick={(e) => selectAll(e)}>
                    <input
                      type="checkbox"
                      name="check_remember"
                      ref={selectAllCheckBoxRef}
                    />
                    <span className="checkmark"></span>
                   
                  </label>
                  <span className="check-text">Select All</span>
                </div>
                <div className="taglist-select-action">
                  <select ref={selectRef} onChange={(e) => {
                    makeActions();
                  }}>
                    <option value={0}>Select Action</option>
                    <option value={1}>Delete</option>
                  </select>
                </div>
              </div>
              <button
                onClick={() => {
                  // if (getArchiveStatus(archiveStatus)) {
                  //   return;
                  // }
                  setAddTagPopUp(true);
                }}
                className={`blue-button create-new`}
              >
                <img width={20} src={createNew} /> Create New
              </button>
            </div>
            <div className="feedback-listing-wrapper dashboard-tables-wrapper tags-listing-table pagination-table">
              <Scrollbars
                autoHeight={true}
                autoHeightMax={`calc(100vh - 228px)`}
                autoHide={true}
              >
                <table>
                  <thead>
                    <tr>
                      <th className="text-left">
                        <div className="th-headings flex">
                          <span className="th-headings-text">Tag Name</span>
                          <span className={`th-down ${sortTypeForTagName.sortType === 'asc' ? 'active sorted' : ''} ${sortTypeForTagName.sortType === 'desc' ? 'active' : ''}`}
                            onClick={() => {
                              handleSortToggle()
                              if (sortTypeForTagName.sortType === 'desc') {
                                setSortTypeForTagName({
                                  sortBy: 'tagName',
                                  sortType: 'asc'
                                });
                              } else {
                                setSortTypeForTagName({
                                  sortBy: 'tagName',
                                  sortType: 'desc'
                                });
                              }
                            }
                            }></span>
                        </div>
                      </th>
                      <th className="text-left no-rborder">Color</th>
                      <th className="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tagDetails.length > 0 ? (
                      tagDetails.map((tag, i) => (
                        <tr
                          key={i + 1}
                          style={{
                            backgroundColor:
                              clickedTagId === tag.tagId ? "#eaf2ff" : "",
                          }}
                        >
                          <td>
                            <div className="form-group form-checkbox-group table-checkbox-group">
                              <label className="form-checkbox">
                                <input
                                  type="checkbox"
                                  name="check_remember"
                                  checked={
                                    selectUnselect.includes(
                                      tag?.tagId
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(event) =>
                                    handleCheckboxChange(event, tag)
                                  }
                                />
                                <span className="checkmark"></span>

                              </label>
                              <div className="checkbox-text-wrapper">

                                <span className="check-text tag-name"
                                  id={"tag_name_" + i}
                                  onDoubleClick={() => {
                                    // if (getArchiveStatus(archiveStatus)) {
                                    //   return;
                                    // }
                                    doubleClickHandler(i, tag.name);
                                    setPreviousId(i);
                                    dispatch(
                                      tagActions.setTagIdClicked({
                                        clickedTagId: tag.tagId,
                                      })
                                    );
                                  }}
                                >
                                  {tag.name}
                                </span>
                                <div className="flex tag-name-td">
                                  {/* <span
                        className="tag-name"
                        id={"tag_name_" + i}
                        onDoubleClick={() => {
                          // if (getArchiveStatus(archiveStatus)) {
                          //   return;
                          // }
                          doubleClickHandler(i, tag.name);
                          setPreviousId(i);
                          dispatch(
                            tagActions.setTagIdClicked({
                              clickedTagId: tag.tagId,
                            })
                          );
                        }}
                      >
                        {tag.name}
                      </span> */}
                                  <span id={"edit-text_" + i} className="edit-text">
                                    <input
                                      type="text"
                                      {...register(`tagName[${i}].text`, {
                                        required: true,
                                      })}
                                      className={
                                        errors.tagName?.[i]?.text
                                          ? "invalid-error"
                                          : ""
                                      }
                                      id={"name_text_" + i}
                                      onKeyDown={(e) => {
                                        keyDown(i, e, tag);
                                      }}
                                      onClick={() => {
                                        dispatch(
                                          tagActions.setTagIdClicked({
                                            clickedTagId: tag.tagId,
                                          })
                                        );
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>

                          </td>
                          <td className="no-rborder">
                            <span
                              className="tag-color"
                              style={{ backgroundColor: tag.tagColour }}
                              onClick={(e) => {
                                // if (getArchiveStatus(archiveStatus)) {
                                //   return;
                                // }
                                showAction(e, tag.tagColour);
                                setTagDetails(tag);
                                setSelectedColor(tag.tagColour);
                                dispatch(
                                  tagActions.setTagIdClicked({
                                    clickedTagId: tag.tagId,
                                  })
                                );
                              }}
                            ></span>
                          </td>
                          <td className="text-right">
                            <span className="delete-row" onClick={() => {
                              // if (getArchiveStatus(archiveStatus)) {
                              //   return;
                              // }
                              setIsShowConfirmation(true);
                              dispatch(
                                tagActions.setTagIdClicked({
                                  clickedTagId: tag.tagId,
                                })
                              );
                              setSelectUnselect([tag.tagId]);
                              selectAllCheckBoxRef.current.checked = false;
                            }}>
                              <img
                                style={{ cursor: "pointer" }}
                                width={16}
                                src={deleteImg}
                              />
                            </span>

                          </td>
                        </tr>
                      ))
                    ) : noData ? (
                      <tr>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            // if (getArchiveStatus(archiveStatus)) {
                            //   return;
                            // }
                            setAddTagPopUp(true);
                          }}
                          colSpan={8}
                          className={`create-col`}
                        >
                          Click here to create a tag!
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={8} className="create-col">
                          No tags found!
                        </td>
                      </tr>
                    )}
                    {Object.keys(paginationArr).length !== 0 &&
                      tagDetails.length !== 0 && paginationArr.count > 15 && (
                        <tr className="pagination-row">
                          <td colSpan={3}>
                            <Pagination
                              paginationArr={paginationArr}
                              responseData={responseData}
                            />

                          </td>
                        </tr>)}
                  </tbody>
                </table>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ top: actionPos?.top, left: actionPos?.left }}
        className={`tag-list tag-color-list ${Object.keys(actionPos).length ? "show" : ""
          }`}
        ref={outsideActionRef}
      >
        {randomColors.map((color, i) => (
          <span
            key={i}
            className={
              selectedColor === color ? "tag-color selected" : "tag-color"
            }
            style={{ backgroundColor: color }}
            onClick={() => {
              setColor(color);
              setSelectedColor(color);
            }}
          ></span>
        ))}
        <span
          className="add-colorpicker"
          onClick={() => {
            dispatch(
              tagActions.setColorPickerOpen({
                open: true,
              })
            );
          }}
        >
          {open && <SketchPicker color={color} onChange={setColors} />}
        </span>
      </div>
      {showAddTagList && <AddTags cancelPopUp={cancelResponse} />}
      {isShowConfirmation && (
        <Confirmation responseAction={confirmationesponseAction}></Confirmation>
      )}
      {isLoad && <LoadingSpinner />}
    </React.Fragment>
  );
}

export default TagList;
