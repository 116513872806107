import { createSlice } from "@reduxjs/toolkit";
import { STORE_CONST } from "../utils/constants/store";
import {
  deleteSubscriber,
  getGlobalSubscribersList,
  markSubscriberAsAciveDeactive,
  submitOtpForUnsubscribe,
  unSubscribeAuser,
} from "./api/SubscriberApi";
import { toasterError, toasterSuccess } from "../utils/helper/commonHelper";
import { PROJECT_CONST } from "../utils/constants/projectConst";
import { ERROR_CODE_CONST } from "../utils/constants/errorCode";

const SubscribersSlice = createSlice({
  name: STORE_CONST.FILE_READING,
  initialState: {
    isLoad: false,
    subscribersList: {
      list: [],
      paginationArr: {},
      page: 0,
    },
    clickedSubscriberId: 0,
    callGobalSubscibersList: false,
    redirectToUnsubscribeSuccess: false,
    callUnsubscribeApi:false,
    unsubscribeSuccessMessage: '',
    errorInOtp: false
  },
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload.isLoad;
    },
    setFilterForGlobalSubsriberList(state, action) {
      state.subscribersList = {
        ...state.subscribersList,
        ...action?.payload,
      };
    },
    setClickedSubscriberId(state, action) {
      state.clickedSubscriberId = action.payload.clickedSubscriberId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGlobalSubscribersList.pending, (state, { payload }) => {
      state.isLoad = true;
    });
    builder.addCase(
      getGlobalSubscribersList.fulfilled,
      (state, { payload }) => {
        state.isLoad = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          state.subscribersList.list = payload?.response?.Subscribers;
          state.subscribersList.paginationArr = payload?.response?.paginationArr
            ? payload?.response?.paginationArr
            : {};
        }else if(payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
          && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode === 
          ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
          ){
            state.subscribersList.list = [];
            state.subscribersList.paginationArr = [];
            toasterError(payload?.message);
          }
      }
    );
    builder.addCase(getGlobalSubscribersList.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(
      markSubscriberAsAciveDeactive.pending,
      (state, { payload }) => {
        state.isLoad = true;
        state.callGobalSubscibersList = false;
      }
    );
    builder.addCase(
      markSubscriberAsAciveDeactive.fulfilled,
      (state, { payload }) => {
        state.isLoad = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          state.callGobalSubscibersList = true;
          if (payload?.response?.isActive) {
            toasterSuccess(PROJECT_CONST.GLOBAL_SUBSCRIBER_STATUS_ACTIVE_MSG);
          } else {
            toasterSuccess(PROJECT_CONST.GLOBAL_SUBSCRIBER_STATUS_INACTIVE_MSG);
          }
        } else if (
          payload?.hasError &&
          payload?.errorCode === ERROR_CODE_CONST.LEVEL2_DELETION
        ) {
          state.callGobalSubscibersList = false;
        }else if(payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
          && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode === 
          ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
          ){
            state.callGobalSubscibersList = false;
            toasterError(payload?.message);
          }
      }
    );
    builder.addCase(
      markSubscriberAsAciveDeactive.rejected,
      (state, { payload }) => {
        state.isLoad = false;
        state.callGobalSubscibersList = false;
      }
    );

    builder.addCase(deleteSubscriber.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callGobalSubscibersList = false;
    });
    builder.addCase(
      deleteSubscriber.fulfilled,
      (state, { payload }) => {
        state.isLoad = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          state.callGobalSubscibersList = true;
          if(payload?.length > 1) {
            toasterSuccess(PROJECT_CONST.SUBSCRIBERS_DELETED_SUCCESS)
          }else{
            toasterSuccess(PROJECT_CONST.SUBSCRIBER_DELETED_SUCCESS)
          }
        }else if(payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
          && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode === 
          ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
          ){
            state.callGobalSubscibersList = false;
            toasterError(payload?.message);
          }
      }
    );
    builder.addCase(deleteSubscriber.rejected, (state, { payload }) => {
      state.isLoad = false;
    });
    builder.addCase(unSubscribeAuser.pending, (state, { payload }) => {
      state.isLoad = true;
      state.redirectToUnsubscribeSuccess = false;
      state.unsubscribeSuccessMessage = '';
      state.errorInOtp = false;
    });
    builder.addCase(
      unSubscribeAuser.fulfilled,
      (state, { payload }) => {
        state.isLoad = false;
        if (!payload?.hasError && payload?.errorCode === -1) {
          state.redirectToUnsubscribeSuccess = true;
          state.unsubscribeSuccessMessage = payload?.message;
        }else if(payload?.hasError && payload?.errorCode != ERROR_CODE_CONST.ARCHIVE_ERROR
          && payload?.errorCode != ERROR_CODE_CONST.LEVEL2_DELETION && payload?.errorCode === 
          ERROR_CODE_CONST.NO_LEVEL_2_EXIST_CODE
          ){
            toasterError(payload?.message);
          }else{
            state.isLoad = true;
          }
      }
    );
    builder.addCase(unSubscribeAuser.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.redirectToUnsubscribeSuccess = false;
      state.unsubscribeSuccessMessage = '';
      state.errorInOtp = false;
    });
    builder.addCase(submitOtpForUnsubscribe.pending, (state, { payload }) => {
      state.isLoad = true;
      state.callUnsubscribeApi = false;
      state.errorInOtp = false;
    });
    builder.addCase(submitOtpForUnsubscribe.fulfilled, (state, { payload }) => {
      state.isLoad = false;
      if (!payload?.hasError && payload?.errorCode === -1) {
        toasterSuccess(PROJECT_CONST.VERIFICATION_SUCCESS);
        if(payload?.callUnSubscribe) {
          state.callUnsubscribeApi = true;
        }
      }else if(payload?.hasError && payload?.errorCode === ERROR_CODE_CONST.COMMON_ERROR_CODE) {
        state.redirectToUnsubscribeSuccess = true
        state.errorInOtp = true;
      } 
    });
    builder.addCase(submitOtpForUnsubscribe.rejected, (state, { payload }) => {
      state.isLoad = false;
      state.callUnsubscribeApi = false;
    });
  },
});

export const SubscribersAction = SubscribersSlice.actions;

export default SubscribersSlice;
